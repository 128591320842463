import React from "react";
import Navbar from "../LandingPage/MainLandingPage/Navbar";
import Footer from "../LandingPage/MainLandingPage/Footer";

function PrivacyPolicy() {
  return (
    <div className="landing-container">
      <Navbar />
      <div className="slide">
        <img
          style={{ width: "100%",
          background: "linear-gradient(357.77deg, rgba(253, 167, 55, 0.6) 3.34%, rgba(0, 82, 154, 0.6) 98.44%)"
        }}
          src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/privacypolicy.png"
          alt="privacypolicys"
        />
        <div className="products_overlay" id="privacybanner">
          <p className="raleway_64px_rem" >Privacy Policy</p>
        </div>
      </div>
      <div className="privacydata">
        <div className="privacy_text">
          <p className="raleway_20px_reg">
            Smarttrak AI ( <strong>"we," "us," "our"</strong>) is committed to
            protecting the privacy of our customers, business partners, and
            users of our website, 
            <a href="https://smarttrak.co/"  className="privacy-link"> 
            www.smarttrak.co
            </a>{" "}(the{" "}
            <strong>“Website”</strong>). This Privacy Policy outlines the types
            of information we collect, how we use it, and the steps we take to
            protect it.{" "}
            <strong>
              By accessing or using the Website, you agree to the terms of this
              policy.{" "}
            </strong>
            If you do not agree with these terms, please do not use the Website.
          </p>
          <p className="raleway_20px_reg">
            We may modify this policy from time to time, and by continuing to
            use the Website after any changes, you accept the updated policy. We
            encourage you to review this policy periodically to stay informed
            about how we are protecting your information.
          </p>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Mutual Confidentiality & Non Disclosure Agreement Overview:</p>
          <div className="list_italic" id="font_italic">
            <p className="raleway_20px_reg">
            The Mutual Confidentiality and Nondisclosure Agreement <i>(the "Agreement")</i> establishes the terms between Smarttrak LLC and a firm/institution regarding the exchange of confidential information during discussions of a potential business relationship. The Agreement defines <i>"Confidential Information"</i> as proprietary information disclosed by either party, which remains confidential and includes all forms of communication.

            </p>
            
          </div>
          <div className="list_italic">
            <p className="raleway_20px_reg">
            Key obligations include:
            </p>
            <li>Confidentiality: The Receiving Party must protect the Disclosing Party's confidential information, disclosing it only to employees or consultants with a need to know.</li>{" "}
            <li>
            Compelled Disclosure: If legally required to disclose information, the Receiving Party must notify the Disclosing Party and limit disclosure to the necessary information.{" "}
            </li>
            <li>
            Return or Destruction: Upon termination or request, all confidential materials must be returned or destroyed, with certification of destruction provided.
            </li>
            <li>
            Duration: Confidential obligations last for five years following the last disclosure of confidential information.
            </li>
            <li>
            Governing Law: The Agreement is governed by the laws of North Carolina, with exclusive jurisdiction for disputes.
            </li>
          </div>
         
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Information Collection and Use:</p>
          <div className="list_italic" id="font_italic">
            <p className="raleway_20px_reg">
              We collect and process personal data that you voluntarily provide
              to us, including, but not limited to:
            </p>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Mailing address</li>
            <li>Phone number</li>
            <li>Login credentials (for dealer login and business partners)</li>
            <li>
              Any other information that you choose to provide when interacting
              with the Website or contacting us directly
            </li>
          </div>
          <div className="list_italic">
            <p className="raleway_20px_reg">
              This information may be collected through contact forms, email
              inquiries, or other interactions with the Website. We use this
              data to:
            </p>
            <li>Respond to inquiries or requests.</li>{" "}
            <li>
              Provide services, such as estimates, repair, and technical support.{" "}
            </li>
            <li>
              Improve the functionality of the Website and user experience.
            </li>
            <li>
              Communicate updates, special offers, or changes to this privacy
              policy (if you have opted to receive such communications).
            </li>
            <li>
              Ensure compliance with any contracts or agreements between you and
              Smarttrak AI.
            </li>
          </div>
          <div>
            <p className="raleway_20px_reg">
              We do not sell your information to any third parties.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Information Sharing:</p>
          <div className="list_italic">
            <p className="raleway_20px_reg">
              We may share your information with:
            </p>
            <li>
              Subsidiaries or affiliates: To streamline and enhance our
              services.
            </li>
            <li>
              Third-party service providers: Contractors, dealers, or partners
              who help us deliver our services, including installation and
              maintenance support. 
            </li>

            <li>
              Successor entities: In the event of a merger, sale, restructuring,
              or other transfer of assets, your information may be transferred
              to the new entity.
            </li>
            <li>
              Legal obligations: To comply with laws, court orders, or
              government regulations, and to protect the rights, property, and
              safety of Smarttrak AI, our customers, or others.
            </li>

            <li>
              Third-party data processors: For managing Website operations,
              analytics, or email communications, provided these third parties
              agree to keep your data secure.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">
            Data Collection through Automated Technologies:
          </p>
          <div className="list_italic">
            <p className="raleway_20px_reg">
              We also collect information automatically as you navigate through
              and interact with the Website. This may include
            </p>
            <li>IP address</li>
            <li>Browser type and version</li>

            <li>Operating system</li>
            <li>Click-stream data</li>
          </div>
          <div>
            <p className="raleway_20px_reg">
              We may use cookies, web beacons, and other tracking technologies
              to improve Website functionality and gather insights on usage.
              Cookies are small data files stored on your device, which help us
              recognize repeat visitors and improve your browsing experience.
              You can control the use of cookies by adjusting your browser
              settings, but disabling cookies may limit your access to some
              features of the Website.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Security and Data Storage:</p>
          <div className="list_italic">
            <li>
              Smarttrak AI takes appropriate measures to protect your
              information from unauthorized access, alteration, or disclosure.
              However, no transmission over the internet is completely secure.
              By submitting your personal information through the Website, you
              acknowledge that any transmission is at your own risk.
            </li>
          </div>
          <div className="list_italic">
            <li>
              Your data may be stored or processed in the United States or other
              locations as required by Smarttrak AI. By using the Website, you
              consent to this transfer, storage, and processing of data.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Retention of Data:</p>
          <div className="list_italic">
            <li>
              We retain your personal information only for as long as necessary
              to fulfill the purposes for which it was collected, as required by
              applicable laws, or as necessary for the enforcement of any
              agreements or contracts. If you wish to have your personal
              information deleted, you may contact us, and we will comply with
              your request to the extent permitted by law.
            </li>
          </div>
        </div>

        <div className="privacy_text">
          <p className="raleway_20px_semi">
            Your Rights (EU, US, and Other Jurisdictions):
          </p>
          <div className="list_italic">
            <p className="raleway_20px_reg">
              Depending on your location, you may have certain rights regarding
              your personal information, including:
            </p>

            <li>
              Access: The right to access your personal information held by us.
            </li>
            <li>
              Correction: The right to correct or update inaccurate information.
            </li>

            <li>
              Deletion: The right to request the erasure of your data, subject
              to applicable laws.
            </li>
            <li>
              Data portability: The right to receive your personal data in a
              machine-readable format and transmit it to another party.
            </li>

            <li>
              Objection: The right to object to the processing of your personal
              data, including for marketing purposes.
            </li>
            <li>
              Restriction of processing: The right to request a restriction on
              processing your personal data under certain circumstances.
            </li>
          </div>
          <div>
            <p className="raleway_20px_reg">
              To exercise these rights, please contact us at{" "}
              <strong><a href="mailto:info@smarttrak.co" className="privacy-link"> info@smarttrak.co,</a> </strong>and we will respond in
              accordance with applicable laws. Please provide sufficient
              information to identify yourself, such as proof of identity, and
              specify your request.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">International Data Transfers:</p>
          <div className="list_italic">
            <li>
              Smarttrak AI operates globally, and your personal data may be
              transferred to, stored, and processed in countries outside your
              own, including the United States. While these countries may not
              have the same data protection laws as your jurisdiction, we take
              all necessary steps to ensure that your personal data is handled
              securely and in accordance with this Privacy Policy and relevant
              legal frameworks, including Standard Contractual Clauses as
              required under GDPR.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Children's Privacy:</p>
          <div className="list_italic">
            <li>
              Our Website is not intended for use by individuals under the age
              of <span id="roboto_text">18</span>. We do not knowingly collect
              personal information from minors. If you are under{" "}
              <span id="roboto_text">18</span>, do not use or provide any
              information on this Website. If we learn we have collected
              personal information from a minor, we will delete it promptly.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Third-Party Links:</p>
          <div className="list_italic">
            <li>
              Our Website may contain links to third-party websites. These
              websites have their own privacy policies, and Smarttrak AI is not
              responsible for the privacy practices or content of these external
              sites. We encourage you to review the privacy policies of any
              websites you visit.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Changes to the Privacy Policy :</p>
          <div className="list_italic">
            <li>
              Smarttrak AI reserves the right to modify this Privacy Policy at
              any time. Changes will be posted on this page, and the updated
              date will be indicated. We recommend checking this page
              periodically to stay informed about any updates. Your continued
              use of the Website constitutes your acceptance of any changes.
            </li>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_20px_semi">Contact Us:</p>
          <div className="list_italic">
            <li>
              If you have any questions or concerns about this Privacy Policy,
              or wish to exercise your rights, please contact us at:{" "}
              <strong><a href="mailto:info@smarttrak.co" className="mailinfo"> info@smarttrak.co.</a> </strong>This privacy policy is designed to be
              comprehensive, addressing all applicable regulations and best
              practices, while reflecting the unique operations of Smarttrak AI.
            </li>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
