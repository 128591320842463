import React, { useState, useRef, useEffect,useMemo, memo } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import "./graphCSS.css"
import "./BarPlotForIOT.scss";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import Divider from "@mui/material/Divider";
import Dropdown from "react-dropdown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
const InvBarPlot =  memo((props) => {
  const {
    isValueData,
    titleText,
    tooltipName,
    indicateUnit,stringDurationFun,selectedDateRangeProp, isFilter
  } = props;
  const seriesData =useMemo(() => {
    return isValueData ? isValueData.map((item) => item.value) : [];
  }, [isValueData]);

  const xaxisLabels = useMemo(() => {
    return isValueData ? isValueData.map((item) => {
      const date = new Date(item.timestamp_edge);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return `${day} ${month}`;
    }) : [];
  }, [isValueData]);

  const [xaxisData, setXaxisData] = useState(xaxisLabels);
  const [series, setSeries] = useState([
    {
      name: tooltipName,
      data: seriesData,
    },
  ]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);
  const [isDurationType, setIsDurationType] = useState("Daily");

  const [options, setOptions] = useState({
    chart: { type: "bar" },
    plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: xaxisLabels.length <= 2 ? "5%": "30%",
        },
      },
      colors: ["#90E900"],
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: xaxisData,
      labels: {
        style: {
          colors: "#000000",
        },
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: "Value in k",
      },
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },

        style: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
    },
    grid: {
        show: true,
        borderColor: "#BDBDBD",
        strokeDashArray: 5,
        position: "back",
        xaxis: { lines: { show: true } },
      },

      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => (
          `<div class="arrow_box">
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">Date:</span> ${w.globals.labels[dataPointIndex]}</span><br>
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">${tooltipName}:</span> ${parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2)} ${indicateUnit}</span><br>
          </div>`
        ),
      },
    toolbar: {
      show: false,
    },
  });

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [isFullScreen]);
  useEffect(() => {
    setSeries([
      {
        name: tooltipName,
        data: seriesData,
      },
    ]);

    setXaxisData(xaxisLabels);
    setOptions((prevOptions) => ({
      ...prevOptions,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: xaxisLabels.length <= 2 ? "5%": "30%",
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisLabels.map((dateTime, index) => {
          if (xaxisLabels.length >= 1) {
            if (isDurationType === "Monthly") {
              const date = new Date(dateTime);
              const month = date.toLocaleString("default", { month: "short" });
              return `${month}`;
          } if (xaxisLabels.length >= 4) {
              const date = new Date(dateTime);
              const month = date.toLocaleString("default", { month: "short" });
              const day = date.getDate();
              return `${day} ${month}`;
            
          }else {
            const date = new Date(dateTime);
            const month = date.toLocaleString("default", { month: "short" });
            const day = date.getDate();
            return ` ${day} ${month}`;
          }
        }
        }),
        labels: {
          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => (
          `<div class="arrow_box">
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">Date:</span> ${w.globals.labels[dataPointIndex]}</span><br>
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">${tooltipName}:</span> ${parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2)} ${indicateUnit}</span><br>
          </div>`
        ),
      },
      yaxis: {
        ...prevOptions.yaxis,

        title: {
          text: `Value in ${indicateUnit}`,
        },
        labels: {
          formatter: function (value) {
            return Math.round(value);
          },

          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
    }
   
  }, [
    isValueData,
    isFullScreen,
    xaxisLabels,
    seriesData,
    tooltipName,
    indicateUnit,
    titleText,

  ]);
  const handleFullScreenToggle = () => {
    const chartContainer = chartContainerRef.current;

    if (!isFullScreen) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) {
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) {
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) {
        chartContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  const chartContainerRef = useRef(null);

  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);
    const svg64 = btoa(xml);
    const imgSrc = `data:image/svg+xml;base64,${svg64}`;
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
    
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);

  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
  const durationType = [
    "Daily",
    "Weekly",
    "Monthly",
    "Yearly",
  ];
  const handleChangeDeviceDuration = (device) => {
    setIsDurationType(device);
  if (device === "Daily") {
      props.stringDurationFun("D");

    } else if (device === "Weekly") {
      props.stringDurationFun("W");

    } else if (device === "Monthly") {
      props.stringDurationFun("ME");

    }
    else if (device === "Yearly") {
      props.stringDurationFun("YE");

    }
  }
  return (
    <div
      style={{
        display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      }}
    >
         <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1vw",
        alignItems: "center",
      }}
    >
      <div>
        <p
          className="Poppins_18px_rem"
          style={{ color: isFullScreen ? "white" : "#212121" }}
        >
            {isValueData?.title?.text?? titleText}
            </p>{" "}
        </div>
        <div style={{ display: "flex", gap: "10px",
      
      alignItems: "center",
      justifyContent:"center" }}>
       {/* <div style={{ width: "auto", minWidth: "1.6vw", cursor: "pointer" }}  id="exc-linegraph">
            <Dropdown 
              className="recommendations-text"
              options={durationType.map((field) =>
                field
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              )}
              onChange={handleChangeDeviceDuration}
              value={isDurationType}
            />
          </div> */}
    
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>
      

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
              className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={handleFullScreenToggle}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                  className='icon-css'
                />
              ) : (
                <FullscreenIcon className='icon-css' />
              )}
            </button>
          </Tooltip>
        </div>
      
       </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />

      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
          durationType={durationType}
          handleChangeDuration={handleChangeDeviceDuration}
          isDurationType={isDurationType}
          isFilter={isFilter}
        />
      )}

<div
        style={{
          height: Object.keys(isValueData).length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",

        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
     
        {Object.keys(isValueData).length === 0 ? (
           <div
           style={{
             display: "flex",
             justifyContent: "center",
             height: "350px",

             alignItems: "center",
           }}
         >
           <p className="Poppins_18px_rem">No Data</p>
         </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
          <div style={{ width: xaxisData.length > 30 ? xaxisData.length * 50 : '100%' }}>
            <Chart options={options} series={series} type={isValueData?.chart?.type || "bar" } height={isFullScreen ? "800" : "350"} />
            </div>
            </div>)}
      </div>
    </div>
  );
});

export default InvBarPlot;