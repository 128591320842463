import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Tooltip } from "@mui/material";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import NormalBarPlot from "../../../Graphs/NormalBarPlot";
import CommonText from "../../../Common/CommonText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import "../DeviceMonitoring.scss";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import BarPlotForIOT from "../../../Graphs/BarPlotForIOT";
import SingleLineTempGraph from "../../../Graphs/SingleLineTempGraph";
import INVLineChart from "../../../Graphs/INVLineChart";
import html2canvas from "html2canvas";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BoldCommonText from "../../../Common/BoldCommonText";
import { useHistory } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BarWithLine from "../../../Graphs/BarWithLine";
import MultiDateLineGraph from "../../../Graphs/MultiDateLineGraph";
import InvBarPlot from "../../../Graphs/InvBarPlot";
import NewBarLineChart from "../../../Graphs/NewBarLineChart";
import UnixDateChart from "../../../Graphs/UnixDateChart";
import useErrorHandler from "../../../../utils/errorHandler";
import TimestampFormatter from "../../../Common/TimestampFormatter";
const invService = new CosmicInverterService();
const InverterDetails = memo(({ deviceId,defaultVal }) => {
  const [isPerformance, setIsPerformance] = useState([
    2.9, 3.2, 3, 3.6, 3.1, 3.4, 4,
  ]);
  const warningBoxStyle = {
    position: "absolute",
    width: "35vw",
    minHeight: "13vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    bottom: 0,
    right: 0,
    p: 4,
    padding: "20px",
    zIndex: "1",
    overflowY: "auto",
  };
  const [loadingState, setLoadingState] = useState({
    fieldList: false,
    perfRatio: false,
    dailyGen: false,
    fieldEnergy: false,
    fieldACVolt: false,
    outputPower: false,
    outputCurrent: false,
    dcVoltage: false,
    avgPercent: false,
    energyCompare: false,
    deviceCompare: false,
    genPower: false,
    tempParameters: false,
    perfOverview: false,
    invPara: false,
    forecast: false,
    actualIdeal: false,
    actualForecasted: false,

  });

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [inverterParameters, setInverterParameters] = useState({});

  const [isStartTimeGen, setIsStartTimeGen] = useState("");
  const [isStopTimeGen, setIsStopTimeGen] = useState("");

  const [isStartTimeTemp, setIsStartTimeTemp] = useState("");
  const [isStopTimeTemp, setIsStopTimeTemp] = useState("");
  const [isSelectedType, setIsSelectedType] = useState("daily");
  const [isFieldCompareVal, setIsFieldCompareVal] = useState(defaultVal);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);

  const [isStartTimePerf, setIsStartTimePerf] = useState("");
  const [isStartTimePerfRatio, setIsStartTimePerfRatio] = useState("");
  const [isStopTimePerfRatio, setIsStopTimePerfRatio] = useState("");
  const [isStopTimePerf, setIsStopTimePerf] = useState("");

  const [isStartTimeAcVolt, setIsStartTimeAcVolt] = useState("");
  const [isStopTimeAcVolt, setIsStopTimeAcVolt] = useState("");
  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [isStartTimeDailyGen, setIsStartTimeDailyGen] = useState("");
  const [isStopTimeDailyGen, setIsStopTimeDailyGen] = useState("");
  const [isStartTimeOutPower, setIsStartTimeOutPower] = useState("");
  const [isStopTimeOutPower, setIsStopTimeOutPower] = useState("");
  const [isStartTimeOutCurrent, setIsStartTimeOutCurrent] = useState("");
  const [isStopTimeOutCurrent, setIsStopTimeOutCurrent] = useState("");
  const [isStartTimeDcVolt, setIsStartTimeDcVolt] = useState("");
  const [isStopTimeDcVolt, setIsStopTimeDcVolt] = useState("");
  const [isStartTimeAvgPercent, setIsStartTimeAvgPercent] = useState("");
  const [isStopTimeAvgPercent, setIsStopTimeAvgPercent] = useState("");
  const [isStartTimeDeviceCompare, setIsStartTimeDeviceCompare] = useState("");
  const [isStopTimeDeviceCompare, setIsStopTimeDeviceCompare] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDateTime, setIsDateTime] = useState([]);
  const [performanceValue, setPerformanceValue] = useState([]);
  const [generationValue, setGenerationValue] = useState([]);

  const [predictedData, setPredictedData] = useState({});
  const [actualIdealGen, setActualIdealGen] = useState({});
  const [actualForecasted, setActualForecasted] = useState({});
  const [temperatureValue, setTemperatureValue] = useState([]);
  const [perfOverViewValue, setPerfOverViewValue] = useState([]);
  const [perfCompareVal, setPerfCompareVal] = useState([]);
  const [outputPower, setOutputPower] = useState([]);
  const [dailyGeneration, setDailyGeneration] = useState([]);
  const [fieldEnergy, setFieldEnergy] = useState([]);
  const [acVoltage, setAcVoltage] = useState([]);
  const [outputCurrent, setOutputCurrent] = useState([]);
  const [dcVoltage, setDcVoltage] = useState([]);
  const [avgPercent, setAvgPercent] = useState([]);
  const [inverterCompare, setInverterCompare] = useState([]);
  const [stringDuration, setStringDuration] = useState("h");
  const [stringDurationTwo, setStringDurationTwo] = useState("h");
  const [stringDurationThree, setStringDurationThree] = useState("h");
  const [stringDurationFour, setStringDurationFour] = useState("h");
  const [generationstringDuration, setGenerationStringDuration] = useState("D");

  const [isStartTimeInv, setIsStartTimeInv] = useState("");
  const [isStopTimeInv, setIsStopTimeInv] = useState("");
  const [isStartTimePrediction, setIsStartTimePrediction] = useState("");
  const [isStopTimePrediction, setIsStopTimePrediction] = useState("");
  const [isStartTimeActualIdeal, setIsStartTimeActualIdeal] = useState("");
  const [isStopTimeActualIdeal, setIsStopTimeActualIdeal] = useState("");
  const [isStartTimeActualForecasted, setIsStartTimeActualForecasted] = useState("");
  const [isStopTimeActualForecasted, setIsStopTimeActualForecasted] = useState("");
  const [gridHeight, setGridHeight] = useState(0);
  const [warningsList, setWarningsList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const chartContainerRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleFetchError = useErrorHandler();

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(currentDate.getDate()).padStart(2, "0");
  const defaultEndValue = `${year1}-${month1}-${day1}T00:00:00Z`;

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 7);

  const year = yesterday.getFullYear();
  const month = String(yesterday.getMonth() + 1).padStart(2, "0");
  const dayStart = String(yesterday.getDate()).padStart(2, "0");
  const defaultStartValue = `${year}-${month}-${dayStart}T00:00:00Z`;

  const pastDay = new Date(currentDate);
  pastDay.setDate(currentDate.getDate() - 1);
  const yearTwo = pastDay.getFullYear();
  const monthTwo = String(pastDay.getMonth() + 1).padStart(2, "0");
  const dayStartTwo = String(pastDay.getDate()).padStart(2, "0");
  const pastOneDayDate = `${yearTwo}-${monthTwo}-${dayStartTwo}T00:00:00Z`;

  const gridRef = useRef(null);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    if (deviceId) {
      await Promise.all([
        handleForecastedGen(deviceId),
        handleActualIdealGen(deviceId),
        handleActualForecasted(deviceId),
        handleDeviceFieldAcVoltage(deviceId),
        handleDailyEnergyGen(deviceId),
        handleInvertersCompare(deviceId),
        handleDeviceFieldEnergy(deviceId),
        handleDeviceFieldOutPower(deviceId),
        handleDeviceOutputCurrent(deviceId),
        handleDeviceDcVoltage(deviceId),
        handleDeviceAvgPercent(deviceId),
      ]);
    }
  }, [deviceId]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [fetchData]);
  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeAcVolt && isStopTimeAcVolt) {
        handleDeviceFieldAcVoltage(
          deviceId,
          isStartTimeAcVolt,
          isStopTimeAcVolt
        );
      }
    }
  }, [isStopTimeAcVolt]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeEnergy && isStopTimeEnergy) {
        handleDeviceFieldEnergy(deviceId, isStartTimeEnergy, isStopTimeEnergy);
      }
    }
  }, [isStopTimeEnergy]);
  useEffect(() => {
    if (deviceId) {
      if (
        (isStartTimeDailyGen && isStopTimeDailyGen) ||
        generationstringDuration
      ) {
        handleDailyEnergyGen(
          deviceId,
          generationstringDuration,
          isStartTimeDailyGen,
          isStopTimeDailyGen
        );
      }
    }
  }, [isStopTimeDailyGen, generationstringDuration]);
  useEffect(() => {
    if (deviceId) {
      if ((isStartTimeInv && isStopTimeInv) || stringDuration) {
        handleInvertersCompare(
          deviceId,
          stringDuration,
          isStartTimeInv,
          isStopTimeInv
        );
      }
    }
  }, [isStopTimeInv, stringDuration]);
  useEffect(() => {
    if (deviceId) {
      if ((isStartTimePrediction && isStopTimePrediction) || stringDurationTwo) {
        handleForecastedGen(
          deviceId,
          stringDurationTwo,
          isStartTimePrediction,
          isStopTimePrediction
        );
      }
    }
  }, [isStopTimePrediction, stringDurationTwo]);
  useEffect(() => {
    if (deviceId) {
      if ((isStartTimeActualIdeal && isStopTimeActualIdeal) || stringDurationThree) {
        handleActualIdealGen(
          deviceId,
          stringDurationThree,
          isStartTimeActualIdeal,
          isStopTimeActualIdeal
        );
      }
    }
  }, [isStopTimeActualIdeal, stringDurationThree]);
  useEffect(() => {
    if (deviceId) {
      if ((isStartTimeActualForecasted && isStopTimeActualForecasted) || stringDurationFour) {
        handleActualForecasted(
          deviceId,
          stringDurationFour,
          isStartTimeActualForecasted,
          isStopTimeActualForecasted
        );
      }
    }
  }, [isStopTimeActualForecasted, stringDurationFour]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeOutPower && isStopTimeOutPower) {
        handleDeviceFieldOutPower(
          deviceId,
          isStartTimeOutPower,
          isStopTimeOutPower
        );
      }
    }
  }, [isStopTimeOutPower]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeOutCurrent && isStopTimeOutCurrent) {
        handleDeviceOutputCurrent(
          deviceId,
          isStartTimeOutCurrent,
          isStopTimeOutCurrent
        );
      }
    }
  }, [isStopTimeOutCurrent]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeDcVolt && isStopTimeDcVolt) {
        handleDeviceDcVoltage(deviceId, isStartTimeDcVolt, isStopTimeDcVolt);
      }
    }
  }, [isStopTimeDcVolt]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeAvgPercent && isStopTimeAvgPercent) {
        handleDeviceAvgPercent(
          deviceId,
          isStartTimeAvgPercent,
          isStopTimeAvgPercent
        );
      }
    }
  }, [isStopTimeAvgPercent]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeDeviceCompare && isStopTimeDeviceCompare) {
        handleDeviceCompare(
          deviceId,
          isFieldCompareVal,
          isStartTimeDeviceCompare,
          isStopTimeDeviceCompare
        );
      }
    }
  }, [isFieldCompareVal, isStopTimeDeviceCompare]);

  useEffect(() => {
    if (deviceId) {
      if (plantId !== 2) {
        if (isStartTimePerfRatio && isStopTimePerfRatio) {
          handleDevicePerfRatio(
            deviceId,
            isStartTimePerfRatio,
            isStopTimePerfRatio
          );
        } else {
          const fetchData = () => {
            handleDevicePerfRatio(deviceId, defaultStartValue, defaultEndValue);
          };
          fetchData();
          const intervalId = setInterval(fetchData, 900000);

          return () => {
            clearInterval(intervalId);
          };
        }
      }
    }
  }, [deviceId, isStopTimePerfRatio]);

  useEffect(() => {
    const fetchComparisonData = () => {
      if (deviceId) {
        handleDeviceCompare(deviceId, isFieldCompareVal);
      }
    };
    fetchComparisonData();
    const comparisonIntervalId = setInterval(
      fetchComparisonData,
      5 * 60 * 1000
    );
    return () => clearInterval(comparisonIntervalId);
  }, [isFieldCompareVal, deviceId]);
  useEffect(() => {
    if (deviceId) {
      handleInvParameters(deviceId);
    }
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [deviceId]);

  // useEffect(() => {
  //   if (deviceId) {
  //     if (plantId !== 2) {
  //       if (isStartTimeTemp && isStopTimeTemp) {
  //         handleGetTempParameter(deviceId, isStartTimeTemp, isStopTimeTemp);
  //       } else {
  //         const fetchData = () => {
  //           handleGetTempParameter(deviceId, pastOneDayDate, defaultEndValue);
  //         };
  //         fetchData();
  //         const intervalId = setInterval(fetchData, 900000);

  //         return () => {
  //           clearInterval(intervalId);
  //         };
  //       }
  //     }
  //   }
  // }, [deviceId, isStopTimeTemp]);

  useEffect(() => {
    if (deviceId) {
      if (plantId !== 2) {
        if (isStartTimePerf && isStopTimePerf) {
          handleGetPerfOverview(deviceId, isStartTimePerf, isStopTimePerf);
        } else {
          const fetchData = () => {
            handleGetPerfOverview(deviceId, pastOneDayDate, defaultEndValue);
          };
          fetchData();
          const intervalId = setInterval(fetchData, 900000);

          return () => {
            clearInterval(intervalId);
          };
        }
      }
    }
  }, [isStopTimePerf]);

  useEffect(() => {
    if (deviceId) {
      if (plantId !== 2) {
        if (isStartTimeGen && isStopTimeGen) {
          handleGetPowerGen(deviceId, isStartTimeGen, isStopTimeGen);
        } else {
          const fetchData = () => {
            handleGetPowerGen(deviceId, defaultStartValue, defaultEndValue);
          };
          fetchData();
          const intervalId = setInterval(fetchData, 900000);

          return () => {
            clearInterval(intervalId);
          };
        }
      }
    }
  }, [deviceId, isStopTimeGen]);
  const generateDateTimes = useCallback(() => {
    const dateTimes = [];
    const today = new Date();
    for (let i = 6; i >= 0; i--) {
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - i);
      const year = pastDate.getFullYear();
      const month = (pastDate.getMonth() + 1).toString().padStart(2, "0");
      const day = pastDate.getDate().toString().padStart(2, "0");
      const hours = (16 + i).toString().padStart(2, "0");
      const minutes = "50";
      const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}`;
      dateTimes.push(dateTimeString);
    }
    return dateTimes;
  }, []);

  useEffect(() => {
    const dateTimes = generateDateTimes();
    setIsDateTime(dateTimes);
    if (gridRef.current) {
      setGridHeight(gridRef.current.offsetHeight);
    }
  }, [gridRef, generateDateTimes]);
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 6);
  const formattedStartDate = `${startDate.getFullYear()}-${(
    startDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
  const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

 
  useEffect(() => {
    handleFieldsList(deviceId);
  }, []);
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const plantId = plantIdsArray[0];
  
  const handleFullScreenToggle = useCallback(() => {
    const chartContainer = chartContainerRef.current;

    if (!isFullScreen) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) {
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) {
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) {
        chartContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      setIsFullScreen(false);
    }
  }, []);
  
  const handleFieldsList = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter", device_id);
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };

  const downloadTableAsImage = () => {
    const chartContainer = chartContainerRef.current;

    html2canvas(chartContainer)
      .then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = "table.png";
        a.click();
      })
      .catch((error) => {
        console.error("Error capturing table:", error);
      });
  };

  const handleDevicePerfRatio = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: true }));
      const res = await invService.getDevicePerfRatio(id, start_time, end_time);
      if (res.status === 200) {
        setPerformanceValue(res.data.filter((item) => item.value !== 0));
        setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      handleFetchError(error, "Performance Ratio");
    }
  };
  const handleDailyEnergyGen = async (
    id,
    generationstringDuration,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, dailyGen: true }));
      const res = await invService.getDeviceEnergyGeneration(
        id,
        generationstringDuration === undefined ? "D" : generationstringDuration,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          .filter((item) => item["daily_energy_generation_MWh"] !== 0) // Filter out zero values
          .map((item) => {
            const {
              timestamp_edge: timestamp_edge,
              daily_energy_generation_MWh: value,
            } = item; // Extract and rename fields
            return { timestamp_edge, value }; // Return the new object
          });
        setDailyGeneration(formattedData);
        setLoadingState((prevState) => ({ ...prevState, dailyGen: false }));
      }
    } catch (error) {
      setDailyGeneration([])

      setLoadingState((prevState) => ({ ...prevState, dailyGen: false }));
      handleFetchError(error, "daily energy generation");
    }
  };
  const handleDeviceFieldEnergy = async (id, start_time, end_time) => {
    try {
      
      setLoadingState((prevState) => ({ ...prevState, fieldEnergy: true }));
      let res;
    if (plantId === 2) {
      res = await invService.getDeviceFieldEnergy(id, start_time, end_time);
    } else {
      res = await invService.getDeviceCompareFields("inverter",id, "todays_energy", start_time, end_time);
    }
      
      
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setFieldEnergy(formattedData);
        setLoadingState((prevState) => ({ ...prevState, fieldEnergy: false }));
      }
    } catch (error) {
      setFieldEnergy([]);

      setLoadingState((prevState) => ({ ...prevState, fieldEnergy: false }));
      handleFetchError(error, "daily energy generation");
    }
  };
  const handleDeviceFieldAcVoltage = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldACVolt: true }));
      
      let res;
      if (plantId === 2) {
        res = await invService.getDeviceFieldAcVoltage(
          id,
          start_time,
          end_time
        );
      } else {
        res = await invService.getDeviceCompareFields("inverter",id, "temperature", start_time, end_time);
      }
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setAcVoltage(formattedData);
        setLoadingState((prevState) => ({ ...prevState, fieldACVolt: false }));
      }
    } catch (error) {
      setAcVoltage([]);

      setLoadingState((prevState) => ({ ...prevState, fieldACVolt: false }));
      handleFetchError(error, "AC Voltage");
    }
  };
  const handleDeviceFieldOutPower = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, outputPower: true }));
      
      let res;
      if (plantId === 2) {
        res = await invService.getDeviceFieldoutPower(
          id,
          start_time,
          end_time
        );
      } else {
        res = await invService.getDeviceCompareFields("inverter",id, "net_frequency", start_time, end_time);
      }
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setOutputPower(formattedData);
        setLoadingState((prevState) => ({ ...prevState, outputPower: false }));
      }
    } catch (error) {
      setOutputPower([])

      setLoadingState((prevState) => ({ ...prevState, outputPower: false }));
      handleFetchError(error, "Output Power");
    }
  };
  const handleDeviceOutputCurrent = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, outputCurrent: true }));
      let res;
      if (plantId === 2) {
        res = await invService.getDeviceOutputCurrent(
          id,
          start_time,
          end_time
        );
      } else {
        res = await invService.getDeviceCompareFields("inverter",id, "r_phase_output_current,y_phase_output_current,b_phase_output_current,dc_current", start_time, end_time);
      }
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setOutputCurrent(formattedData);
        setLoadingState((prevState) => ({ ...prevState, outputCurrent: false }));
      }
    } catch (error) {
      setOutputCurrent([]);

      setLoadingState((prevState) => ({ ...prevState, outputCurrent: false }));
      handleFetchError(error, "Output Current");
    }
  };
  const handleForecastedGen = async (
    id, stringDurationTwo,
    start_time,
    end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, forecast: true }));
      const res = await invService.getInvPredictedData(
        id,
        stringDurationTwo === undefined ? "h" : stringDurationTwo,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setPredictedData(res.data)
        setLoadingState((prevState) => ({ ...prevState, forecast: false }));

      }
    } catch (error) {
      setPredictedData({})

      setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      handleFetchError(error, "Actual Vs Forecasted Generation");
    }
  };
  const handleActualIdealGen = async (
    device_id,
    stringDurationThree,
    start_time,
    end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, actualIdeal: true }));
      const res = await invService.getActualIdeal(
        device_id,
        stringDurationThree === undefined ? "h" : stringDurationThree,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setActualIdealGen(res.data)
        setLoadingState((prevState) => ({ ...prevState, actualIdeal: false }));

      }
    } catch (error) {
      setActualIdealGen({})

      setLoadingState((prevState) => ({ ...prevState, actualIdeal: false }));
      handleFetchError(error, "Ideal Vs Actual Generation");
    }
  };
  const handleActualForecasted = async (
    device_id,
    stringDurationFour,
    start_time,
    end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, actualForecasted: true }));
      const res = await invService.getActualVsForecasted(
        device_id,
        stringDurationFour === undefined ? "h" : stringDurationFour,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setActualForecasted(res.data)
        setLoadingState((prevState) => ({ ...prevState, actualForecasted: false }));

      }
    } catch (error) {
      setActualForecasted({})

      setLoadingState((prevState) => ({ ...prevState, actualForecasted: false }));
      handleFetchError(error, "Forecasted Vs Actual Generation");
    }
  };
  const handleDeviceDcVoltage = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, dcVoltage: true }));
      let res;
      if (plantId === 2) {
        res = await invService.getDeviceFieldDcVoltage(
          id,
          start_time,
          end_time
        );
      } else {

        res = await invService.getDeviceCompareFields("inverter",id, "r_phase_voltage,y_phase_voltage,b_phase_voltage,dc_voltage", start_time, end_time);
      }
      
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setDcVoltage(formattedData);
        setLoadingState((prevState) => ({ ...prevState, dcVoltage: false }));
      }
    } catch (error) {
      setDcVoltage([]);

      setLoadingState((prevState) => ({ ...prevState, dcVoltage: false }));
      handleFetchError(error, "DC Voltage");
    }
  };
  const handleDeviceAvgPercent = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, avgPercent: true }));
      
      let res;
      if (plantId === 2) {
        res = await invService.getDeviceFieldAvgPercent(
          id,
          start_time,
          end_time
        );
      } else {
        res = await invService.getDeviceCompareFields("inverter",id, "active_power,dc_input_power", start_time, end_time);
      }
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setAvgPercent(formattedData);
        setLoadingState((prevState) => ({ ...prevState, avgPercent: false }));
      }
    } catch (error) {
      setAvgPercent([])

      setLoadingState((prevState) => ({ ...prevState, avgPercent: false }));
      handleFetchError(error, "Average Percent");
    }
  };
  const handleInvertersCompare = async (
    id,
    stringDuration,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, energyCompare: true }));
      const res = await invService.getInvertersCompare(
        id,
        stringDuration === undefined ? "h" : stringDuration,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setInverterCompare(res.data);
        setLoadingState((prevState) => ({ ...prevState, energyCompare: false }));
      }
    } catch (error) {
      setInverterCompare([])

      setLoadingState((prevState) => ({ ...prevState, energyCompare: false }));
      handleFetchError(error, "Energy Fields Comparison");
    }
  };

  const handleDeviceCompare = async (id, fields, start_time, end_time) => {
    try {
      if (fields.length === 0) {
        setPerfCompareVal([]);
        // enqueueSnackbar("Please Select Fields to Compare", {
        //   variant: "warning",
        // });
      } else {
        setLoadingState((prevState) => ({ ...prevState, deviceCompare: true }));
        const res = await invService.getDeviceCompareFields("inverter",
          id,
          fields,
          start_time,
          end_time
        );
        if (res.status === 200) {
          const formattedData = res.data.map((item) => {
            const formattedItem = { timestamp_edge: item.timestamp_edge };
            Object.keys(item).forEach((field) => {
              if (field !== "timestamp_edge" && field !== "device_name") {
                const formattedField = field
                  .replace("_", " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                let fieldValue = item[field];
                if (typeof fieldValue === "number") {
                  fieldValue = fieldValue.toFixed(2);
                }
                formattedItem[formattedField] = fieldValue;
              }
            });
            return formattedItem;
          });
          setPerfCompareVal(formattedData);
          setLoadingState((prevState) => ({ ...prevState, deviceCompare: false }));
        }
      }
    } catch (error) {
      setPerfCompareVal([]);

      setLoadingState((prevState) => ({ ...prevState, deviceCompare: false }));
      handleFetchError(error, "Device Fields Comparison");
    }
  };
  const handleGetPowerGen = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, genPower: true }));

      const res = await invService.getPowerGenGraph(id, start_time, end_time);
      if (res.status === 200) {
        setGenerationValue(res.data.filter((item) => item.value !== 0));

        const values = res.data.map((entry) => entry.value);
        const filteredValues = values.filter((value) => value !== 0);

        const min = Math.min(...filteredValues);
        const max = Math.max(...filteredValues);
        const filteredValuesWithoutMin = filteredValues.filter(
          (value) => value !== min
        );
        const filteredValuesWithoutMax = filteredValues.filter(
          (value) => value !== max
        );
        // Find the second minimum and maximum
        const secondMin = Math.min(...filteredValuesWithoutMin);
        const secondMax = Math.max(...filteredValuesWithoutMax);
        for (const entry of res.data) {
          if (entry.value !== 0) {
            const newWarningsList = [];

            if (entry.value < secondMin) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'Generation' is below ${secondMin}kWh on ${entry.timestamp_edge.replace(
                  /T|:\d{2}\+\d{2}:\d{2}/g,
                  " "
                )}`
              );
            }
            if (entry.value > secondMax) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'Generation' is above ${secondMax}kWh on ${entry.timestamp_edge.replace(
                  /T|:\d{2}\+\d{2}:\d{2}/g,
                  " "
                )}`
              );
            }
            setWarningsList((prevWarnings) => [
              ...prevWarnings,
              ...newWarningsList,
            ]);
          }
        }
        setLoadingState((prevState) => ({ ...prevState, genPower: false }));

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, genPower: false }));
      handleFetchError(error, "Generated Power");

    }
  };

  const handleGetTempParameter = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, tempParameters: true }));

      const res = await invService.getTempParameter(id, start_time, end_time);
      if (res.status === 200) {
        const formattedData = res.data
          .filter((item) => item.value !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Inside Cabin Temperature": item.value,
          }));
        setTemperatureValue(formattedData);
        setLoadingState((prevState) => ({ ...prevState, tempParameters: false }));

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, tempParameters: false }));
      handleFetchError(error, "Temperature parameters");
    }
  };

  const handleGetPerfOverview = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, perfOverview: true }));
      const res = await invService.getPerfOverview(id, start_time, end_time);
      if (res.status === 200) {
        const filteredData = res.data.filter(
          (item) =>
            item.active_power !== 0 &&
            item.dc_input_power !== 0 &&
            item.efficiency !== 0
        );
        const activePowerValues = res.data
          .filter((entry) => entry.active_power !== 0)
          .map((entry) => entry.active_power);
        const dcPowerValues = res.data
          .filter((entry) => entry.dc_input_power !== 0)
          .map((entry) => entry.dc_input_power);

        const activePowerMin = Math.min(...activePowerValues);
        const activePowerMax = Math.max(...activePowerValues);
        const dcPowerMin = Math.min(...dcPowerValues);
        const dcPowerMax = Math.max(...dcPowerValues);

        const activePowerFilteredValuesWithoutMin = activePowerValues.filter(
          (value) => value !== activePowerMin
        );
        const activePowerFilteredValuesWithoutMax = activePowerValues.filter(
          (value) => value !== activePowerMax
        );
        const dcPowerFilteredValuesWithoutMin = dcPowerValues.filter(
          (value) => value !== dcPowerMin
        );
        const dcPowerFilteredValuesWithoutMax = dcPowerValues.filter(
          (value) => value !== dcPowerMax
        );

        const activePowerSecondMin = Math.min(
          ...activePowerFilteredValuesWithoutMin
        );
        const activePowerSecondMax = Math.max(
          ...activePowerFilteredValuesWithoutMax
        );
        const dcPowerSecondMin = Math.min(...dcPowerFilteredValuesWithoutMin);
        const dcPowerSecondMax = Math.max(...dcPowerFilteredValuesWithoutMax);

        for (const entry of res.data) {
          if (entry.active_power !== 0 && entry.dc_input_power !== 0) {
            const newWarningsList = [];
            const regexPattern = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}).*/;
            const match = entry.timestamp_edge.match(regexPattern);
            const transformedTimestamp = `${match[1]} ${match[2]}`;

            if (entry.active_power < activePowerSecondMin) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'Active power' is below ${activePowerSecondMin}kW on ${transformedTimestamp}`
              );
            }
            if (entry.active_power > activePowerSecondMax) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'Active power' is above ${activePowerSecondMax}kW on ${transformedTimestamp}`
              );
            }
            if (entry.dc_input_power < dcPowerSecondMin) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'DC power' is below ${dcPowerSecondMin}kW on ${transformedTimestamp}`
              );
            }
            if (entry.dc_input_power > dcPowerSecondMax) {
              setOpenDrawer(true);
              newWarningsList.push(
                `'DC power' is above ${dcPowerSecondMax}kW on ${transformedTimestamp}`
              );
            }
            setWarningsList((prevWarnings) => [
              ...prevWarnings,
              ...newWarningsList,
            ]);
          }
        }
        setPerfOverViewValue(filteredData);
        setLoadingState((prevState) => ({ ...prevState, perfOverview: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, perfOverview: false }));
      handleFetchError(error, "Performance Overview");
    }
  };

  const handleInvParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invPara: true }));

      const res = await invService.getInvParameter(id);
      if (res.status === 200) {
        setInverterParameters(res.data);
        setLoadingState((prevState) => ({ ...prevState, invPara: false }));

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invPara: false }));
      handleFetchError(error, "inverter parameters");

    }
  };
  const handleCloseWarningsModal = () => {
    setOpenDrawer(false);
    setWarningsList((prevWarnings) => prevWarnings.slice(-10));
  };

  const invPlantData = [
    // {
    //   title: "DC Parameters",
    //   subtitle1: "DC Current (A)",

    //   value1: "--",

    //   subtitle2: "DC Voltage (V)",
    //   value2: inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV !== null &&
    //     inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV !== undefined
    //     ? `${inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV.toFixed(2)} V`
    //     : "-- V",

    //   subtitle3: "DC Power (kW)",
    //   value3: "--",
    // },
    {
      title: "AC Parameters",
      subtitle1: "AC Current (A)",

      value1: inverterParameters.device_data?.specific_unit_monitoring?.output_current_A !== null &&
        inverterParameters.device_data?.specific_unit_monitoring?.output_current_A !== undefined
        ? `${inverterParameters.device_data?.specific_unit_monitoring?.output_current_A.toFixed(2)} A`
        : "-- A",

      subtitle2: "AC Voltage (V)",
      value2: inverterParameters.device_data?.specific_unit_monitoring?.net_AC_voltage_V !== null &&
        inverterParameters.device_data?.specific_unit_monitoring?.net_AC_voltage_V !== undefined
        ? `${inverterParameters.device_data?.specific_unit_monitoring?.net_AC_voltage_V.toFixed(2)} V`
        : "-- V",

      subtitle3: "AC Power (kW)",
      value3: inverterParameters.device_data?.specific_unit_monitoring?.output_power_kW !== null &&
        inverterParameters.device_data?.specific_unit_monitoring?.output_power_kW !== undefined
        ? `${inverterParameters.device_data?.specific_unit_monitoring?.output_power_kW.toFixed(2)} kW`
        : "-- kW",
    },
    {
      title: "Other Details",
      subtitle1: "DC Voltage (V)",
      value1: inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV !== null &&
        inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV !== undefined
        ? `${inverterParameters.device_data?.specific_unit_monitoring?.DC_voltage_DCV.toFixed(2)} V`
        : "-- V",

      subtitle2: "Inverter Temp",
      value2: inverterParameters.device_data?.temperature_C !== null &&
        inverterParameters.device_data?.temperature_C !== undefined
        ? `${inverterParameters.device_data?.temperature_C.toFixed(2)} °C`
        : "-- °C",

      subtitle3: "Frequency",
      value3:  inverterParameters.device_data?.net_frequency_Hz !== null &&
      inverterParameters.device_data?.net_frequency_Hz !== undefined
      ? `${inverterParameters.device_data?.net_frequency_Hz.toFixed(2)} Hz`
      : "-- Hz",
    },
  ];
  const otherPlantData = [
    {
      title: "AC Parameters",
      subtitle1: "R Phase Output Current (A)",
      value1: inverterParameters.device_data?.r_phase_output_current !== null &&
        inverterParameters.device_data?.r_phase_output_current !== undefined
        ? `${inverterParameters.device_data?.r_phase_output_current.toFixed(2)} A`
        : "-- A",

      subtitle2: "Y Phase Output Current (A)",
      value2: inverterParameters.device_data?.y_phase_output_current !== null &&
        inverterParameters.device_data?.y_phase_output_current !== undefined
        ? `${inverterParameters.device_data?.y_phase_output_current.toFixed(2)} A`
        : "-- A",

      subtitle3: "B Phase Output Current (A)",
      value3: inverterParameters.device_data?.b_phase_output_current !== null &&
        inverterParameters.device_data?.b_phase_output_current !== undefined
        ? `${inverterParameters.device_data?.b_phase_output_current.toFixed(2)} A`
        : "-- A",
    },
    {
      title: "Other Details",
      subtitle1: "DC Current (A)",
      value1: inverterParameters.device_data?.dc_current !== null &&
        inverterParameters.device_data?.dc_current !== undefined
        ? `${inverterParameters.device_data?.dc_current.toFixed(2)} A`
        : "-- A",

      subtitle2: "DC Input Power",
      value2: inverterParameters.device_data?.dc_input_power !== null &&
        inverterParameters.device_data?.dc_input_power !== undefined
        ? `${inverterParameters.device_data?.dc_input_power.toFixed(2)} kW`
        : "-- kW",

      subtitle3: "Reactive Power",
      value3:  inverterParameters.device_data?.reactive_power !== null &&
      inverterParameters.device_data?.reactive_power !== undefined
      ? `${inverterParameters.device_data?.reactive_power.toFixed(2)} kW`
      : "-- kW",
    },
  ]

  return (
    <div>
      <Grid container className="fullWidthCenteredFlexStart">
        <Grid item lg={12} xs={12} md={12} className="center_center_grid_p0">
          <Grid container className="center_flex-start_gap_bg">
            <Grid
              className="total_power_container"
              item
              lg={2}
              xs={12}
              sm={12}
              md={5.9}
              sx={{
                height: "28rem",
              }}
            >
              <Paper
                className="inv-insight-one"

              >
                <p
                  className="Poppins_18px_rem"
                  style={{ fontWeight: "600", paddingTop: "1rem" }}
                >
                  Fault Probability
                </p>
                <div className="progressBarContainer">
                  <div className="progressBarWrapper" id="circularBarTwo">
                    <CircularProgressbar
                      value={inverterParameters?.device_details?.fault_probability? inverterParameters?.device_details?.fault_probability:0}
                      text={`${inverterParameters?.device_details?.fault_probability?inverterParameters?.device_details?.fault_probability:0}%`}
                      styles={buildStyles({
                        width: "80%",
                        pathColor: `#2F67F8`,
                        textColor: "#2F67F8",
                      })}
                    />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              className="total_power_container"
              item
              lg={3}
              xs={12}
              md={5.9}
              sx={{
                height: "28rem",
              }}
            >
              <Paper
                className="inv-insight-one"

              >
                <p
                  className="Poppins_18px_rem"
                  style={{ fontWeight: "600", paddingTop: "1rem" }}
                >
                  Remaining Useful Life
                </p>
                <div
                  className="semicircle-scale"
                  style={{ position: "relative" }}
                  id="circularBarTwo"
                >
                  <CircularProgressbar
                    value={inverterParameters?.device_details?.rul_predicted ? inverterParameters?.device_details?.rul_predicted :0}
                    circleRatio={0.5}
                    strokeLineCap="square"
                    styles={{
                      root: {
                        transform: "rotate(0.75turn)",
                      },
                      path: { stroke: "#90E900" },
                      trailColor: "grey",
                      backgroundColor: "red",
                    }}
                  />
                  <p className="Poppins_36px_rem">{`${inverterParameters?.device_details?.rul_predicted ? inverterParameters?.device_details?.rul_predicted :0}%`}</p>
                </div>
                <p
                  className="Poppins_16px_rem"
                  style={{ fontWeight: "400", paddingBottom: "1rem" }}
                >
                  {`RUL is estimated based on the readings from the last week:
                  from ${formattedStartDate} 03:30:00 to ${formattedEndDate}
                  08:30:001`}
                </p>
              </Paper>
            </Grid>
            <Grid
              item
              lg={6.8}
              xs={12}
              md={11.9}
              sm={12}
              className="exc-graph-grid"
              sx={{ height: "28rem" }}
            >
              <Paper className="exc-graph-paper"
              >
                <InvBarPlot
                  isValueData={dailyGeneration}
                  StartTimer={setIsStartTimeDailyGen}
                  StopTimer={setIsStopTimeDailyGen}
                  openDrawerValue={setDrawerOpen}
                  titleText="Daily Energy Generation"
                  indicateUnit="MWh"
                  tooltipName="Energy Generation"
                  stringDurationFun={setGenerationStringDuration}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeDailyGen
                        ? isStartTimeDailyGen
                        : new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ),
                      endDate: isStopTimeDailyGen
                        ? isStopTimeDailyGen
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  isFilter={true}
                />
                {loadingState.dailyGen && (
                  <SimpleBackdrop open={loadingState.dailyGen} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="center_center_column"
        >
          <Grid container className="grid-container-smb">
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="notification-paper-one"
            >
              <Paper className="notification-paper-one flex-start_gap">
                <p className="Poppins_24px_rem" style={{ color: "#212121" }}>
                  Inverter Parameters
                </p>
                {inverterParameters.device_data?.timestamp_edge && <p className="inv_time">
                  Last updated on {inverterParameters.device_data?.timestamp_edge

                    ?<TimestampFormatter dateString={inverterParameters.device_data?.timestamp_edge}/> 
                    : "--"}
                </p>}

              </Paper>
            </Grid>
            <Grid container className="grid-container">
            {(plantId === 2 ? invPlantData : otherPlantData).map((ele, index) => (
              <Grid
                key={index}
                item
                lg={5.9}
                xs={12}
                sm={5.9}
                md={5.9}
                className="exc-graph-paper"
                sx={{ position: "relative" }}
              >
                <Paper className="paper_inv">
                  <div className="center_div_nogap div_parameter">
                    <p className="Poppins_18px_rem"> {ele.title}</p>
                  </div>
                  <div className="flex-start_space-between inv_param_div">
                    <div>
                      <p className="Poppins_18px_rem">{ele.subtitle1}</p>
                    </div>
                    <div className="only_flex-start">
                      <p className="Poppins_18px_blue">{ele.value1}</p>
                    </div>
                  </div>
                  <div className="flex-start_space-between inv_param_div">
                    <div>
                      <p className="Poppins_18px_rem">{ele.subtitle2}</p>
                    </div>
                    <div className="only_flex-start">
                      <p className="Poppins_18px_blue">{ele.value2}</p>
                    </div>
                  </div>
                  <div className="flex-start_space-between inv_param_div">
                    <div>
                      <p className="Poppins_18px_rem">{ele.subtitle3}</p>
                    </div>
                    <div className="only_flex-start">
                      <p className="Poppins_18px_blue">{ele.value3}</p>
                    </div>
                  </div>
                </Paper>
                {loadingState.invPara && (
                  <SimpleBackdrop open={loadingState.invPara} />
                )}
              </Grid>
            ))}
          </Grid>


          </Grid>
        </Grid>

        {/* {plantId !== 2 ? (
          <>
            <Grid item lg={5.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <BarPlotForIOT
                  isValueData={performanceValue}
                  StartTimer={setIsStartTimePerfRatio}
                  StopTimer={setIsStopTimePerfRatio}
                  openDrawerValue={setDrawerOpen}
                  titleText="Performance Ratio (%)"
                  tooltipName="Performance"
                  indicateUnit="%"
                />
              </Paper>
            </Grid>

            <Grid item lg={5.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <BarPlotForIOT
                  isValueData={generationValue}
                  StartTimer={setIsStartTimeGen}
                  StopTimer={setIsStopTimeGen}
                  openDrawerValue={setDrawerOpen}
                  titleText="Generation (kWh)"
                  indicateUnit="kWh"
                  tooltipName="Generation"
                  selectedType={setIsSelectedType}
                />
              </Paper>
            </Grid>

           
            <Grid item lg={5.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <INVLineChart
                  isValueData={perfOverViewValue}
                  StartTimer={setIsStartTimePerf}
                  StopTimer={setIsStopTimePerf}
                  openDrawerValue={setDrawerOpen}
                  titleText="Performance Overview"
                />
              </Paper>
            </Grid>

            <Grid item lg={5.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={temperatureValue}
                  StartTimer={setIsStartTimeTemp}
                  StopTimer={setIsStopTimeTemp}
                  openDrawerValue={setDrawerOpen}
                  yAxisText="Temperature (°C)"
                  titleText="Temperature Parameters"
                  legendName="Inside Cabin temperature"
                  indicateUnit="°C"
                  calenderClick="on"
                />
              </Paper>
            </Grid>
          </>
        ) : ( */}
          <>
            <Grid
              className="total_power_container"
              item
              lg={12}
              xs={12}
              md={12}
              sx={{ position: "relative" }}
            >
              <Paper className="forecasted_paper">
              <UnixDateChart 
             isValueData={actualIdealGen}
             openDrawerValue={setDrawerOpen}
             StartTimer={setIsStartTimeActualIdeal}
             StopTimer={setIsStopTimeActualIdeal}
             selectedDateRangeProp={[
              {
                startDate: isStartTimeActualIdeal
                  ? isStartTimeActualIdeal
                  : new Date(),
                endDate: isStopTimeActualIdeal
                  ? isStopTimeActualIdeal
                  : new Date(),
                key: "selection",
              },
            ]}/>
                
              </Paper>
              {loadingState.actualIdeal && (
                <SimpleBackdrop open={loadingState.actualIdeal} />
              )}
            </Grid>
            <Grid
              className="total_power_container"
              item
              lg={12}
              xs={12}
              md={12}
              sx={{ position: "relative" }}
            >
              <Paper className="forecasted_paper">
              <UnixDateChart 
             isValueData={actualForecasted}
             openDrawerValue={setDrawerOpen}
             StartTimer={setIsStartTimeActualForecasted}
             StopTimer={setIsStopTimeActualForecasted}
             selectedDateRangeProp={[
              {
                startDate: isStartTimeActualForecasted
                  ? isStartTimeActualForecasted
                  : new Date(),
                endDate: isStopTimeActualForecasted
                  ? isStopTimeActualForecasted
                  : new Date(),
                key: "selection",
              },
            ]}/>
                
              </Paper>
              {loadingState.actualForecasted && (
                <SimpleBackdrop open={loadingState.actualForecasted} />
              )}
            </Grid>
           
            <Grid
              className="total_power_container"
              item
              lg={12}
              xs={12}
              md={12}
              sx={{ position: "relative" }}
            >
              <Paper className="forecasted_paper">

             <UnixDateChart 
             isValueData={predictedData}
             openDrawerValue={setDrawerOpen}
             StartTimer={setIsStartTimePrediction}
             StopTimer={setIsStopTimePrediction}
             selectedDateRangeProp={[
                    {
                      startDate: isStartTimePrediction
                        ? isStartTimePrediction
                        : new Date(),
                      endDate: isStopTimePrediction
                        ? isStopTimePrediction
                        : new Date(),
                      key: "selection",
                    },
                  ]}/>
              </Paper>
              {loadingState.forecast && (
                <SimpleBackdrop open={loadingState.forecast} />
              )}
            </Grid>

            {inverterCompare.series && (
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="exc-graph-grid"
              >
                <Paper className="exc-graph-paper">
                  <BarWithLine
                    isValueData={inverterCompare}
                    StartTimer={setIsStartTimeInv}
                    StopTimer={setIsStopTimeInv}
                    openDrawerValue={setDrawerOpen}
                    yAxisText="Energy(kWh)"
                    indicateUnit="kWh"
                    stringDurationFun={setStringDuration}
                    selectedDateRangeProp={[
                      {
                        startDate: isStartTimeInv ? isStartTimeInv : new Date(),
                        endDate: isStopTimeInv ? isStopTimeInv : new Date(),
                        key: "selection",
                      },
                    ]}
                    isFilter={true}
                  />
                </Paper>
                {loadingState.energyCompare && (
                  <SimpleBackdrop open={loadingState.energyCompare} />
                )}
              </Grid>
            )}
           
            <Grid item lg={3.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={fieldEnergy}
                  StartTimer={setIsStartTimeEnergy}
                  StopTimer={setIsStopTimeEnergy}
                  openDrawerValue={setDrawerOpen}
                  yAxisText={plantId===2?"Energy (MWh)":"Energy (kWh)"}
                  titleText= {plantId===2 ?"Energy Counter MWh" :"Today Energy Generation Counter"}
                  legendName={plantId===2?"energy_counter_MWh":"energy_counter_kWh"}
                  indicateUnit= {plantId===2 ?"MWh":"kWh"}
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeEnergy
                        ? isStartTimeEnergy
                        : new Date(),
                      endDate: isStopTimeEnergy ? isStopTimeEnergy : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
              {loadingState.fieldEnergy && (
                <SimpleBackdrop open={loadingState.fieldEnergy} />
              )}
            </Grid>
            <Grid item lg={3.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={acVoltage}
                  StartTimer={setIsStartTimeAcVolt}
                  StopTimer={setIsStopTimeAcVolt}
                  openDrawerValue={setDrawerOpen}
                  yAxisText= {plantId===2 ?"Voltage (V)":"Temperature (°C)"}
                  titleText= {plantId===2 ? "Net AC voltage" :"Inside Cabin Temperature"}
                  legendName={plantId===2 ?"Net AC voltage":"Temperature"}
                  indicateUnit= {plantId===2 ?"V":"°C"}
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeAcVolt
                        ? isStartTimeAcVolt
                        : new Date(),
                      endDate: isStopTimeAcVolt ? isStopTimeAcVolt : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
              {loadingState.fieldACVolt && (
                <SimpleBackdrop open={loadingState.fieldACVolt} />
              )}
            </Grid>
            <Grid item lg={3.9} xs={12} md={5.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={outputPower}
                  StartTimer={setIsStartTimeOutPower}
                  StopTimer={setIsStopTimeOutPower}
                  openDrawerValue={setDrawerOpen}
                  yAxisText={plantId===2 ?"Output Power(kW)":"Net Frequency(Hz)"}
                  titleText={plantId===2?"Output Power":"Net Frequency"}
                  legendName={plantId===2?"Output Power":"Net Frequency"}
                  indicateUnit={plantId===2?"kW" :"Hz"}
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeOutPower
                        ? isStartTimeOutPower
                        : new Date(),
                      endDate: isStopTimeOutPower
                        ? isStopTimeOutPower
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                />
                {loadingState.outputPower && (
                  <SimpleBackdrop open={loadingState.outputPower} />
                )}
              </Paper>
            </Grid>
           
              
            <Grid item lg={plantId===2?3.9:11.9} xs={12} md={plantId===2?5.9:11.9} className="exc-graph-grid">
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={outputCurrent}
                  StartTimer={setIsStartTimeOutCurrent}
                  StopTimer={setIsStopTimeOutCurrent}
                  openDrawerValue={setDrawerOpen}
                  yAxisText={plantId===2?"Output Current":"Current Parameters"}
                  titleText={plantId===2?"Output Current":"Current Parameters"}
                  legendName="Output Power"
                  indicateUnit= "A"
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeOutCurrent
                        ? isStartTimeOutCurrent
                        : new Date(),
                      endDate: isStopTimeOutCurrent
                        ? isStopTimeOutCurrent
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
              {loadingState.outputCurrent && (
                <SimpleBackdrop open={loadingState.outputCurrent} />
              )}
            </Grid>
            <Grid item lg={plantId===2?3.9:11.9} xs={12} md={plantId===2?5.9:11.9} className="exc-graph-grid">

              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={dcVoltage}
                  StartTimer={setIsStartTimeDcVolt}
                  StopTimer={setIsStopTimeDcVolt}
                  openDrawerValue={setDrawerOpen}
                  yAxisText="DC Voltage"
                  titleText={plantId==2?"DC Voltage":"Voltage Parameters"}
                  legendName={plantId===2?"DC Voltage":"Voltage Parameters"}
                  indicateUnit="V"
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeDcVolt
                        ? isStartTimeDcVolt
                        : new Date(),
                      endDate: isStopTimeDcVolt ? isStopTimeDcVolt : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
              {loadingState.dcVoltage && (
                <SimpleBackdrop open={loadingState.dcVoltage} />
              )}
            </Grid>
            <Grid item lg={plantId===2?3.9:11.9} xs={12} md={plantId===2?5.9:11.9} className="exc-graph-grid">
            
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={avgPercent}
                  StartTimer={setIsStartTimeAvgPercent}
                  StopTimer={setIsStopTimeAvgPercent}
                  openDrawerValue={setDrawerOpen}
                  yAxisText="Average Cosphi Percent"
                  titleText={plantId===2?"Average Cosphi Percent":"Power Parameters"}
                  legendName={plantId===2?"Average Cosphi Percent":"Power Parameters"}
                  indicateUnit={plantId===2?"%":"kW" }
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeAvgPercent
                        ? isStartTimeAvgPercent
                        : new Date(),
                      endDate: isStopTimeAvgPercent
                        ? isStopTimeAvgPercent
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                />
                {loadingState.avgPercent && (
                  <SimpleBackdrop open={loadingState.avgPercent} />
                )}
              </Paper>
            </Grid>
        

          </>

        {/* )} */}
        {/* {plantId !== 2 && (
          <>
            <Grid
              item
              lg={5.9}
              xs={12}
              md={5.9}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none ! important",
                padding: "10px ! important",
                borderRadius: "0.7vw ! important",
                border: "1px solid #D9D9D9",
                backgroundColor: "white",
              }}
            >
              <Paper
                sx={{
                  width: "100% ! important",
                  boxShadow: "none !important",
                  borderRadius: "0.7vw ! important",
                  display: "flex",
                  flexDirection: "column",
                  height: gridHeight,
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <CommonText
                      type="Bold_400"
                      text="Inverter Parameters"
                      styles={{
                        fontFamily: "Poppins-Bold",
                        fontSize: "18px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Tooltip title="Download" arrow>
                      <DownloadIcon
                        onClick={downloadTableAsImage}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>

                    <Tooltip title="FullScreen" arrow>
                      <button
                        onClick={handleFullScreenToggle}
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                        }}
                      >
                        {isFullScreen ? (
                          <FullscreenExitIcon />
                        ) : (
                          <FullscreenIcon />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div
                  ref={chartContainerRef}
                  style={{ backgroundColor: isFullScreen ? "white" : "white" }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={"headerCell-class"}>
                            <CommonText
                              type="SemiBold_400"
                              text="DC Parameters"
                              styles={{ color: "white" }}
                            />
                          </TableCell>
                          <TableCell className={"headerCell-class"}>
                            <CommonText
                              type="SemiBold_400"
                              text="AC Parameters"
                              styles={{ color: "white" }}
                            />
                          </TableCell>
                          <TableCell className={"headerCell-class"}>
                            <CommonText
                              type="SemiBold_400"
                              text="AC Current & Voltage"
                              styles={{ color: "white" }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="custom-table-body">
                        <TableRow>
                          <TableCell
                            align="right"
                            className={"headerCell-class1"}
                          ></TableCell>
                          <TableCell
                            align="right"
                            className={"headerCell-class1"}
                          ></TableCell>

                          <TableCell
                            align="right"
                            className={"headerCell-class1"}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <CommonText type="SemiBold_400" text="" />
                              <CommonText
                                type="SemiBold_400"
                                text="
                      Voltage (V)
                        "
                              />
                              <CommonText
                                type="SemiBold_400"
                                text="
                         Current (A)
                        "
                              />
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableCell className={"headerCell-class1"}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="DC Current (A)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["dc_current"] !== null &&
                                  inverterParameters["dc_current"] !== undefined
                                  ? inverterParameters["dc_current"] === 0
                                    ? "0"
                                    : inverterParameters["dc_current"].toFixed(
                                      2
                                    )
                                  : "--"
                              }
                            />
                          </div>
                          <Divider
                            sx={{ mt: 0.6, mb: 0.6 }}
                            style={{ border: "1px solid gray" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="DC Voltage (V)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["dc_voltage"] !==
                                  undefined &&
                                  inverterParameters["dc_voltage"] !== null
                                  ? inverterParameters["dc_voltage"] === 0
                                    ? "0"
                                    : inverterParameters["dc_voltage"].toFixed(
                                      2
                                    )
                                  : "--"
                              }
                            />
                          </div>
                          <Divider
                            sx={{ mt: 0.6, mb: 0.6 }}
                            style={{ border: "1px solid gray" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="DC Power (kW)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["dc_input_power"] !==
                                  undefined &&
                                  inverterParameters["dc_input_power"] !== null
                                  ? inverterParameters["dc_input_power"] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "dc_input_power"
                                    ].toFixed(2)
                                  : "--"
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={"headerCell-class1"}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="Apparent Power (kVA)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["active_power"] !==
                                  undefined &&
                                  inverterParameters["active_power"] !== null
                                  ? inverterParameters["active_power"] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "active_power"
                                    ].toFixed(2)
                                  : "--"
                              }
                            />
                          </div>
                          <Divider
                            sx={{ mt: 0.6, mb: 0.6 }}
                            style={{ border: "1px solid gray" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="Reactive Power (KVAr)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["reactive_power"] !==
                                  undefined &&
                                  inverterParameters["reactive_power"] !== null
                                  ? inverterParameters["reactive_power"] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "reactive_power"
                                    ].toFixed(2)
                                  : "--"
                              }
                            />
                          </div>
                          <Divider
                            sx={{ mt: 0.6, mb: 0.6 }}
                            style={{ border: "1px solid gray" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText
                              type="Regular_400"
                              text="Grid Frequency (Hz)"
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_frequency"] !==
                                  undefined &&
                                  inverterParameters["grid_frequency"] !== null
                                  ? inverterParameters["grid_frequency"] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_frequency"
                                    ].toFixed(2)
                                  : "--"
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={"headerCell-class1"}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText type="Regular_400" text="R" />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_r_phase_voltage"] !==
                                  null &&
                                  inverterParameters["grid_r_phase_voltage"] !==
                                  undefined
                                  ? inverterParameters[
                                    "grid_r_phase_voltage"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_r_phase_voltage"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_r_phase_current"] !==
                                  undefined &&
                                  inverterParameters["grid_r_phase_current"] !==
                                  null
                                  ? inverterParameters[
                                    "grid_r_phase_current"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_r_phase_current"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText type="Regular_400" text="Y" />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_y_phase_voltage"] !==
                                  undefined &&
                                  inverterParameters["grid_y_phase_voltage"] !==
                                  null
                                  ? inverterParameters[
                                    "grid_y_phase_voltage"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_y_phase_voltage"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_y_phase_current"] !==
                                  undefined &&
                                  inverterParameters["grid_y_phase_current"] !==
                                  null
                                  ? inverterParameters[
                                    "grid_y_phase_current"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_y_phase_current"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CommonText type="Regular_400" text="B" />
                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_b_phase_voltage"] !==
                                  undefined &&
                                  inverterParameters["grid_b_phase_voltage"] !==
                                  null
                                  ? inverterParameters[
                                    "grid_b_phase_voltage"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_b_phase_voltage"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />

                            <CommonText
                              type="Regular_400"
                              text={
                                inverterParameters["grid_b_phase_current"] !==
                                  undefined &&
                                  inverterParameters["grid_b_phase_current"] !==
                                  null
                                  ? inverterParameters[
                                    "grid_b_phase_current"
                                  ] === 0
                                    ? "0"
                                    : inverterParameters[
                                      "grid_b_phase_current"
                                    ].toFixed(2)
                                  : "--"
                              }
                              styles={{
                                width: "6vw",
                                textAlign: "center",
                                borderBottom: "2px solid gray",
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              lg={5.9}
              xs={12}
              md={5.9}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none ! important",
                padding: "10px ! important",
                borderRadius: "0.7vw ! important",
                border: "1px solid #D9D9D9",
                backgroundColor: "white",
              }}
            >
              <Paper
                ref={gridRef}
                sx={{
                  width: "100% ! important",
                  boxShadow: "none !important",
                  borderRadius: "0.7vw ! important",
                }}
              >
                <NormalBarPlot
                  isValueData={isPerformance}
                  isTimeData={isDateTime}
                  titleText="CB Performance Overview"
                  heightPX={350}
                  calenderShown={true}
                  indicateUnit="%"
                  tooltipName="Performance"
                  colorCode={["#1569B1"]}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeAvgPercent
                        ? isStartTimeAvgPercent
                        : new Date(),
                      endDate: isStopTimeAvgPercent
                        ? isStopTimeAvgPercent
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
            </Grid>{" "}
          </>
        )} */}
       {defaultVal &&( <Grid item lg={11.9} xs={12} md={11.9} className="exc-graph-grid">
          <Paper className="exc-graph-paper">
            <MultiDateLineGraph
              isValueData={perfCompareVal}
              StartTimer={setIsStartTimeDeviceCompare}
              StopTimer={setIsStopTimeDeviceCompare}
              openDrawerValue={setDrawerOpen}
              titleText="Inverter Fields Comparison"
              legendName=""
              calenderClick="on"
              selectedType={setIsFieldCompareVal}
              fieldsDropdata={deviceFieldValue}
              defaultValue={defaultVal}
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeDeviceCompare ? isStartTimeDeviceCompare : new Date(),
                  endDate: isStopTimeDeviceCompare ? isStopTimeDeviceCompare : new Date(),
                  key: "selection",
                },
              ]}
            />
            {loadingState.deviceCompare && (
              <SimpleBackdrop open={loadingState.deviceCompare} />
            )}
          </Paper>
        </Grid>
        )}
      </Grid>
      {openDrawer && (
        <Box sx={warningBoxStyle} style={{ textAlign: "center" }}>
          <CloseIcon onClick={handleCloseWarningsModal} className="close-btn" />
          <BoldCommonText
            type="SemiBold_500"
            text="Warnings:"
            styles={{ color: "#303f9f", paddingBottom: "5px" }}
          />

          {warningsList.map((list, index) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                    background: list.includes("normal")
                      ? "#e3fad7"
                      : "antiquewhite",
                    marginBottom: "6px",
                    padding: "10px",
                  }}
                >
                  {list.includes("normal") ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon sx={{ color: "#ffb000" }} />
                  )}
                  <CommonText
                    type="Regular_400"
                    key={index}
                    styles={{
                      color: "black",
                      textAlign: "left",
                    }}
                    text={`${list}`}
                  />
                </div>
              </>
            );
          })}
        </Box>
      )}
    </div>
  );
});

export default InverterDetails;
