import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Dropdown from "react-dropdown";
const BarPlotForMFM = (props) => {
  const {
    legendName,
    isValueData,
    titleText,
    calenderClick,
    indicateUnit,
    yAxisText,
    stringDurationFun,selectedDateRangeProp
  } = props;
  const [isDurationType, setIsDurationType] = useState("Hourly");
  const [isType, setIsType] = useState("area");

  const [series, setSeries] = useState([]);
  const [xaxisData, setXaxisData] = useState([]);
  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [dayCount, setDayCount] = useState(0);
  const [durationType, setDurationType] = useState([
    // "2 MIN",
    "15 MIN",
    "30 MIN",
    "Hourly",
    // "Daily",
    // "Weekly",
    // "Monthly",
    // "Yearly",
  ]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);
 
  const uniqueDates = [
    ...new Set(isValueData.map((item) => item.timestamp_edge.split("T")[0])),
  ];

  useEffect(() => {
    if (isValueData && isValueData.length > 0) {
      if (isStartTimeEnergy && isStopTimeEnergy) {
        const date1 = new Date(isStartTimeEnergy.split("T")[0]);
        const date2 = new Date(isStopTimeEnergy.split("T")[0]);
        const diffTime = Math.abs(date2 - date1);
        const differenceInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDayCount(differenceInDays);
        if (differenceInDays >= 2) {
          setIsType("bar");
          setDurationType(["Daily", "Weekly", "Monthly", "Yearly"]);
          if (
            isDurationType === "2 MIN" ||
            isDurationType === "15 MIN" ||
            isDurationType === "30 MIN" ||
            isDurationType === "Hourly"
          ) {
            setIsDurationType("Daily");
            props.stringDurationFun("D");
          }
        } else {
          setIsType("area");
          setDurationType([
            "15 MIN",
            "30 MIN",
            "Hourly",
            // "Daily",
            // "Weekly",
            // "Monthly",
            // "Yearly",
          ]);
          setIsDurationType("Hourly");
          props.stringDurationFun("h");
          // setIsDurationType(isDurationType)
        }
      }
      const xaxisLabels = [];
      const groupedData = {};
      const seriesData = [];

      const allTimes = Array.from({ length: 24 }, (_, i) =>
        i < 10 ? "0" + i + ":00" : i + ":00"
      );

      if (
        uniqueDates.length >= 3 ||
        isDurationType === "Daily" ||
        isDurationType === "Weekly" ||
        isDurationType === "Monthly" ||
        isDurationType === "Yearly"
      ) {
        setIsType("bar");

        const parameterNames = Object.keys(isValueData[0]).filter(
          (key) => key !== "timestamp_edge"
        );

        parameterNames.forEach((parameter, index) => {
          seriesData.push({
            name: parameter,
            data: isValueData.map((item) => item[parameter]),
          });
        });
        xaxisLabels.push(
          ...isValueData.map(
            (item) => item.timestamp_edge.match(/^\d{4}-\d{2}-\d{2}/)[0]
          )
        );
        setSeries(seriesData);
        setXaxisData(xaxisLabels);
      } else if (
        isDurationType === "Hourly" ||
        isDurationType === "15 MIN" ||
        isDurationType === "30 MIN" ||
        isDurationType === "2 MIN" ||
        uniqueDates.length < 3
      ) {
        setIsType("area");
        setIsDurationType("Hourly");
        props.stringDurationFun("h");
        // Iterate through each data item and group by date and time
        isValueData.forEach((item) => {
          const date = item.timestamp_edge.split("T")[0];
          const time = item.timestamp_edge.split("T")[1].substring(0, 5); // Extract hour:minute
          if (!groupedData[date]) {
            groupedData[date] = {};
          }

          for (const field in item) {
            if (field !== "timestamp_edge") {
              if (!groupedData[date][field]) {
                groupedData[date][field] = [];
              }
              if (!groupedData[date][field][time]) {
                groupedData[date][field][time] = []; // Initialize time slot with empty array
              }
              groupedData[date][field][time].push(item[field]);
            }
          }
        });

        // Create series for each unique date and field combination
        Object.keys(groupedData).forEach((date) => {
          const dataForDate = [];
          Object.keys(groupedData[date]).forEach((field) => {
            const fieldData = groupedData[date][field];
            const summedData = allTimes.map((time) =>
              fieldData[time] ? fieldData[time][0] : null
            ); // Use null for missing values
            dataForDate.push({
              name: `${date} - ${field}`,
              data: allTimes.map((time, index) => ({
                x: time,
                y: summedData[index],
              })), // Include null values
            });
          });
          seriesData.push(...dataForDate); // Push the series data for the current date to the main array
        });
        setSeries(seriesData);
        // Set x-axis labels to include all times
        xaxisLabels.push(...allTimes);

        setXaxisData(xaxisLabels);
      }
    }
  }, [isValueData, isDurationType]);

  const [options, setOptions] = useState({
    chart: {
      type: isType,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth:"30%",
      },
    },
    
    colors: ["#90E900","#2F67F8"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: isType === "area" ? 2 : 0,
    },
    xaxis: {
      categories: xaxisData,
      //   type: "datetime",

      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
    },
    yaxis: {
      title: {
        text: yAxisText,
        style: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
      labels: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2);
        },

        style: {
          colors: "#000000",
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },
        },
      },
    },

    fill: {
      type: isType === "area" ? "gradient" : "solid",
      gradient: {
        shade: isFullScreen ? "dark" : "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      // position: "top",
      showForSingleSeries: true,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2) + indicateUnit;
        },
      },
    },
    toolbar: {
      show: false,
    },
  });

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [isFullScreen]);
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
        },
      },
      
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        // type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
        },
      },
      fill: {
        type: isType === "area" ? "gradient" : "solid",

        gradient: {
          shade: isFullScreen ? "dark" : "light",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2) + indicateUnit;
          },
        },
      },
      yaxis: {
        ...prevOptions.yaxis,
        text: yAxisText,
        style: {
          colors: isFullScreen ? "white" : "#000000",
        },

        labels: {
          ...prevOptions.yaxis.labels,

          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            return value.toFixed(2);
          },

          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
          y: {
            formatter: function (value) {
              if (value === null || typeof value === "undefined") {
                return ""; // Return an empty string if value is undefined
              }
              return value.toFixed(2);
            },
          },
        },
      },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
    }
  }, [
    isValueData,
    isFullScreen,
    xaxisData,
    // series,
    legendName,
    titleText,
  ]);

  const handleFullScreenToggle = () => {
    const chartContainer = chartContainerRef.current;

    if (!isFullScreen) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) {
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) {
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) {
        chartContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };
  
  

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
   
    setIsStartTimeEnergy(date);

    props.openDrawerValue(openDrawer);
  };

  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    
    setIsStopTimeEnergy(date);

    props.openDrawerValue(openDrawer);
  };
  const handleChangeDeviceDuration = (device) => {
    if (dayCount > 2 || uniqueDates.length >= 3) {
      if (
        isDurationType === "2 MIN" ||
        isDurationType === "15 MIN" ||
        isDurationType === "30 MIN" ||
        isDurationType === "Hourly"
      ) {
        setIsType("bar");
        setIsDurationType("Daily");
        props.stringDurationFun("D");
      } else {
        setIsType("area");
        setIsDurationType("Hourly");
        props.stringDurationFun("h");
        // setIsDurationType(device.value);
      }
    } else if (dayCount < 3) {
      setIsType("area");
      setIsDurationType("Hourly");
      props.stringDurationFun("h");
      // setIsDurationType(device.value);
    }

    if (device.value === "2 MIN") {
      props.stringDurationFun("2min");
    } else if (device.value === "15 MIN") {
      props.stringDurationFun("15min");
    } else if (device.value === "30 MIN") {
      props.stringDurationFun("30min");
    } else if (device.value === "Hourly") {
      props.stringDurationFun("h");
    } else if (device.value === "Daily") {
      props.stringDurationFun("D");
    } else if (device.value === "Weekly") {
      props.stringDurationFun("W");
    } else if (device.value === "Monthly") {
      props.stringDurationFun("ME");
    } else if (device.value === "Yearly") {
      props.stringDurationFun("YE");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: isFullScreen ? "white" : "#212121" }}
          >
            {titleText}
          </p>{" "}
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div style={{ width: "auto", minWidth: "1.6vw", cursor: "pointer" }} id="exc-linegraph">
            <Dropdown
              className="recommendations-text"
              options={durationType.map((field) =>
                field
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              )}
              onChange={handleChangeDeviceDuration}
              value={isDurationType}
            />
          </div> */}
          <div style={{display:"flex", alignItems:"baseline", gap:"10px", justifyContent:"center"}}>
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
              className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={handleFullScreenToggle}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                 className='icon-css'
                />
              ) : (
                <FullscreenIcon
                 className='icon-css'
                />
              )}
            </button>
          </Tooltip>
          </div>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}

      <div
        style={{
          height: isValueData.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {isValueData && isValueData.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "350px",
              width:"100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
        <div style={{ width: xaxisData.length > 30 ? xaxisData.length * 50 : 'auto' }}>
          <Chart options={options} series={series} type={isType} height={isFullScreen ? "800" : "350"} />
          </div>
          </div>)}
      </div>
    </div>
  );
};

export default BarPlotForMFM;
