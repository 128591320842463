import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function FaultProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/secondImgOne.svg",
            title:"Comprehensive AI-Driven Data Analysis",
            description:"Our Comprehensive Data Analysis feature integrates diverse data sources to refine fault predictions with high accuracy. By analyzing historical data, current sensor readings, and environmental factors, our advanced models provide probability scores for specific issues, such as inverter or tracker faults. This precise analysis enables targeted maintenance and proactive fault prevention, ensuring timely interventions and enhancing the overall reliability of your solar farm."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/SecondImgTwo.svg",
            title:"Early Warning System",
            description:"This system provides alerts 24 to 48 hours before potential failures, allowing for proactive maintenance. It forecasts the probability of a failure for specific devices, such as inverters or trackers and the advance notice enables targeted interventions based on fault probability scores, ensuring timely action and minimizing disruptions."
        },
      
    ]

  const keyPoints = [
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/secondKey1.svg",
        title:"Precise Alerts",
        description:"AI and PINNs deliver accurate fault predictions for timely maintenance."
    },
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/secondKey2.svg",
        title:"Focused Interventions",
        description:"Early warnings and fault probabilities enable targeted, cost-effective actions."
    },
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/secondKey3.svg",
        title:"Increased Reliability",
        description:"Enhanced fault detection and proactive alerts boost system reliability and minimize downtime."
    },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideTwo.jpg" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Fault Prediction</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Utilizes AI and Physics-Informed Neural Networks for early fault detection and precise maintenance alerts, boosting system reliability.
          </p>
        </div>
      </div>
     
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Employs Physics-Informed Neural Networks (PINNs) to model the entire plant by balancing data and physics. Each independently trained PINN acts as a complete digital twin, ensuring precise fault detection and system reliability.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphTwo.jpg' alt='prod' className='infoGraph' style={{width:"36%"}} />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
      <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
            {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
                <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default FaultProductsPage