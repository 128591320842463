import React, { useEffect, useRef, useState } from 'react'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import { CosmicInverterService } from '../../../Services/CosmicInverterService';
import SimpleBackdrop from '../../../../LifeScience/components/common/SimpleBackdrop';
import TableNoCheckBox from '../../../Common/TableNoCheckBox';
import useErrorHandler from '../../../../utils/errorHandler';
import ButtonSlider from '../../../Common/ButtonSlider';

const invService = new CosmicInverterService();
function TrackersHome() {
  const [gridHeight, setGridHeight] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const columns = [
    { field: "node_id", title: "Node ID" },
    { field: "sun_angle", title: "Sun Angle" },
    { field: "tracker_angle", title: "Tracker Angle" },
    { field: "status", title: "Status" },
    { field: "build_date", title: "Build Date" },
    { field: "last_active", title: "Last Active" },
  ];
  const [loadingState, setLoadingState] = useState({
    trackerList: false
  });
  const [trackersList, setTrackersList] = useState([]);
  const [allTrackerDetail, setAllTrackerDetail] = useState({});
  const [tableData, setTableData] = useState([]);
  const [deviceDatas, setDeviceDatas] = useState([]);

  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 10; 
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const history = useHistory();
  const gridRef = useRef(null);
  const totalPages = Math.ceil(trackersList.length / itemsPerPage);
  const handleFetchError = useErrorHandler();
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const startIndex = currentPage * itemsPerPage;
  let visibleTrackers = trackersList.slice(startIndex, startIndex + itemsPerPage);
   if (currentPage === 0) {
    visibleTrackers = [
      {
        NodeID: "Trackers Dashboard",
      },
      ...visibleTrackers,
    ];
  }
  useEffect(() => {

    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);

  const invStatus = [
    {
      title: "Active Trackers",
      value: allTrackerDetail?.active_trackers ? allTrackerDetail.active_trackers : "0",
      total: (allTrackerDetail?.active_trackers || allTrackerDetail?.inactive_trackers) ? allTrackerDetail.active_trackers + allTrackerDetail.inactive_trackers : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/switch-on.png",
    },
    {
      title: "Inactive Trackers",
      value: allTrackerDetail?.inactive_trackers ? allTrackerDetail.inactive_trackers : "0",
      total: (allTrackerDetail?.active_trackers || allTrackerDetail?.inactive_trackers) ? allTrackerDetail.active_trackers + allTrackerDetail.inactive_trackers : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/switch-off.png",
    },
    // {
    //   title: "Total Off Motors",
    //   value: 0,
    //   total: 1,

    //   iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/vcbStatus.svg",
    // },
  ];
  const PowerPlant = [
    {
      title: "Cumulative",
      subtitle: "Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",


      subtitle2: "Apparent",
      value2: "525* MVah",

      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#00529A",
    },
    {
      title: "Daily",
      subtitle: "Energy Loss",

      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",


      subtitle2: "Apparent",
      value2: "525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#FDA737",
    },
    {
      title: "Weekly",
      subtitle: "Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",


      subtitle2: "Apparent",
      value2: "525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#04724D",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(
    Array.from({ length: PowerPlant.length }, () => null)
  );
  useEffect(() => {
    handleTrackersList();
  }, [])

  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 
  const handleTrackerTopClick = (tracker, id, index) => {
    if (id !== "Trackers Dashboard") {
      history.push({
        pathname: `/iot-home/dev-monitoring/trackers/home`,
        state: {
          trackerDeviceData: tracker,
          trackerId: id
        }
      });
    }
  }
  const convertBuildDate = (buildDate) => {
    if (!buildDate) return '';

    const [hours, minutes, seconds, day, month, year] = buildDate.split(':');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  
  const handleTrackersList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, trackerList: true }));
      const res = await invService.getTrackerLists();
      if (res.status === 200) {
        const tableDatas = res.data.nodes.map((item) => ({
          node_id: item?.NodeID ? item.NodeID : "--",
          sun_angle: item?.Orientation?.Sun_Angle,
          tracker_angle: item?.Orientation?.Tracker_Angle,
          status: item?.status,
          build_date:convertBuildDate(item?.BuildDate),
          last_active: formatDate(item?.last_active)
        }));
        setTableData(tableDatas);
        setAllTrackerDetail(res.data)
        setTrackersList(res.data.nodes);
        const formattedData = res.data.nodes
        .map((item) => ({
          device_name: item?.NodeID ? item.NodeID : "--",
        }));
        
        setDeviceDatas([ {
          device_name : "Trackers Dashboard",
        },
        ...formattedData])

      }
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
      handleFetchError(error, "Trackers List");
    }
  };
  const handleTrackerClick = (tracker) => {
    history.push({
      pathname: `/iot-home/dev-monitoring/trackers/home`,
      state: {
        trackerId: tracker.node_id
      }
    }
    );
  }

  return (
    <div className="home-container">
      <Grid
        container
        className="grid-container"
      >
        <Grid
          container
          className="inv_names_container"
          sx={{
            padding: "1rem !important", backgroundColor: "white !important",
            border: "0.0625rem solid #D8D8D8 !important", borderRadius: "0.75rem !important"
          }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
            sx={{position:"relative"}}
          >
            <Grid
              container
              className="inv-names_grid"
            >
              {trackersList.length > 0 ? (
<>
        
          <ButtonSlider isValueData={visibleTrackers} handleInvDeatil={handleTrackerTopClick} itemsPerPage={itemsPerPage} />
        </>
              ) : (
                <div className='no-data'>
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>No data is available!</p>
                </div>
              )}
            </Grid>
            {loadingState.trackerList && (
              <SimpleBackdrop open={loadingState.trackerList} />
            )}
          </Grid>
        </Grid>
      
        <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            // className="only_flex-start"
          className="total_power_container"

          >
            <Grid
              container
              sx={{borderRadius:"0.75rem"}}
              // className="inv-names_grid"
            >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          sx={{borderRadius:"0.75rem"}}
        >
          <Paper className="inv_total_paper only_column_gap" sx={{paddingBottom:"0rem !important"}}>
            <p className="Poppins_18px_rem" style={{ color: "#2F67F8" }}>
              Trackers
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          sx={{borderRadius:"0.75rem", position:"relative"}}
        >
          <Grid container className="inv_total_paper only_column_gap">
            {invStatus.map((ele, index) => (
              <Grid item
              lg={5.9}
              xs={12}
              sm={12}
              md={5.9}
               key={index}

                className="tracker-one-div tracker-one-div_padding"
              >
                <div>
                  <img
                    src={ele.iconInv}
                    alt="invStatus"
                    style={{ width: "4rem" }}
                  />
                </div>
                <div className="flex-start-column-two nogap">
                  <p
                    className="raleway_14px_blue"
                    style={{ color: "#2F67F8" }}
                  >
                    {ele.title}
                  </p>
                  <p
                    className="raleway_24px_rem"
                    style={{ fontFamily: "Poppins" }}
                  >
                    {ele.value}/
                    <span
                      className="Poppins_18px_rem"
                      style={{ color: "#212121" }}
                    >
                      {ele.total}
                    </span>
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
          {loadingState.trackerList && (
              <SimpleBackdrop open={loadingState.trackerList} />
            )}
        </Grid>
        </Grid>
        </Grid>
        {/* {PowerPlant.map((ele, index) => (
          <Grid
            key={index}
            item
            lg={3}
            xs={12}
            sm={12}
            md={5.9}
            sx={{
              height: gridHeight,
              gap: "2.5rem !important"
            }}
            className="power-plant-grid-exc"
          >
            <Paper className="power-plant-paper-exc">
              <div className="center_div">
                <div className="icon-exc-div">
                  <img
                    src={ele.PlantIcon}
                    alt="plant"
                    style={{ width: "2rem" }}
                  />
                </div>
                <div className="flex-start-column">
                  <p className="Poppins_18px_rem">{ele.title}</p>
                  <p className="Poppins_18px_rem">{ele.subtitle}</p>
                </div>
              </div>
              <div
                // onMouseEnter={(event) => handlePopoverOpen(index, event)}
                //  onMouseLeave={() => handlePopoverClose(index)}
                onClick={(event) => handlePopoverOpen(index, event)}
              >
                <img

                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                  alt="plant"
                  style={{ width: "1rem", cursor: "pointer" }}
                />
              </div>
            </Paper>
            <Paper className="powerplant-sec-paper">
              <div
                className="powerplant-div-exc height-energy"
                style={{
                  borderLeft: `0.2rem solid ${ele.colorCode}`,
                }}
              >
                <p className="raleway_14px_rem">{ele.subtitle1}</p>
                <p className="Poppins_18px_rem">
                  {ele.value1 ? ele.value1 : "--"}
                </p>
              </div>
              <div
                className="powerplant-div-exc height-energy"
                style={{
                  borderLeft: `0.2rem solid ${ele.colorCode}`,
                }}
              >
                <p className="raleway_14px_rem">{ele.subtitle2}</p>
                <p className="Poppins_18px_rem">
                  {ele.value2 ? ele.value2 : "--"}
                </p>
              </div>

            </Paper>
            <Popover
              key={index}
              open={Boolean(anchorEl[index])}
              anchorEl={anchorEl[index]}
              onClose={() => handlePopoverClose(index)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{ className: "customPopoverPaper-exc" }}
            >
              <div className="center_div">
                <p className="raleway_14px_rem">{ele.subtitle1}</p>:
                <p className="Poppins_18px_rem">
                  {ele.value1 ? ele.value1 : "--"}
                </p>
              </div>
              <div className="center_div">
                <p className="raleway_14px_rem">{ele.subtitle2}</p>:
                <p className="Poppins_18px_rem">
                  {ele.value2 ? ele.value2 : "--"}
                </p>
              </div>

            </Popover>
          </Grid>
        ))} */}
        {/* <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="notification-grid"
        >
          <Paper className="notification-paper-one">
            <div style={{ padding: "1rem" }}>
              <p className="Poppins_24px_rem" style={{ color: "#212121" }}>
                Important Notifications
              </p>
            </div>
            <Divider
              sx={{ mt: 1 }}
              style={{ border: "0.0625rem solid #D9D9D9" }}
            />
          </Paper>
           {NotificationData.map((ele, index) => (
          <Paper
            className="notification-paper"
            key={index}
            sx={
              {
                borderBottom:
                  index === NotificationData.length - 1
                    ? "none"
                    : "0.125rem solid #D9D9D9",
              }
            }
          >
            <p className="Poppins_18px_rem">No Notification yet</p>
            <div
                    style={{
                      padding: "1rem 1.5rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div> 
          </Paper>
         ))}
        </Grid> */}
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={11.9}
          className="center_center_column"
          sx={{position: "relative"}}
        >
          <TableNoCheckBox
            data={tableData}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
            handleRowClick={handleTrackerClick}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            serchText="Search Node ID"
            titleText="Trackers List"
          />
           {loadingState.trackerList && (
              <SimpleBackdrop open={loadingState.trackerList} />
            )}
        </Grid>
      </Grid>
    </div>
  )
}

export default TrackersHome