import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import "./MFM.scss";
import "../../../Common/FontSizes.scss";
import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import EnergyData from "../../../Common/EnergyData";
import TableNoCheckBox from "../../../Common/TableNoCheckBox";
import useErrorHandler from "../../../../utils/errorHandler";
const invService = new CosmicInverterService();
function MFM() {
  const [tableData, setTableData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleFetchError = useErrorHandler();
  const columns = [
    { field: "name", title: "MFM" },
    { field: "current", title: "Current (A)" },
    { field: "voltage", title: "Voltage (V)" },
    { field: "Energy", title: "Active Energy (kWh)" },
    { field: "status", title: "Status" },
    { field: "raised_at", title: "Last Updated Timestamp" },
  ];

  const mfmDataSet = [
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: "-- A",
      title: "Phase A RMS Current",
    },
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: "-- A",
      title: "Phase B RMS Current",
    },
    {
      iconImg: "https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg",
      value: "-- A",
      title: "Phase C RMS Current",
    },
  ];

  const exportData = [
    {
      subtitle: "Active Energy",
      value: "-- kVA",
    },
    {
      subtitle: "Reactive Energy",
      value: "-- kVARh",
    },
    {
      subtitle: "Apparent Power",
      value: "-- kVA",
    },
  ];
  const importData = [
    {
      subtitle: "Active Energy",
      value: "-- kVA",
    },
    {
      subtitle: "Reactive Energy",
      value: "-- kVARh",
    },
    {
      subtitle: "Apparent Power",
      value: "-- kVA",
    },
  ];

  useEffect(() => {
    handleFieldsList();
  }, []);

  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  useEffect(() => {
    if (id) {
      handleMfmParameters(id);
      // handleMFMDeviceID(id);
    }
  }, []);
  const gridRef = useRef(null);

  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridHeight, setGridHeight] = useState(0);

  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // const handleMFMDeviceID = async (id) => {
  //     try {
  //         setLoading(true);
  //         const res = await invService.getPlantDevices(id, "mfm");
  //         if (res.status === 200) {

  //             const fetchData = async () => {
  //                 await handleDeviceTable(res.data[0].id);
  //             };
  //             fetchData();
  //             // const intervalId = setInterval(fetchData, 5 * 60 * 1000);
  //             // return () => clearInterval(intervalId);
  //         }
  //         setLoading(false);
  //     } catch (error) {
  //         if (error.response && error.response.status === 404) {
  //             if (!warningDisplayed) {
  //                 enqueueSnackbar(error.response.data.detail, {
  //                     variant: "error",
  //                     anchorOrigin: {
  //                         vertical: "bottom",
  //                         horizontal: "left",
  //                     },
  //                 });
  //                 warningDisplayed = true;
  //             }

  //             setLoading(false);
  //         }
  //         if (error.response && error.response.status === 500) {
  //             history.push("/iot-home/server-error");
  //             setLoading(false);
  //         } else {
  //             console.log("Error while fetching the data", error);
  //             if (!warningDisplayed) {
  //                 enqueueSnackbar(error.response.data.detail, {
  //                     variant: "error",
  //                     anchorOrigin: {
  //                         vertical: "bottom",
  //                         horizontal: "left",
  //                     },
  //                 });
  //                 warningDisplayed = true;
  //             }
  //             setLoading(false);
  //         }
  //     }
  // };
  
  const handleMfmClick = (mfmDevice) => {
    history.push({
      pathname: `/iot-home/dev-monitoring/mfm/mfm-details`,
      state: {
        mfmDeviceData: mfmDevice,
        mfmList: tableData,
        firstField: deviceFieldValue[0],
      },
    });
  };
  const handleFieldsList = async () => {
    try {
      setLoading(true);

      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "Inverter field List");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        const tableDatas = res.data.map((item) => ({
          name: item?.device_details?.name,
          current:item?.device_data?.phase_A_RMS_current_A.toFixed(2),
          voltage: item?.device_data?.phase_A_RMS_voltage_V.toFixed(2),
          Energy: item?.device_data?.positive_active_energy_kWh.toFixed(2),
          status: item?.device_details?.status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_details?.last_active.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
          device_data: item?.device_data?.device_id,
          id: item?.device_details?.id,
        }));
        setTableData(tableDatas);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "mfm field List");
    }
  };

  const handleDeviceTable = async (device_id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceTable(device_id, "mfm");
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedDate = item["Date(YYYY-MM-DD)"]
            ? item["Date(YYYY-MM-DD)"].split("T")[0]
            : "--";

          const formattedItem = { ...item, "Date(YYYY-MM-DD)": formattedDate };

          // Iterate through keys to apply toFixed(2) on numeric values
          for (let key in formattedItem) {
            if (typeof formattedItem[key] === "number") {
              formattedItem[key] = formattedItem[key].toFixed(2);
            }
          }

          return formattedItem;
        });

        setDeviceTableData(formattedData);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "mfm table data");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  // const mfmData = tableData.filter(smb =>
  //     smb.device_details.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  return (
    <div className="home-container">
      {loading && <SimpleBackdrop open={loading} />}
      <Grid container className="grid-container">
        {/* <Grid
                    item
                    lg={12}
                    xs={12}
                    md={11.9}
                    className='table_grid'
                >
                    <Paper className='table_paper'
                    >
                        {deviceTableData.length > 0 ? (<TableContainer
                            className="table_data_scroll"
                        >
                            <Table
                                stickyHeader
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(deviceTableData[0]).map((key, index) => (
                                            <TableCell key={index} sx={{ backgroundColor: "#FFFBE6" }}
                                            >
                                                <p className="raleway_12px_rem" id="table_cell_padding">{key}</p>
                                            </TableCell>
                                        ))}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deviceTableData.map((field, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {Object.values(field).map((value, cellIndex) => (
                                                <TableCell key={cellIndex} align="left">
                                                    <p className="Poppins_12px_rem" id="table_cell_padding">{value}</p>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>) :
                            (
                                <div className='no-data'>
                                    <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>No data is available!</p>
                                </div>
                            )}
                    </Paper>
                </Grid> */}
        <Grid item lg={12} xs={12} sm={12} md={12} className="heading_grid">
          <Paper
            sx={{
              width: "100% !important",
              padding: "10px",
              backgroundColor: "transparent",
              boxShadow: "none !important",
            }}
          >
            <p className="Poppins_32px_rem" style={{ color: "#2F67F8" }}>
              MFM Performance Summary
            </p>
          </Paper>
        </Grid>

        <Grid
          item
          lg={5}
          xs={12}
          sm={12}
          md={12}
          className="total_power_container"
          sx={{
            height: gridHeight,
          }}
        >
          <Paper className="smb-paper">
            <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
              MFMs
            </p>
            <div className="center_div">
              <div className="smb-one-div">
                <div className="flex-start-column-two">
                  <p className="raleway_14px_rem">Daily Generated Power</p>
                  <div className="center_div_gap">
                    <p
                      className="Poppins_32px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      --
                    </p>
                    <p
                      className="raleway_18px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      kW
                    </p>
                  </div>
                </div>
              </div>
              <div className="smb-one-div">
                <div className="flex-start-column-two">
                  <p className="raleway_14px_rem">
                    Total Yield Energy Till Now
                  </p>
                  <div className="center_div_gap">
                    <p
                      className="Poppins_32px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      --
                    </p>
                    <p
                      className="raleway_18px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      kWh
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={6.9}
          xs={12}
          sm={12}
          md={12}
          className="center_center_column"
          ref={gridRef}
        >
          <Grid container className="grid-container">
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="center_center_flex_gap"
            >
              <Paper className="gen-power_paper">
                <p
                  className="Poppins_24px_rem"
                  style={{ color: "#212121", textAlign: "center" }}
                >
                  Generated Energy
                </p>
              </Paper>
            </Grid>

            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Today’s"
                  colorCode="#00529A"
                />
              </Paper>
            </Grid>
            <Divider className="divider-style" orientation="vertical" />
            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Yesterday’s"
                  colorCode="#FDA737"
                />
              </Paper>
            </Grid>
            <Divider className="divider-style" orientation="vertical" />
            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Monthly"
                  colorCode="#04724D"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={3.95}
          xs={12}
          sm={12}
          md={3.95}
          className="mfm_data_grid"
        >
          <div className="metric-box">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
              alt="metric"
              className="metric-icon"
            />
            <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
              Cumulative Import Energy
            </p>
          </div>
          <Grid container className="grid-container-column">
            {importData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={10}
                  xs={10}
                  sm={10}
                  md={10}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_18px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_20px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item lg={3.95} xs={12} sm={12} md={3.9} className="mfm_data_grid">
          <div className="metric-box">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
              alt="metric"
              className="metric-icon"
            />
            <p className="raleway_24px_rem" style={{ fontWeight: "700" }}>
              Cumulative Export Energy
            </p>
          </div>
          <Grid container className="grid-container-column">
            {exportData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={10}
                  xs={10}
                  sm={10}
                  md={10}
                  className="mfm_grid_item"
                >
                  <Paper className="key_value_container">
                    <p
                      className="raleway_18px_rem"
                      style={{ fontWeight: "700" }}
                    >
                      {item.subtitle}
                    </p>
                    <p className="Poppins_20px_rem" id="blue_value">
                      {item.value}
                    </p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          lg={3.95}
          xs={12}
          sm={12}
          md={3.95}
          className="mfm_data_grid"
        >
          <Grid container className="grid-container-column">
            {mfmDataSet.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={10}
                  xs={10}
                  sm={10}
                  md={10}
                  className="three-grids-container"
                >
                  <Paper className="four_grids_paper">
                    <div className="center_div_gap">
                      <div className="icon_container">
                        <img
                          src={item.iconImg}
                          alt="gen"
                          className="icon_3rem"
                        />
                      </div>

                      <p className="raleway_18px_rem">{item.title}</p>
                    </div>
                    <p className="Poppins_28px_rem">{item.value}</p>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="notification_grid"
        >
          <Paper className="notification_paper">
            <div className="imp_notification_block">
              <p className="raleway_24px_rem" style={{ color: "#212121" }}>
                Important Notifications
              </p>
            </div>
          </Paper>
          <Divider className="divider_nomargin" />
          {/* {NotificationData.map((ele, index) => ( */}
          <Paper
            // key={index}
            className="notification_paper"
          >
            <div className="imp_notification_block">
              <p
                className="raleway_18px_rem"
                style={{
                  fontWeight: "700",
                }}
              >
                No Notification yet
              </p>
            </div>
            {/* <div
            style={{
              padding: "1vh 2vw",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: "0.5vw",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "baseline",
              }}
            >
              <WarningRoundedIcon style={{ color: "#FFA287" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "baseline",
              }}
            >
              <p className="Poppins_18px_rem">
                {ele.title}
                <span style={{ fontWeight: "400" }}>
                  &nbsp;{ele.disc}
                </span>
              </p>
            </div>
          </div> */}
          </Paper>
          {/* ))} */}
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={11.9}
          className="center_center_column"
        >
          <TableNoCheckBox
            data={tableData}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
            handleRowClick={handleMfmClick}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            serchText="Search MFM"
            titleText="MFM List"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MFM;
