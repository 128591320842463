import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function EnergyProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FirstImgOne.svg",
            title:"Advanced Deep Learning Models",
            description:"PowerOptix employs advanced deep learning models like FourcastNet, Graphcast, and TimesNet to deliver highly accurate energy forecasts. By integrating time-series analysis with meteorological data from national agencies, we ensure precise energy predictions, perfectly aligning production with weather conditions to maximize efficiency and reliability.",
            extraDescription:"Our deep learning models utilize vast datasets and sophisticated algorithms to refine forecasting capabilities. This approach not only enhances the accuracy of energy predictions but also supports seamless integration with grid management systems. The result is a robust solution that maximizes operational efficiency and stability, delivering optimal energy generation forecasts tailored to real-world conditions."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FirstImgTwo.svg",
            title:"High Accuracy Predictions",
            description:"We deliver precise energy forecasts using advanced ensemble models that aggregate and weight data from diverse sources, including weather data, historical trends, and real-time conditions. This sophisticated approach ensures inverter-level accuracy, enabling optimal control and maximizing efficiency in solar power generation."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FirstImgThree.svg",
            title:"Integration with Grid Management",
            description:"This feature optimizes energy distribution by seamlessly integrating with grid management systems, ensuring that energy production aligns perfectly with demand forecasts. Our system efficiently manages energy storage and release at the inverter level, storing energy until it reaches a threshold before redirecting the surplus to the grid, ultimately ensuring peak efficiency grid stability and performance."
        },
    ]

  const keyPoints = [
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/firstKeyOne.svg",
        title:"Enhanced Precision",
        description:"Advanced AI models deliver precise energy predictions for optimal performance."
    },
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/firstKeyTwo.svg",
        title:"Granular Control",
        description:"Real-time data and advanced algorithms enable precise adjustments."
    },
    {
        keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/firstKeyThree.svg",
        title:"Optimal Efficiency",
        description:"Seamlessly aligns energy output with demand forecasts for peak grid stability."
    },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideOne.jpg" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Accurate Energy Generation Forecasting</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Harnesses advanced AI to deliver precise energy forecasts and seamlessly syncs with grid systems for peak performance and efficiency.</p>
        </div>
      </div>
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Aggregates data from diverse sources using advanced deep learning time-series models. These custom models, trained on large datasets, provide precise and reliable energy predictions for optimal plant performance.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphOne.jpg' alt='prod' className='infoGraph' />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
        <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.extraDescription}</p>

        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
          {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
                <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default EnergyProductsPage