import { Grid, Paper } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function ButtonSlider(props) {
  const {isValueData, deviceId, itemsPerPage} = props
  // const isValueData = Array.from({ length: 12 }, (v, i) => ({
  //   id: `device${i + 1}`,
  //   name: `Inverter ${i + 1}`,
  //   device_details: {
  //     id: `device${i + 1}`,
  //     name: `Inverter ${i + 1}`
  //   },
  //   NodeID: `node${i + 1}`
  // }));
  const [currentPage, setCurrentPage] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const startIndex = currentPage * itemsPerPage;
  const totalPages = Math.ceil(isValueData.length / itemsPerPage);
  const containerRef = useRef(null);
  const buttonsRef = useRef([]);

  useEffect(() => {
    // Calculate total width of the buttons and the container
    if (containerRef.current && buttonsRef.current.length > 0) {
      const containerWidth = containerRef.current.offsetWidth;
      const totalButtonsWidth = buttonsRef.current.reduce(
        (total, btn) => total + (btn ? btn.offsetWidth : 0),
        0
      );

      // Show arrows if buttons take up more than 80% of the container width or if multiple pages
      setShowArrows(totalButtonsWidth > containerWidth * 0.8 || totalPages > 1);
    }
  }, [isValueData, itemsPerPage, currentPage, totalPages]);

  const handleNextPage = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleInvDeatils = (inverter,id,index) => {
    props.handleInvDeatil(inverter,
      id,
      index)
  }
  return (
          <Grid
          container
          className="inv_names_container"
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
          >
            <Grid
              container
              className="inv-names_grid"
              ref={containerRef}
            >
                {isValueData.length > 0 ? (
            <>
              {currentPage > 0  && (
                
                  <Grid item 
                  // xs={12} 
                  className="a-center_j-flex-start">
                    <div 
                    onClick={handlePrevPage}
                     className="arrow-btn"
                      style={{
                        backgroundColor: currentPage === 0 ? "#ebe7e7" : "#2F67F8",
                        border: "1px solid #D8D8D8", cursor: currentPage === 0 ? "not-allowed" : "pointer",
                        pointerEvents: currentPage === 0 ? "none" : "auto"
                      }}>
                      <ChevronLeftIcon sx={{ color: currentPage === 0 ? "#b5afaf" : "white" }} />
                    </div>
                  </Grid>
                  )}
                  {isValueData.slice(startIndex, startIndex + itemsPerPage).map((ele, index) => {
                    return(
                    <Grid
                      item
                      key={index}
                      // lg={2.2}
                      // xs={12}
                      // sm={5.8}
                      // md={2.9}
                      onClick={()=> handleInvDeatils(ele,
                        ele?.id || ele?.device_details?.id || ele?.NodeID,
                        index)}
                      sx={{ flex: '0 0 auto', width: 'auto' }}
                      className="flex-start_gap"
                      ref={(el) => (buttonsRef.current[index] = el)} 
                    >
                      <Paper
                        className="inv-names_paper"
                        sx={{ width:"fit-content",
                          backgroundColor: deviceId 
                          ? ((deviceId === (ele?.id || ele?.device_details?.id || ele?.NodeID)) ? "#2F67F8" : "#ffff")
                          : (index === 0 ? "#2F67F8" : "#ffff"),
                          border: '0.0625rem solid #D8D8D8 !important',
                        }}
                      >
                        <div
                          className="inv-names_div"
                          style={{
                            justifyContent: 'center',
                            backgroundColor: deviceId 
                            ? ((deviceId === (ele?.id || ele?.device_details?.id || ele?.NodeID)) ? "#2F67F8" : "#ffff")
                            : (index === 0 ? "#2F67F8" : "#ffff"),
                          }}
                        >
                          <p
                            className="Poppins_18px_rem"
                            style={{
                              color: deviceId 
                              ? ((deviceId === (ele?.id || ele?.device_details?.id || ele?.NodeID)) ? "#ffff" : "#2F67F8")
                              : (index === 0 ? "#ffff" : "#2F67F8"),
                            }}
                          >
                            {ele?.name ||  ele?.device_details?.name.charAt(0).toUpperCase() +
                            ele?.device_details?.name.slice(1).toLowerCase() || ele?.NodeID}
                          </p>
                        </div>
                      </Paper>
                    </Grid>)
})}

{currentPage < totalPages - 1 && (
                  <Grid item xs={12} className="a-center_j-flex-end">
                    <div 
                    onClick={handleNextPage}
                     className="arrow-btn"
                      style={{
                        backgroundColor: currentPage === totalPages - 1 ? "#ebe7e7" : "#2F67F8",
                        border: "1px solid #D8D8D8", cursor: currentPage === totalPages - 1 ? "not-allowed" : "pointer",
                        pointerEvents: currentPage === totalPages - 1 ? "none" : "auto"
                      }}>
                      <ChevronRightIcon sx={{ color: currentPage === totalPages - 1 ? "#b5afaf" : "white" }} />
                    </div>
                  </Grid>
                    )}
                    </>
                  ) : (
                    <div className="no-data">
                      <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                        No data is available!
                      </p>
                    </div>
                  )}
            </Grid>
          </Grid>

        </Grid>
  )
}

export default ButtonSlider