import React, { useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import "../MainLandingPage/LandingPage.scss";
import "./MainCaseStudy.scss";
import Footer from "../MainLandingPage/Footer";
function MainCaseStudy() {
  const caseStudies = [
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyOne.jpg",
      title:
        "Solar Power Plant - Predictive Energy Generation Using Deep Learning",
      role: "Solar Industry"
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyTwo.jpg",
      title:
        "Rapid Genomic Sequencing Model Training through GPU Infrastructure",
      role: "Life Science"
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyThree.jpg",
      title:
        "Predicting Heavy Equipment Failure Using Real-Time Data and Deep Learning",
      role: "Manufacturing"
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyFour.jpg",
      title:
        "Scalable Car License Plate Detection and Recognition Using Custom CNN and AWS EKS",
      role: "Automotive"
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyFive.jpg",
      title: "Actionable Insights from Robot Data Leveraging Deep Learning.",
      role: "Robotics"
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudySix.jpg",
      title:
        "Deploying Computer Vision Models on NVIDIA Jetson for Industrial Machine Vision Applications",
      role: "Process Industry"
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudies);
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filteredStudies = caseStudies.filter((study) =>
      study.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCaseStudies(filteredStudies);
  };
  const handleCaseStudy = (index) => {
    history.push(`./case-study/${index}`);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
 
  return (
    <div className="landing-container">
      <Navbar />
      <div className="case-study-heading" onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div>
          <div style={{ position: "relative" }}>
            <svg
              className="vectorAnime-one"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 98 363"
              fill="none"
            >
              <path
                className="vectorAnime"
                d="M94.3703 356.751C95.843 356.751 97.0369 357.945 97.0369 359.418C97.0369 360.891 95.843 362.084 94.3703 362.084C92.8975 362.084 91.7036 360.891 91.7036 359.418C91.7036 357.945 92.8975 356.751 94.3703 356.751ZM17.2015 76.6336L17.5643 76.2895L17.2015 76.6336ZM1.23438 0.0434635L1.23438 35.3433L0.234378 35.3433L0.234378 0.0434635L1.23438 0.0434635ZM17.5643 76.2895L78.266 140.288L77.5404 140.976L16.8387 76.9776L17.5643 76.2895ZM94.8703 181.922L94.8703 359.418L93.8703 359.418L93.8703 181.922L94.8703 181.922ZM78.266 140.288C88.9272 151.528 94.8703 166.43 94.8703 181.922L93.8703 181.922C93.8703 166.686 88.0255 152.031 77.5404 140.976L78.266 140.288ZM1.23438 35.3433C1.23438 50.5794 7.07921 65.2349 17.5643 76.2895L16.8387 76.9776C6.17744 65.7373 0.234378 50.8355 0.234378 35.3433L1.23438 35.3433Z"
                fill="transparent"
              />
            </svg>
          </div></div>

        <div className="case-study-head-two">
          <p className="bold-big-text">
            Case Studies
            <br />{" "}
            <p className="landing-heading">
              A Deep Dive into Smarttrak's Innovative Solutions
              <br /> and Operational Triumphs
            </p>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
          }}
        >
          <img
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyArcherry.png"
            className="archerry-img"
            alt="archerry"
          />
          <div className="case-study-stars"
            style={{
              position: "absolute",
              right: "3.5vw",
              top: "10vh",
              zIndex: "1",
            }}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
              alt="big-green-star"
              className={isHovered ? "blink-green-star" : "none"}
              style={{ width: "3vw" }}
            />
            <img
              style={{ position: "absolute", width: "1vw" }}
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
              alt="small-green-star"
              className={isHovered ? "blink-green-star" : "none"}
            />
          </div>
          <svg
            className="archerry-shadow"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 77 7"
            fill="none"
          >
            <ellipse
              cx="38.3887"
              cy="3.57556"
              rx="38.1543"
              ry="2.58972"
              fill="#E5EBEF"
            />
          </svg>

        </div>


      </div>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div className="search-box">
        <input className="semibold-smalltext" id="search-input"
          value={searchTerm}
          onChange={handleSearch}
          type="text"
          placeholder="Search Case Studies"
        />
        <svg xmlns="http://www.w3.org/2000/svg" className="search-icon" viewBox="0 0 55 55" fill="none">
              <rect width="55" height="55" rx="27.5" fill="#212121" />
              <path d="M38.2307 37.3048L32.9222 31.9987C34.3449 30.3628 35.2092 28.2264 35.2092 25.8908C35.2092 20.7515 31.0291 16.5784 25.8968 16.5784C20.7574 16.5784 16.582 20.7584 16.582 25.8908C16.582 31.0232 20.7621 35.2032 25.8944 35.2032C28.2278 35.2032 30.3641 34.3412 32.0023 32.9162L37.3108 38.227C37.4359 38.3521 37.6051 38.4217 37.7719 38.4217C37.9364 38.4217 38.1056 38.3591 38.233 38.227C38.4809 37.9745 38.4809 37.5574 38.2307 37.3048ZM25.8898 33.901C21.4781 33.901 17.8842 30.3072 17.8842 25.8908C17.8842 21.4744 21.4781 17.8852 25.8898 17.8852C30.3062 17.8852 33.8954 21.479 33.8954 25.8908C33.8954 30.3025 30.3062 33.901 25.8898 33.901Z" fill="white" />
            </svg>
      </div>
      </div>

        <div style={{ position: "relative" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="svg-arrow-left"
            viewBox="0 0 679 97"
            fill="none"
          >
            <path
              className="vectorAnime"
              d="M672.682 94.2613C672.682 95.734 673.876 96.9279 675.349 96.9279C676.822 96.9279 678.016 95.734 678.016 94.2613C678.016 92.7885 676.822 91.5946 675.349 91.5946C673.876 91.5946 672.682 92.7885 672.682 94.2613ZM248.942 10.2076L248.671 10.6278L248.942 10.2076ZM0 1.1254H216.415V0.125397H0V1.1254ZM248.671 10.6278L364.102 85.0992L364.644 84.2589L249.213 9.78747L248.671 10.6278ZM396.901 94.7613H675.349V93.7613H396.901V94.7613ZM364.102 85.0992C373.878 91.4066 385.266 94.7613 396.901 94.7613V93.7613C385.458 93.7613 374.259 90.462 364.644 84.2589L364.102 85.0992ZM216.415 1.1254C227.857 1.1254 239.056 4.42463 248.671 10.6278L249.213 9.78747C239.437 3.48008 228.049 0.125397 216.415 0.125397V1.1254Z"
              fill="#E6E6E6"
            />
          </svg>
        </div>

      <div className="casestudy-grid">
        <Grid container sx={{ flexGrow: 1, gap: "1vw", pl: 0 }}>
          {filteredCaseStudies.map((item, index) => (
            <Grid
              item
              key={index}
              lg={3.9}
              xs={11.9}
              sm={5.9}
              md={5.9}
              sx={{
                marginTop: "7vh",
              }}
            >
              <Paper
                className="case-study-cards"
                sx={{
                  borderRadius:
                    index % 3 === 0
                      ? "2.5vh 2.5vh 20vh"
                      : index % 3 === 1
                        ? "2.5vh 2.5vh 27vh 27vh"
                        : "2.5vh 2.5vh 2.5vh 20vh",

                  backgroundColor: "#212121 !important",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4.5vh 1.5vw",
                  ":hover": {
                    backgroundColor: "#2F67F8 !important",
                  },
                }}
              >
                <div>
                  <p className="regular-common-text">{item.role}</p>
                  <p
                    style={{ height: "15vh", color: "white" }}
                    className=" semibold-commontext"
                  >
                    {item.title}
                  </p>

                  <img className="case_study_imgs"
                    style={{
                      width: "100%",
                      borderRadius:
                        index % 3 === 0
                          ? "2.5vh 2.5vh 20vh"
                          : index % 3 === 1
                            ? "2.5vh 2.5vh 24vh 24vh"
                            : "2.5vh 2.5vh 2.5vh 20vh",

                      marginTop: "4vh",
                    }}
                    src={item.image}
                    alt="expertise"
                  />
                  <div
                    onClick={() => handleCaseStudy(index)}
                    className="circle-arrow-casestudy"
                    style={{
                      marginTop: "-14vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "translate(0%, 70%)",
                    }}
                  >
                    <div
                      style={{
                        width: "6vw",
                        height: "6vw",
                        borderRadius: "50%",
                        background: "#90E900",
                        zIndex: "1",
                        border: "1px solid #111",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="rotate-on-scroll"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28"
                        fill="none"
                        className="hover-svg"
                      >
                        <path
                          d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default MainCaseStudy;
