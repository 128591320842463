import React from 'react'
import Navbar from '../MainLandingPage/Navbar';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import Footer from '../MainLandingPage/Footer';
import "./service.scss";


function MainServices() {
 
    const history = useHistory();
   
      const ourServices = [
        {
          title: "Digital Twin",
          description: "Immersive technology enhances user experience and training with improved visualization for quick issue identification.",
          image: "https://s3.amazonaws.com/smarttrak.co/landing-images/service-3.jpg",
          bgColor: "#FBAF3F",
        },
        {
          title: "Generative AI",
          description: "Pioneering business innovation with Generative AI, crafting bespoke solutions for creativity and efficiency.",
          image: "https://s3.amazonaws.com/smarttrak.co/landing-images/service-1.jpg",
          bgColor: "#37A6A6",
        },
        {
          title: "Custom AI Models",
          description: "Tailoring AI for business automation, informed decision-making, and predictive analytics, ensuring you stay ahead.",
          image: "https://s3.amazonaws.com/smarttrak.co/landing-images/service-2.jpg",
          bgColor: "#8C6FF7",
        }
      ]
      const handleServices = (index) => {
        history.push(`/our-services/${index}`);
      };
      
  return (
    <div className='landing-container'>
        <Navbar />
        <div style={{ position: "relative" }}>
                <svg style={{ position: "absolute" }}
                    className="service-draw-line-one" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 679 97" fill="none">
                    <path className="vectorAnime" d="M672.682 2.82749C672.682 1.35473 673.876 0.160828 675.349 0.160828C676.822 0.160828 678.016 1.35473 678.016 2.82749C678.016 4.30025 676.822 5.49416 675.349 5.49416C673.876 5.49416 672.682 4.30025 672.682 2.82749ZM248.942 86.8812L248.671 86.461L248.942 86.8812ZM0 95.9634H216.415V96.9634H0V95.9634ZM248.671 86.461L364.102 11.9896L364.644 12.8299L249.213 87.3013L248.671 86.461ZM396.901 2.32749H675.349V3.32749H396.901V2.32749ZM364.102 11.9896C373.878 5.68218 385.266 2.32749 396.901 2.32749V3.32749C385.458 3.32749 374.259 6.62673 364.644 12.8299L364.102 11.9896ZM216.415 95.9634C227.857 95.9634 239.056 92.6641 248.671 86.461L249.213 87.3013C239.437 93.6087 228.049 96.9634 216.415 96.9634V95.9634Z" fill="#E6E6E6" />
                </svg></div>
            <div className='service-box' style={{position:"relative", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "22vh", marginBottom: "18vh" }}>
                <div className='about-main'>
                    <div className='about-submain'>
                        <p className='bold-big-common-text'>Our Solutions</p>
                        <p id='auto-width' className="semibold-smalltext" style={{width:"52vw", fontWeight: "700", textAlign: "center" }}>
                        At Smarttrak, we lead with AI, unlocking creativity and innovation across sectors. Empower your business with us, where AI meets creativity.
            </p> </div>
                    <div style={{position:"absolute"}}><img className="our-service" src='https://s3.amazonaws.com/smarttrak.co/landing-images/our-services.jpg' alt='about' style={{ position:"absolute",width: "14vw", left:"53vw", top:"-16vh" }} /></div>
                </div>
                <div>
                <svg style={{ position: "absolute" }}
                    className="services-draw-line-two" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 299" fill="none">
                    <path className="vectorAnime" d="M83.7598 293.59C85.2325 293.59 86.4264 294.784 86.4264 296.257C86.4264 297.729 85.2325 298.923 83.7598 298.923C82.287 298.923 81.0931 297.729 81.0931 296.257C81.0931 294.784 82.287 293.59 83.7598 293.59ZM1.23438 0.0434623L1.23438 23.6552L0.234377 23.6552L0.234377 0.0434623L1.23438 0.0434623ZM19.087 66.1493L66.1071 112.232L65.4071 112.947L18.3871 66.8635L19.087 66.1493ZM84.2598 155.441L84.2598 296.257L83.2598 296.257L83.2598 155.441L84.2598 155.441ZM66.1071 112.232C77.7174 123.611 84.2598 139.184 84.2598 155.441L83.2598 155.441C83.2598 139.453 76.8255 124.137 65.4071 112.947L66.1071 112.232ZM1.23438 23.6552C1.23438 39.6432 7.66864 54.9584 19.087 66.1493L18.3871 66.8635C6.77678 55.4845 0.234377 39.9119 0.234377 23.6552L1.23438 23.6552Z" fill="#E6E6E6" />
                </svg>
            </div>
            </div>
            

            <div className="casestudy-grid">
        <Grid container sx={{ flexGrow: 1, gap: "1vw", pl: 0 }}>
          {ourServices.map((item, index) => (
            <Grid
              item
              key={index}
              lg={3.9}
              xs={11.9}
              sm={5.9}
              md={3.9}
              sx={{
                marginTop: "3vh",
              }}
            >
              <Paper
                className="services-cards"
                sx={{
                  borderRadius: "2vw 2vw 27vw 27vw",
                  background: "#212121 ! important",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4.5vh 1.5vw",
                  ":hover": {
                    backgroundColor: "#2F67F8 ! important",
                  },
                }}
              >
                <div>
                 
                  <p
                    style={{ height: "8vh", color: "white" }}
                    className=" semibold-commontext"
                  >
                    {item.title}
                  </p>
                  <p
                    style={{ height: "12vh", color: "white" }}
                    className="regular-common-text"
                  >
                    {item.description}
                  </p>

                  <img className='service_imgs'
                    style={{
                      width: "100%",
                      borderRadius:"2.5vh 2.5vh 27vh 27vh",
                         marginTop: "4vh",
                    }}
                    src={item.image}
                    alt="expertise"
                  />

                  {/* <div onClick={()=>handleServices(index)}
                    className="circle-arrow-casestudy"
                    style={{
                      marginTop: "-14vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "translate(0%, 70%)",
                    }}
                  >
                    <div
                      style={{
                        width: "6vw",
                        height: "6vw",
                        borderRadius: "50%",
                        background: "#90E900",
                        zIndex: "1",
                        border: "1px solid #111",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="rotate-on-scroll"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28"
                        fill="none"
                        className="hover-svg"
                      >
                        <path
                          d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div> */}
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <Footer />
    </div>
  )
}

export default MainServices