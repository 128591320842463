import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.scss";
import "react-slideshow-image/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Navbar from "./Navbar";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/joy/LinearProgress";
import Stack from "@mui/joy/Stack";
import { CssVarsProvider } from "@mui/joy/styles";
import 'vidstack/styles/defaults.css'
import 'vidstack/styles/community-skin/video.css'

import { defineCustomElements } from 'vidstack/elements';

defineCustomElements();


function Home() {
  const ourExpertise = [
    {
      title: "Digital Twin",
      description:
        "Immersive technology enhances user experience and training with improved visualization for quick issue identification.",
      image:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/expertise1.jpg",
    },
    {
      title: "Generative AI",
      description:
        "Pioneering business innovation with Generative AI, crafting bespoke solutions for creativity and efficiency.",
      image:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/expertise2.jpg",
    },
    {
      title: "Custom AI Models",
      description:
        "Tailoring AI for business automation, informed decision-making, and predictive analytics, ensuring you stay ahead.",
      image:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/expertise3.jpg",
    },
  ];

  const firstFolders = [
    {
      title: "Advanced Physics-Based",
      extraTitle: "AI Models",
      description:
        "Utilize cutting-edge, physics-informed AI models that deeply understand and accurately predict the behavior of solar power plant equipment, ensuring early detection of faults.",
      bgColor: "#BBD6FF",
    },
    {
      title: "Real-Time Data",
      extraTitle: "Integration and Analysis",
      description:
        "Seamlessly integrated with IoT sensors for continuous real-time data monitoring, enabling the system to quickly identify deviations and anomalies in equipment performance.",
      bgColor: "#CDBCFF",
    },
    {
      title: "Proactive Fault Prediction",
      extraTitle: "and Automated Response",
      description:
        "Beyond immediate fault detection, our system anticipates potential issues before they occur and automatically initiates maintenance protocols, minimizing downtime and enhancing operational efficiency.",
      bgColor: "#FFA288",
    },
  ];
  const secondFolders = [
    {
      title: "High-Accuracy Forecasting with",
      extraTitle: "Advanced Deep Learning",
      description:
        "Leverage state-of-the-art deep learning models to deliver energy generation forecasts with over 98% accuracy, ensuring highly reliable and precise predictions for solar power plant output.",
      bgColor: "#BBD6FF",
    },
    {
      title: "Customized Solar ",
      extraTitle: "Power Analytics",
      description:
        "Tailored specifically for solar energy, our system integrates unique environmental variables and historical data, providing forecasts that are finely attuned to the specific dynamics of solar power plants.",
      bgColor: "#CDBCFF",
    },
    {
      title: "Data-Driven Operational",
      extraTitle: "Optimization",
      description:
        "Empower solar power plant operators with actionable insights, enabling optimized energy distribution, enhanced maintenance scheduling, and strategic operational decision-making based on comprehensive and accurate energy forecasts.",
      bgColor: "#FFA288",
    },
  ];
  const thirdFolders = [
    {
      title: "Bespoke Model",
      extraTitle: "Fine-Tuning",
      description:
        "Our Large Language Models are meticulously fine-tuned to align with your specific industry and organizational requirements, ensuring highly relevant and targeted AI-driven insights and solutions.",
      bgColor: "#BBD6FF",
    },
    {
      title: "Seamless Integration with",
      extraTitle: "Existing Systems",
      description:
        "Designed for easy integration, our LLMs can be seamlessly incorporated into your existing workflows and systems, enhancing data processing and decision-making without disrupting current operations.",
      bgColor: "#CDBCFF",
    },
    {
      title: "Adaptive Learning for",
      extraTitle: "Continuous Improvement",
      description:
        "Our models continuously learn and adapt from new data, ensuring they remain up-to-date and increasingly effective in delivering accurate and actionable insights, tailored to the evolving dynamics of your business.",
      bgColor: "#FFA288",
    },
  ];
  const ourProducts = [
    {
      title: "POWEROPTIX.AI",
      laptop:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/laptop_dashboard.svg",
      description:
        "Unlock unparalleled efficiency with our AI-driven solution, empowering data-driven decisions.",
      img_one:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/dashboard.svg",
      img_two:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/barChart.svg",
      img_three:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/bottom.svg",
    },
    {
      title: "SmartIQ Edge",
      laptop:
        "https://s3.amazonaws.com/smarttrak.co/landing-images/smart_iq_edge.svg",
      description:
        "Elevate Your Solar Efficiency with SmartIQ Edge: The Future of Solar Energy Management",
    },
  ];
  const slides = [
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/new_landing_images/slide1.svg",
      title: "Enhance Productivity with Digital Twin",

      description:
        "Our digital twin platform creates a virtual replica of your plant, enabling real-time monitoring and performance analysis. Quickly identify and resolve inefficiencies to maximize production and achieve optimal energy output.",
      progressText: "Digital Twin",
    },
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/new_landing_images/slide2.svg",
      title: "Unlock the Power of AI-Enhanced Energy Forecasting",

      description:
        "Smarttrak's AI leverages historical data, weather patterns, and current conditions to deliver precise energy production predictions. Boost revenue and optimize operations with our cutting-edge forecasting technology.",

      progressText: "AI-Enhanced Energy Forecasting",
    },
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/new_landing_images/slide3.jpg",
      title: "Prevent Downtime with Proactive Fault Prediction",

      description:
        "Smarttrak's AI predicts potential equipment faults before they occur, allowing you to manage maintenance proactively. Minimize downtime and ensure uninterrupted energy production with our advanced fault prediction technology.",

      progressText: "Proactive Fault Prediction",
    },
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/new_landing_images/slide4.svg",
      title: "Optimize Asset Management with Remaining Useful Life Predictions",

      description:
        "Our AI algorithms predict the remaining life of your critical assets, empowering you to make informed maintenance decisions. Plan replacements strategically, avoid unexpected disruptions, and extend asset lifecycles.",

      progressText: "Asset Lifecycle Predictions",
    },
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/new_landing_images/slide5.svg",
      title:
        "Explore Data in Depth with Interactive 3D Digital Twin Visualization",

      description:
        "Experience your entire plant’s operations in a state-of-the-art 3D environment. Smarttrak’s digital twin enhances monitoring capabilities, offers interactive control, and provides real-time data visualization for better decision-making.",

      progressText: "3D Digital Twin Visualization",
    },
  ];

  const [partners, setPartners] = useState([
    "https://s3.amazonaws.com/smarttrak.co/landing-images/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/Nvidia_logo.png",
  ]);
  const marqueeRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const marqueeContainer = marqueeRef.current;

    const handleAnimationEnd = () => {
      // Move all images to the last positions in the array
      setPartners((prevPartners) => {
        const updatedPartners = [...prevPartners];
        for (let i = 0; i < updatedPartners.length; i++) {
          const partner = updatedPartners.shift();
          updatedPartners.push(partner);
        }
        return updatedPartners;
      });
    };
    marqueeContainer.addEventListener("animationiteration", handleAnimationEnd);

    return () => {
      marqueeContainer.removeEventListener(
        "animationiteration",
        handleAnimationEnd
      );
    };
  }, []);
  const [isInViewport, setIsInViewport] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndexone, setHoveredIndexone] = useState(-1);
  const [hoveredIndextwo, setHoveredIndextwo] = useState(-1);
  const [hoveredIndexthree, setHoveredIndexthree] = useState(-1);
  const [email, setEmail] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [progress, setProgress] = useState(Array(slides.length).fill(0));
  const sliderRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        if (newProgress[currentSlide] >= 100) {
          newProgress[currentSlide] = 0;
          const nextSlide = (currentSlide + 1) % slides.length;
          setCurrentSlide(nextSlide);
          sliderRef.current.slickGoTo(nextSlide); // Change slide programmatically
        } else {
          newProgress[currentSlide] += 0.5;
        }
        return newProgress;
      });
    }, 50);

    return () => clearInterval(timer);
  }, [currentSlide]);

  const handleLeftArrowClick = () => {
    const previousSlide = (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(previousSlide);
    sliderRef.current.slickGoTo(previousSlide); // Navigate to the previous slide
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[previousSlide] = 0; // Reset the progress for the previous slide
      newProgress[currentSlide] = 0;
      return newProgress;
    });
  };

  const handleRightArrowClick = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
    sliderRef.current.slickGoTo(nextSlide); // Navigate to the next slide
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[nextSlide] = 0; // Reset the progress for the next slide
      newProgress[currentSlide] = 0;
      return newProgress;
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleHoverFolderone = (index) => {
    setHoveredIndexone(index);
  };
  const handleHoverFoldertwo = (index) => {
    setHoveredIndextwo(index);
  };
  const handleHoverFolderthree = (index) => {
    setHoveredIndexthree(index);
  };
  const handleHover = (index) => {
    setIsInViewport(index);
  };
  const handleHoverLeave = () => {
    setIsInViewport(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleProductSoln = (index) => {
    if (index === 0) {
      history.push({
        pathname: `/products/poweroptix`,
        state: { index: index },
      });
    } else if (index === 1) {
      history.push({
        pathname: `/products/smartiq_edge`,
        state: { index: index },
      });
    }
  };
  const handleCaseStudy = (index) => {
    history.push(`/case-study/${index}`);
  };
  const handleViewAll = () => {
    history.push("/case-studies");
  };

  // const handleFeature = (index) => {
  //   history.push({
  //     pathname: `/poweroptix`,
  //     state: { index: index },
  //   });
  // };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    if (!email) {
      enqueueSnackbar("Please enter your email address", {
        variant: "warning",
      });
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Email submitted successfully", {
        variant: "success",
      });
    }
    setEmail("");
  };

  const handleContact = () => {
    history.push("/about-us#contact-us");
  };

  return (
    <div className="landing-container">
      <Navbar />

      <div className="slider-container">
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              <img src={slide.imgSrc} alt={`Slide ${index + 1}`} />
              <div className="overlay">
                <p className="Poppins_30px_rem">{slide.title}</p>
                <p
                  style={{ padding: "1rem 0rem", width: "96%", color: "#ffff", fontWeight: "400" }}
                  className="Poppins_16px_rem"
                >
                  {slide.description}
                </p>
                <div className="contact-btn">
                  <p
                    className="Poppins_18px_rem"
                    onClick={handleContact}
                    style={{ color: "#FDA737" }}
                  >
                    Let get in touch
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <CssVarsProvider>
          <div className="left-arrow">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/left_arrow.svg"
              style={{ width: "5vw" }}
              alt="left"
              onClick={handleLeftArrowClick}
            />
          </div>
          <div className="right-arrow">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/right_arrow.svg"
              style={{ width: "5vw" }}
              alt="right"
              onClick={handleRightArrowClick}
            />
          </div>
          <Stack
            spacing={3}
            direction="row"
            className="stack_progess"
            sx={{
              flex: 1,
              width: "55%",
              marginTop: "-7%",
              marginLeft: "10%",
              position: "absolute",
            }}
          >
            {progress.map((value, index) => (
              <Stack key={index} spacing={1} alignItems="center">
                <div style={{ width: "100%" }}>
                  {" "}
                  <LinearProgress
                    sx={{
                      width: "100%",
                      color: "#3475AE",
                      backgroundColor: "#C7C7C7",
                    }}
                    determinate
                    value={value}
                  />
                </div>
                <div className="progress-linewidth">
                  <p className="Poppins_14px_rem" style={{ color: "#ffff" }}>
                    {slides[index].progressText}
                  </p>
                </div>
              </Stack>
            ))}
          </Stack>
        </CssVarsProvider>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="Poppins_40px_rem" style={{ color: "black", fontWeight: "500", margin: "1rem" }}>Product Demo</p>
        <div className="video-container">

          <media-player
            title="Smarttrak AI"
            src="https://s3.amazonaws.com/smarttrak.co/videos/smarttrak_ai.mp4"
            poster="https://s3.amazonaws.com/smarttrak.co/landing-images/Thumbnail.jpg"
            thumbnails="https://s3.amazonaws.com/smarttrak.co/landing-images/Thumbnail.jpg"
            aspect-ratio="16/9"
            crossorigin
          >
            <media-outlet>
              <media-poster
                alt="video not found"
              ></media-poster>

            </media-outlet>
            <media-community-skin></media-community-skin>
          </media-player>

        </div>
      </div>
      <div
        className="expertise-box"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4rem",
        }}
      >
        <div className="our-expertise-container">
          <div className="expertise-heading">
            <p className="bold-common-text">Our AI Expertise</p>
            <p
              id="expertise-description"
              className="regular-common-text"
              style={{ width: "41%" }}
            >
              At Smarttrak, we lead with AI, unlocking creativity and innovation
              across sectors. Empower your business with us, where AI meets
              creativity.
            </p>
          </div>
          <Divider
            style={{ border: "0.5px solid #656565", margin: "5vh 0vh" }}
          />
          <Grid
            container
            className="expertise-grid"
            sx={{ flexGrow: 1, gap: "1vw", marginTop: "2vh" }}
          >
            {ourExpertise.map((item, index) => (
              <Grid
                item
                key={index}
                lg={3.9}
                xs={11.9}
                sm={5.8}
                md={5.8}
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverLeave}
              >
                <Paper
                  className="expertise-cards"
                >
                  <div className="expertise-cards-container">
                    <p
                      className="bold-common-text "
                      style={{
                        color: isInViewport === index ? "#fff" : "#90E900",
                      }}
                    >
                      {item.title}
                    </p>

                    <Divider
                      style={{
                        border: "0.5px solid #656565",
                        margin: "3vh 0px",
                      }}
                    />
                    <p
                      style={{
                        color: "#fff",
                        margin: "1rem 0rem",
                        height: "10vh",
                      }}
                      className="regular-common-text"
                    >
                      {item.description}
                    </p>
                    <img
                      className="expertise_image"
                      src={item.image}
                      alt="expertise"
                    />

                    {/* <div onClick={() => handleServices(index)} className="circle-arrow" id="home-circle"
                      style={{
                        position: "absolute",
                        bottom: "4vh",
                        right: "1.5vw",
                        transform: "translate(50%, 50%)",
                      }}
                    >
                      <div
                        style={{
                          width: "6vw",
                          height: "6vw",
                          borderRadius: "50%",
                          background: isInViewport === index  ? "#90E900" : "#454545",
                          zIndex: "1",
                          border: "1px solid #111",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                        }}
                        className={`rotate-on-scroll ${isInViewport === index  ? "double-rotation" : ""}`}

                      >

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none" className="hover-svg">
                          <path d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z" fill={isInViewport === index  ? "black" : "white"} />
                        </svg>
                      </div>
                    </div> */}
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div style={{ marginTop: "8vh" }}>
        <div className="service-heading">
          <p className="bold-common-text" style={{ color: "#111" }}>
            Our Solutions
          </p>
          <p
            id="expertise-description"
            className="regular-common-text"
            style={{ width: "21%", color: "#111" }}
          >
            Discover the range of solutions we offer to meet your needs and
            exceed your expectations.
          </p>
        </div>
        <Divider
          style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }}
        />
      </div>
      <div className="gen-ai-conatiner">
        <p className="semibold-commontext">
          Generative AI for Solar Power Plants
        </p>
        <div
          className="fault-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className="ourservice-container"
            style={{
              position: "relative",
              backgroundColor: "#B5ADC9",
              borderRadius: "1.5vw 1.5vw 11vw 1.5vw",
            }}
          >
            <img
              className="service-img"
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/gen-ai1.jpg"
              alt="our-service"
              style={{
                borderRadius: "1.5vw 1.5vw 11vw 1.5vw",
                width: "29vw",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "-3vh",
                right: "0vw",
              }}
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                alt="big-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                style={{ width: "3vw" }}
                id="big-star"
              />
              <img
                style={{ position: "absolute", width: "1vw" }}
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                alt="small-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                id="small-star"
              />
            </div>
          </div>
          <div>
            <p
              // onClick={() => handleFeature(0)}
              className="semibold-commontext"
              style={{ fontWeight: "700", color: "#2F67F8" }}
            >
              Fault Detection and Preventive Maintenance
            </p>
            <p className="semibold-smalltext">
              Solar power plants play a crucial role in building a sustainable
              future. Ensuring their optimal performance is paramount. Our
              sophisticated time series AI solutions are tailored to detect and
              predict faults, ensuring your solar installations function
              efficiently and sustainably.
            </p>
            <p
              className="semibold-commontext"
              style={{ fontWeight: "700", padding: "20px 0px" }}
            >
              Key Features:
            </p>

            <div
              className="folder-container"
              style={{ display: "flex", gap: "1vw" }}
            >
              {firstFolders.map((item, index) => (
                <div
                  onMouseEnter={() => handleHoverFolderone(index)}
                  onMouseLeave={() => handleHoverFolderone(-1)}
                  style={{
                    position: "relative",
                    display: "flex",
                    width: "max-content",
                    alignItems:
                      hoveredIndexone === index ? "center" : "flex-end",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="folder"
                    style={{ width: "18.6vw" }}
                    viewBox="0 0 286 218"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M285.711 8.85777C285.711 4.29543 282.013 0.596924 277.45 0.596924H219.656C214.424 0.596924 209.769 3.91368 208.06 8.85777C206.351 13.8019 201.695 17.1186 196.464 17.1186H15.0869C6.80264 17.1186 0.0869141 23.8343 0.0869141 32.1186V202.119C0.0869141 210.403 6.80264 217.119 15.0869 217.119H270.713C278.997 217.119 285.713 210.403 285.713 202.119V17.1194C285.713 17.119 285.713 17.1186 285.712 17.1186C285.712 17.1186 285.711 17.1183 285.711 17.1179V8.85777Z"
                      fill={
                        hoveredIndexone === index ? "#001D49" : item.bgColor
                      }
                    />
                  </svg>
                  <p
                    style={{
                      position: "absolute",
                      zIndex: "2",
                    }}
                    className={
                      hoveredIndexone === index
                        ? "folder-text"
                        : "semibold-folder-text"
                    }
                  >
                    {hoveredIndexone === index ? (
                      item.description
                    ) : (
                      <p>
                        {item.title}
                        <br />
                        {item.extraTitle}
                      </p>
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="fault-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>
            <p
              // onClick={() => handleFeature(1)}
              className="semibold-commontext"
              style={{ fontWeight: "700", color: "#2F67F8" }}
            >
              Energy Generation Forecasting
            </p>
            <p className="semibold-smalltext">
              Harness the sun's potential to its fullest. Our forecasting models
              provide accurate predictions on energy generation, helping you
              plan and optimize your power output.
            </p>
            <p
              className="semibold-commontext"
              style={{ fontWeight: "700", padding: "20px 0px" }}
            >
              Key Features:
            </p>
            <div
              className="folder-container"
              style={{ display: "flex", gap: "1vw" }}
            >
              {secondFolders.map((item, index) => (
                <div
                  onMouseEnter={() => handleHoverFoldertwo(index)}
                  onMouseLeave={() => handleHoverFoldertwo(-1)}
                  style={{
                    position: "relative",
                    display: "flex",
                    width: "max-content",
                    alignItems:
                      hoveredIndextwo === index ? "center" : "flex-end",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="folder"
                    style={{ width: "18.6vw" }}
                    viewBox="0 0 286 218"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M285.711 8.85777C285.711 4.29543 282.013 0.596924 277.45 0.596924H219.656C214.424 0.596924 209.769 3.91368 208.06 8.85777C206.351 13.8019 201.695 17.1186 196.464 17.1186H15.0869C6.80264 17.1186 0.0869141 23.8343 0.0869141 32.1186V202.119C0.0869141 210.403 6.80264 217.119 15.0869 217.119H270.713C278.997 217.119 285.713 210.403 285.713 202.119V17.1194C285.713 17.119 285.713 17.1186 285.712 17.1186C285.712 17.1186 285.711 17.1183 285.711 17.1179V8.85777Z"
                      fill={
                        hoveredIndextwo === index ? "#001D49" : item.bgColor
                      }
                    />
                  </svg>
                  <p
                    style={{
                      position: "absolute",
                      zIndex: "2",
                    }}
                    className={
                      hoveredIndextwo === index
                        ? "folder-text"
                        : "semibold-folder-text"
                    }
                  >
                    {hoveredIndextwo === index ? (
                      item.description
                    ) : (
                      <p>
                        {item.title}
                        <br />
                        {item.extraTitle}
                      </p>
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="ourservice-container"
            style={{
              position: "relative",
              backgroundColor: "#F1C0B2",
              borderRadius: "1.5vw 1.5vw 11vw 1.5vw",
            }}
          >
            <img
              className="service-img"
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/gen-forcast.jpg"
              alt="our-service"
              style={{ borderRadius: "1.5vw 1.5vw 11vw 1.5vw", width: "29vw" }}
            />
            <div
              style={{
                position: "absolute",
                top: "-3vh",
                right: "0vw",
              }}
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                alt="big-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                style={{ width: "3vw" }}
                id="big-star"
              />
              <img
                style={{ position: "absolute", width: "1vw" }}
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                alt="small-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                id="small-star"
              />
            </div>
          </div>
        </div>
        <Divider
          style={{ border: "0.5px solid #E6E6E6", margin: "40px 0px" }}
        />
        <p style={{ marginBottom: "40px" }} className="semibold-commontext">
          Generative AI for Corporations & Businesses
        </p>
        <div
          className="fault-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className="ourservice-container"
            style={{
              position: "relative",
              backgroundColor: "#B5ADC9",
              borderRadius: "1.5vw 1.5vw 11vw 1.5vw",
            }}
          >
            <img
              className="service-img"
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/gen-ai2.jpg"
              alt="our-service"
              style={{ borderRadius: "1.5vw 1.5vw 11vw 1.5vw", width: "29vw" }}
            />
            <div
              style={{
                position: "absolute",
                top: "-3vh",
                right: "0vw",
              }}
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                alt="big-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                style={{ width: "3vw" }}
                id="big-star"
              />
              <img
                style={{ position: "absolute", width: "1vw" }}
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                alt="small-green-star"
                className={isHovered ? "blink-green-star" : "none"}
                id="small-star"
              />
            </div>
          </div>
          <div>
            <p
              className="semibold-commontext"
              style={{ fontWeight: "700", color: "#2F67F8" }}
            >
              Tailored Large Language Models (LLMs) for specific needs
            </p>
            <p className="semibold-smalltext">
              By customizing these advanced models, we provide precise, context-aware insights, streamline processes, and enable intelligent automation. Whether it's improving operational efficiency, enhancing decision-making, or delivering industry-specific knowledge, our solution adapts to your unique requirements, driving innovation and delivering real-world impact.
            </p>
            <p
              className="semibold-commontext"
              style={{ fontWeight: "700", padding: "20px 0px" }}
            >
              Key Features:
            </p>
            <div
              className="folder-container"
              style={{ display: "flex", gap: "1vw" }}
            >
              {thirdFolders.map((item, index) => (
                <div
                  onMouseEnter={() => handleHoverFolderthree(index)}
                  onMouseLeave={() => handleHoverFolderthree(-1)}
                  style={{
                    position: "relative",
                    display: "flex",
                    width: "max-content",
                    alignItems:
                      hoveredIndexthree === index ? "center" : "flex-end",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="folder"
                    style={{ width: "18.6vw" }}
                    viewBox="0 0 286 218"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M285.711 8.85777C285.711 4.29543 282.013 0.596924 277.45 0.596924H219.656C214.424 0.596924 209.769 3.91368 208.06 8.85777C206.351 13.8019 201.695 17.1186 196.464 17.1186H15.0869C6.80264 17.1186 0.0869141 23.8343 0.0869141 32.1186V202.119C0.0869141 210.403 6.80264 217.119 15.0869 217.119H270.713C278.997 217.119 285.713 210.403 285.713 202.119V17.1194C285.713 17.119 285.713 17.1186 285.712 17.1186C285.712 17.1186 285.711 17.1183 285.711 17.1179V8.85777Z"
                      fill={
                        hoveredIndexthree === index ? "#001D49" : item.bgColor
                      }
                    />
                  </svg>
                  <p
                    style={{
                      position: "absolute",
                      zIndex: "2",
                    }}
                    className={
                      hoveredIndexthree === index
                        ? "folder-text"
                        : "semibold-folder-text"
                    }
                  >
                    {hoveredIndexthree === index ? (
                      item.description
                    ) : (
                      <p>
                        {item.title}
                        <br />
                        {item.extraTitle}
                      </p>
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="service-heading" style={{ marginTop: "1rem" }}>
        <p className="bold-common-text" style={{ color: "#111" }}>
          Our Products & Solutions
        </p>
        <p
          id="expertise-description"
          className="regular-common-text"
          style={{ width: "25%", color: "#111" }}
        >
          Explore our comprehensive range of products and solutions designed to
          address your specific needs and challenges.
        </p>
      </div>
      <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} />
      <div style={{ padding: "20px 0px" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1vw",
          }}
        >
          {ourProducts.map((item, index) => (
            <Grid
              className="product-container"
              item
              lg={5.6}
              xs={12}
              sm={12}
              md={5.6}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                className="pro-subcontainer"
                sx={{
                  width: "100% !important",
                  borderRadius: "2vw ! important",
                  backgroundColor: "#111 ! important",
                  padding: "3vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="vectorAnime product-linedraw-2"
                  viewBox="0 0 299 98"
                  fill="none"
                >
                  <path
                    d="M5.65737 3.57838C5.65737 2.10561 4.46346 0.911713 2.99071 0.911714C1.51794 0.911714 0.324027 2.10561 0.324027 3.57838C0.324028 5.05114 1.51794 6.24504 2.99071 6.24504C4.46346 6.24504 5.65737 5.05114 5.65737 3.57838ZM178.896 25.3531L179.282 25.0345L178.896 25.3531ZM363.121 96.7142L266.542 96.7142L266.542 97.7142L363.121 97.7142L363.121 96.7142ZM220.68 75.121L179.282 25.0345L178.511 25.6716L219.909 75.7581L220.68 75.121ZM132.649 3.07837L2.99071 3.07838L2.99071 4.07838L132.649 4.07837L132.649 3.07837ZM179.282 25.0345C167.789 11.1292 150.689 3.07836 132.649 3.07837L132.649 4.07837C150.391 4.07836 167.208 11.9961 178.511 25.6716L179.282 25.0345ZM266.542 96.7142C248.8 96.7142 231.984 88.7965 220.68 75.121L219.909 75.7581C231.403 89.6634 248.502 97.7142 266.542 97.7142L266.542 96.7142Z"
                    fill="#353535"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="product-linedraw vectorAnime"
                  viewBox="0 0 98 364"
                  fill="none"
                >
                  <path
                    d="M3.18832 358.184C1.71555 358.184 0.521652 359.378 0.521652 360.851C0.521652 362.323 1.71555 363.517 3.18832 363.517C4.66108 363.517 5.85498 362.323 5.85498 360.851C5.85498 359.378 4.66108 358.184 3.18832 358.184ZM24.963 184.945L24.6445 184.559L24.963 184.945ZM96.3242 0.720215L96.3242 97.299L97.3242 97.299L97.3242 0.720215L96.3242 0.720215ZM74.7309 143.161L24.6445 184.559L25.2816 185.33L75.368 143.932L74.7309 143.161ZM2.68832 231.192L2.68832 360.851L3.68832 360.851L3.68832 231.192L2.68832 231.192ZM24.6445 184.559C10.7392 196.053 2.68832 213.152 2.68832 231.192L3.68832 231.192C3.68832 213.45 11.6061 196.634 25.2816 185.33L24.6445 184.559ZM96.3242 97.299C96.3242 115.041 88.4065 131.858 74.7309 143.161L75.368 143.932C89.2734 132.439 97.3242 115.339 97.3242 97.299L96.3242 97.299Z"
                    fill="#353535"
                  />
                </svg>
                <div
                  style={{
                    position: "relative",
                    marginTop: "6vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="laptop"
                    src={item.laptop}
                    alt="laptop"
                    style={{
                      borderRadius: index === 1 ? "2vw" : "none",
                      width: "33vw",
                      height: "50vh",
                      position: "relative",
                      zIndex: "3",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "1.5vh",
                    }}
                  >
                    {index === 0 ? (
                      <>
                        <div
                          className="img-one-container"
                          style={{
                            position: "absolute",
                            top: "-11vh",
                            right: "-13vw",
                            zIndex: "1",
                          }}
                        >
                          <img
                            className="img-one"
                            src={item.img_one}
                            alt="cart"
                            style={{
                              width: "5vw",
                              animation:
                                "moveRightLeftAndTopBottom 10s linear infinite", // Add animation property
                            }}
                          />
                        </div>
                        <div
                          className="img-two-container"
                          style={{
                            position: "absolute",
                            top: "23vh",
                            right: "-18vw",
                          }}
                        >
                          <img
                            className="img-two"
                            src={item.img_two}
                            alt="marketing"
                            style={{
                              width: "5vw",
                              animation: "topBottom 10s linear infinite",
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className="img-three-container"
                      style={{
                        position: "relative",
                        top: "-11vh",
                        right: "22vw",
                      }}
                    >
                      {index === 0 ? (
                        <img
                          className="img-three"
                          src={item.img_three}
                          alt="barchart"
                          style={{
                            width: "9vw",
                            animation: "right 10s linear infinite", // Add animation property
                            position: "absolute",
                            zIndex: "1",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="product-details">
                  <p className="bold-common-text">{item.title}</p>
                  <p className="pscroll" style={{ color: "#fff" }}>
                    {item.description}
                  </p>
                  <div
                    onClick={() => handleProductSoln(index)}
                    className="circle-arrow-container"
                    style={{
                      position: "absolute",
                      bottom: "5vh",
                      right: "3.5vw",
                      transform: "translate(50%, 50%)",
                    }}
                  >
                    <div
                      style={{
                        width: "6vw",
                        height: "6vw",
                        borderRadius: "50%",
                        background: "#90E900",
                        zIndex: "1",
                        border: "1px solid #111",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className={`rotate-on-scroll ${isInViewport === index ? "double-rotation" : ""
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28"
                        fill="none"
                        className="hover-svg"
                      >
                        <path
                          d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="casestudy-container">
        <div className="case-studies">
          <div className="service-heading" style={{ padding: "0px" }}>
            <p className="bold-common-text">Case Studies</p>
            <p
              id="expertise-description"
              className="regular-common-text"
              style={{ width: "21%" }}
            >
              Explore our case studies to see real-world examples of how our
              solutions drive success.
            </p>
          </div>
          <Divider
            style={{ border: "0.5px solid #656565", margin: "20px 0px" }}
          />

          <div
            className="cards-main-container"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="cards-container">
              <ul id="cards">
                <li class="card" id="card1">
                  <div class="card-body">
                    <img
                      className="cardimg-three"
                      src="https://s3.amazonaws.com/smarttrak.co/landing-images/case-card1.jpg"
                      alt="case"
                      style={{
                        borderRadius: "26px",
                        width: "31vw",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5vh",
                      }}
                    >
                      <p className="regular-common-text">Solar Industry</p>
                      <p
                        id="width-auto"
                        className="bold-common-text"
                        style={{ width: "36vw" }}
                      >
                        Solar Power Plant - Predictive Energy Generation Using
                        Deep Learning
                      </p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p
                        id="width-auto"
                        className="regular-common-text"
                        style={{ width: "34vw" }}
                      >
                        AI enables energy forecasting by leveraging advanced
                        data analytics, machine learning algorithms, and
                        real-time information.
                      </p>
                    </div>
                    <div
                      onClick={() => handleCaseStudy(0)}
                      style={{
                        position: "static",
                        marginTop: "37vh",
                        marginRight: "4.5vw",
                        transform: "translate(50%, 50%)",
                        transition: "transform 0.5s",
                      }}
                      className="case-arrow"
                      id="card-arrow-one"
                    >
                      <div
                        className="rotate-arrow-width"
                        style={{
                          width: "6vw",
                          height: "6vw",
                          borderRadius: "50%",
                          background: "#90E900",
                          border: "1px solid #111",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "transform 0.5s",
                          transform: isInViewport
                            ? "rotate(360deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28 28"
                          fill="none"
                          className="hover-svg"
                        >
                          <path
                            d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="card" id="card2">
                  <div class="card-body">
                    <img
                      className="cardimg-two"
                      src="https://s3.amazonaws.com/smarttrak.co/landing-images/case-card2.jpg"
                      alt="case"
                      style={{
                        borderRadius: "26px",
                        width: "31vw",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5vh",
                      }}
                    >
                      <p className="regular-common-text">Life Science</p>
                      <p
                        id="width-auto"
                        className="bold-common-text"
                        style={{ width: "36vw" }}
                      >
                        Rapid Genomic Sequencing Model Training Through GPU
                        Infrastructure
                      </p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p
                        id="width-auto"
                        className="regular-common-text"
                        style={{ width: "34vw" }}
                      >
                        Transform data into strategic assets with data
                        engineering solutions, centralized repositories, big
                        data solutions.
                      </p>
                    </div>
                    <div
                      onClick={() => handleCaseStudy(1)}
                      style={{
                        position: "static",
                        marginTop: "35vh",
                        marginRight: "3vw",
                        transform: "translate(50%, 50%)",
                      }}
                      id="card-arrow-two"
                      className="case-arrow"
                    >
                      <div
                        style={{
                          width: "6vw",
                          height: "6vw",
                          borderRadius: "50%",
                          background: "#90E900",
                          border: "1px solid #111",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "transform 0.5s",
                          transform: isInViewport
                            ? "rotate(360deg)"
                            : "rotate(0deg)",
                        }}
                        className="rotate-arrow-width"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28 28"
                          fill="none"
                          className="hover-svg"
                        >
                          <path
                            d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="card" id="card3">
                  <div class="card-body">
                    <img
                      className="cardimg-three"
                      src="https://s3.amazonaws.com/smarttrak.co/landing-images/case-card3.jpg"
                      alt="case"
                      style={{
                        borderRadius: "26px",
                        width: "31vw",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5vh",
                      }}
                    >
                      <p className="regular-common-text">Manufacturing</p>
                      <p
                        id="width-auto"
                        className="bold-common-text"
                        style={{ width: "36vw" }}
                      >
                        {" "}
                        Predicting Heavy Equipment Failure Using Real-Time Data
                        And Deep Learning
                      </p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p
                        id="width-auto"
                        className="regular-common-text"
                        style={{ width: "34vw" }}
                      >
                        Deep Learning plays a pivotal role in predicting machine
                        failure by harnessing real-time sensor data from
                        industrial equipment.
                      </p>
                    </div>
                    <div
                      onClick={() => handleCaseStudy(2)}
                      style={{
                        position: "static",
                        marginTop: "34vh",
                        marginRight: "2vw",
                        transform: "translate(50%, 50%)",
                      }}
                      className="case-arrow"
                      id="card-arrow-three"
                    >
                      <div
                        style={{
                          width: "6vw",
                          height: "6vw",
                          borderRadius: "50%",
                          background: "#90E900",
                          border: "1px solid #111",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "transform 0.5s",
                          transform: isInViewport
                            ? "rotate(360deg)"
                            : "rotate(0deg)",
                        }}
                        className="rotate-arrow-width"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28 28"
                          fill="none"
                          className="hover-svg"
                        >
                          <path
                            d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            onClick={handleViewAll}
            className="view-all"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              className="regular-common-text"
              id="common-btn"
              style={{
                cursor: "pointer",
                backgroundColor: "#2F67F8",
                borderRadius: "3vw",
                padding: "1.9vh 3vw",
                position: "relative",
                textTransform: "capitalize",
                width: "17%",
                boxShadow: "none",
              }}
            >
              View all
            </Button>
          </div>
        </div>
      </div>

      <div className="service-heading">
        <p className="bold-common-text" style={{ color: "#111" }}>
          Our Partners
        </p>
        <p
          id="expertise-description"
          className="regular-common-text"
          style={{ width: "25%", color: "#111" }}
        >
          Discover what our clients have to say about their experiences working
          with us.
        </p>
      </div>
      <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} />
      <div
        className="marque-main"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "3vw 0vw",
          overflow: "hidden",
        }}
      >
        <div className="marquee-container" ref={marqueeRef}>
          {partners.map((item, index) => {
            return (
              <div className="marquee-item" key={index}>
                <img
                  className="partners-img"
                  src={item}
                  alt="partner"
                  style={{ width: "5.2vw" }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "11vh",
        }}
      >
        <div className="subscribe-news">
          <div className="service-heading" style={{ padding: "0px" }}>
            <p className="bold-common-text">Subscribe to our newsletter!</p>
            <p
              id="expertise-description"
              className="regular-common-text"
              style={{ width: "39%" }}
            >
              Unlocking creativity across industries, including content
              generation, design, virtual avatars, and personalised
              storytelling, revolutionizing innovation and audience engagement.
            </p>
          </div>
          <Divider
            style={{ border: "0.5px solid #656565", margin: "20px 0px" }}
          />
          <div className="submit-main">
            <div className="submit-box">
              <div className="submit-btn">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Input your email to stay updated!"
                  style={{ color: "gray" }}
                  id="email-input"
                  className="regular-common-text"
                />
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  className="regular-common-text"
                  id="common-btn"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#212121",
                    borderRadius: "3vw",
                    padding: "1.9vh 3vw",
                    textTransform: "capitalize",
                    width: "13vw",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
