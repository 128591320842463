import React, { useState, useEffect, useRef } from "react";
import Navbar from "../MainLandingPage/Navbar";
import "./AboutUs.scss";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Footer from "../MainLandingPage/Footer";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import { UserService } from "../../IOT/Services/UserService";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
const userService = new UserService();
function AboutUs() {
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [textArea, setTextArea] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactError, setContactError] = useState("");
  const [textAreaError, setTextAreaError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const contactUsRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    // Check if the location has a hash and if it's targeting the contact-us section
    if (location.hash === "#contact-us" && contactUsRef.current) {
      // Scroll to the contact-us section
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  const validateFields = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError("Required Field*");
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Required Field*");
      valid = false;
    } else {
      setLastNameError("");
    }

    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!contact) {
      setContactError("Please enter your contact no.");
      valid = false;
    } else {
      setContactError("");
    }

    if (!textArea) {
      setTextAreaError("Please write message here.");
      valid = false;
    } else {
      setTextAreaError("");
    }
    return valid;
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTextAreaChange = (event) => {
    setTextArea(event.target.value);
    setIsChecked(true);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      let reqData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        message: textArea,
        terms_accepted: isChecked,
      };
      await userService
        .postContactUs(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            enqueueSnackbar(res.data.details, {
              variant: "success",
            });
          } else if (res.status === 422) {
            enqueueSnackbar("Please fill the valid fields", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("Error occured while contact us", {
            variant: "error",
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="landing-container">
      <Navbar />
      <div style={{ position: "relative" }}>
        <svg
          className="about-draw-line-one"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 679 97"
          fill="none"
        >
          <path
            className="vectorAnime"
            d="M672.682 2.82749C672.682 1.35473 673.876 0.160828 675.349 0.160828C676.822 0.160828 678.016 1.35473 678.016 2.82749C678.016 4.30025 676.822 5.49416 675.349 5.49416C673.876 5.49416 672.682 4.30025 672.682 2.82749ZM248.942 86.8812L248.671 86.461L248.942 86.8812ZM0 95.9634H216.415V96.9634H0V95.9634ZM248.671 86.461L364.102 11.9896L364.644 12.8299L249.213 87.3013L248.671 86.461ZM396.901 2.32749H675.349V3.32749H396.901V2.32749ZM364.102 11.9896C373.878 5.68218 385.266 2.32749 396.901 2.32749V3.32749C385.458 3.32749 374.259 6.62673 364.644 12.8299L364.102 11.9896ZM216.415 95.9634C227.857 95.9634 239.056 92.6641 248.671 86.461L249.213 87.3013C239.437 93.6087 228.049 96.9634 216.415 96.9634V95.9634Z"
            fill="#E6E6E6"
          />
        </svg>
      </div>
      <div
        className="about-container"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "22vh",
          marginBottom: "18vh",
        }}
      >
        <div className="about-main">
          <div className="img-divs">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/about_us.png"
              alt="about"
              className="img-aboutus"
            />
          </div>

          <div className="about-submain">
            <p className="bold-big-common-text">About Us</p>
            <p id="about-destn" className="semibold-smalltext aboutus-content">
              At Smarttrak AI, innovation is our ethos. We are at the forefront
              of technological progress, combining expertise in artificial
              intelligence with a commitment to sustainability. Our journey is
              marked by relentless advancement, featuring state-of-the-art AI
              models, including pioneering work in Large Language Models (LLMs).
              <br />
            </p>

            <p
              id="about-destn"
              className="semibold-smalltext aboutus-content"
              style={{ width: "65vw" }}
            >
              We provide high-fidelity{" "}
              <span style={{ fontFamily: "sans-serif" }}>3D</span> Digital Twin
              models for immersive solar farm management via VR (Oculus) and AR
              (Apple Vision Pro). Our advanced AI and ML systems deliver
              predictive analytics for fault detection and energy forecasting.
              Integrating Generative AI and LLMs, we offer real-time updates and
              predictive maintenance through a natural language interface.
              <br />
              Our autonomous drones capture real-time imagery for AI-driven
              analysis, and our virtual control room allows for remote
              monitoring and adjustments. With robust dataloggers for precise
              data collection, we ensure optimal energy production forecasting
              and grid management integration.
            </p>
          </div>
        </div>
        <div>
          <svg
            className="about-draw-line-two"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 87 299"
            fill="none"
          >
            <path
              className="vectorAnime"
              d="M83.7598 293.59C85.2325 293.59 86.4264 294.784 86.4264 296.257C86.4264 297.729 85.2325 298.923 83.7598 298.923C82.287 298.923 81.0931 297.729 81.0931 296.257C81.0931 294.784 82.287 293.59 83.7598 293.59ZM1.23438 0.0434623L1.23438 23.6552L0.234377 23.6552L0.234377 0.0434623L1.23438 0.0434623ZM19.087 66.1493L66.1071 112.232L65.4071 112.947L18.3871 66.8635L19.087 66.1493ZM84.2598 155.441L84.2598 296.257L83.2598 296.257L83.2598 155.441L84.2598 155.441ZM66.1071 112.232C77.7174 123.611 84.2598 139.184 84.2598 155.441L83.2598 155.441C83.2598 139.453 76.8255 124.137 65.4071 112.947L66.1071 112.232ZM1.23438 23.6552C1.23438 39.6432 7.66864 54.9584 19.087 66.1493L18.3871 66.8635C6.77678 55.4845 0.234377 39.9119 0.234377 23.6552L1.23438 23.6552Z"
              fill="#E6E6E6"
            />
          </svg>
        </div>
      </div>

      <div className="about-our-story">
        <div className="service-heading" style={{ padding: "0px" }}>
          <p className="bold-common-text">Our Story</p>
        </div>
        <Divider style={{ border: "0.5px solid #656565", margin: "4vh 0vw" }} />
        <div className="abt-img">
          <p className="semibold-smalltext" style={{ color: "#FFF" }}>
            Our journey in the renewable energy sector began with solar
            trackers, establishing a reputation for reliability and efficiency.
            Today, Smarttrak AI is redefining the market with a broad array of
            advanced solutions.<br/><br/> We’ve developed high-fidelity <span style={{ fontFamily: "sans-serif" }}>3D</span>  Digital Twin
            technology, offering immersive real-time solar farm monitoring
            through VR and AR. Our advanced AI and ML models power fault
            prediction and energy forecasting tools, ensuring efficiency and
            reliability. Integrating Generative AI and LLMs, we provide an
            intuitive interface for real-time updates and predictive
            maintenance. <br/><br/>
            Our solutions include real-time drone imagery for
            comprehensive monitoring and a virtual control room for remote
            management and optimization. We also offer state-of-the-art
            dataloggers for accurate, real-time data integration.<br/><br/> Our
            innovations improve solar farm efficiency and promote renewable
            energy use, driving sustainability in the clean energy sector. With
            a vision to expand across the power industry, Smarttrak AI is
            engineering a sustainable and efficient future, leading the
            transformation in renewable energy management.
          </p>

          <p className="semibold-smalltext" style={{ color: "#FFF" }}>
            Our vision is bold and forward looking to extend our technological
            reach across the entire spectrum of the power industry. From solar
            to wind, hydrogen, and energy storage systems, our goal is to set
            new benchmarks in innovation, efficiency, and sustainability. At
            Smarttrak AI, we&#39;re not just creating solutions, we&#39;re
            engineering a more sustainable and efficient future for the entire
            energy sector.
          </p>
          <p className="semibold-smalltext" style={{ color: "#FFF" }}>
            By integrating cutting-edge technologies and maintaining a strong
            focus on innovation, Smarttrak AI is leading the charge in
            transforming renewable energy management, making us a standout
            leader in the industry.
          </p>

          <img
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/aboutOurStory.jpg"
            style={{ borderRadius: "2vw", width: "100%" }}
            alt="about-our-story"
          />

          <p
            className="semibold-smalltext"
            style={{
              fontWeight: "700",
              color: "#FFF",
            }}
          >
            Join us on our journey as we strive to empower the power industry
            with smarter,
            <br /> more sustainable solutions—one innovation at a time.
          </p>
        </div>
        <div
          ref={contactUsRef}
          className="service-heading"
          style={{ padding: "0px", marginTop: "8vh" }}
        >
          <p className="bold-common-text">Contact us</p>
          <p
            id="expertise-description"
            className="regular-common-text"
            style={{ width: "21%" }}
          >
            Connect with Us: Reach Out for Inquiries, Collaboration, or Just to
            Say Hello!
          </p>
        </div>
        <Divider style={{ border: "0.5px solid #656565", margin: "4vh 0vw" }} />

        <div className="casestudy-container" style={{ marginTop: "0px" }}>
          <div className="case-studies">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "3vw",
              }}
            >
              <form onSubmit={handleSubmit} className="about-form-container">
                {loading && <SimpleBackdrop open={loading} />}

                <div className="first-lastname-container">
                  <div className="first-name">
                    <input
                      type="text"
                      name="firstName"
                      value={firstName}
                      id="firstName"
                      placeholder="First Name*"
                      onChange={handleFirstNameChange}
                      className={`form-input ${firstNameError ? "error" : ""}`}
                    />
                    {firstNameError && (
                      <div className="error-message">{firstNameError}</div>
                    )}
                  </div>
                  <br />

                  <div className="last-name">
                    <input
                      type="text"
                      name="lastName"
                      value={lastName}
                      id="lastName"
                      placeholder="Last Name*"
                      onChange={handleLastNameChange}
                      className={`form-input ${lastNameError ? "error" : ""}`}
                    />
                    {lastNameError && (
                      <div className="error-message">{lastNameError}</div>
                    )}
                  </div>
                  <br />
                </div>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="E-mail Address*"
                  onChange={handleEmailChange}
                  className={`form-input ${emailError ? "error" : ""}`}
                />
                {emailError && (
                  <div className="error-message">{emailError}</div>
                )}
                <PhoneInput
                  country={"us"}
                  value={contact}
                  placeholder="Contact Number*"
                  onChange={(contact) => setContact(contact)}
                  className={`form-input ${contactError ? "error" : ""}`}
                  style={{ flagWidth: "50px" }}
                />
                {contactError && (
                  <div className="error-message">{contactError}</div>
                )}

                <textarea
                  onChange={handleTextAreaChange}
                  rows="5"
                  cols="50"
                  placeholder="Write to us..."
                  className={`form-input ${textAreaError ? "error" : ""}`}
                ></textarea>
                {textAreaError && (
                  <div className="error-message">{textAreaError}</div>
                )}
                <label
                  className="regular-common-text"
                  style={{
                    marginBottom: "6vh",
                    color: "#8A8A8A",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1vw",
                  }}
                >
                  <input id="about-checkbox" type="checkbox" name="checkbox" />{" "}
                  I hereby acknowledge the data protection declaration
                  <br /> I have submitted.
                </label>

                <Button
                  id="common-btn"
                  type="submit"
                  variant="contained"
                  className="regular-common-text"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#2F67F8",
                    borderRadius: "3vw",
                    padding: "1.9vh 3vw",
                    textTransform: "capitalize",
                    boxShadow: "none",
                    width: "32%",
                  }}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "7vh" }}>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
