import React, { useState } from "react";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import "./Alram.scss";
import "./Alram.css";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CommonText from "../../Common/CommonText";
import { InverterService } from "../../Services/InverterService";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";

import { Backdrop, Modal, Fade } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ModalCalender from "../../Common/ModalCalender";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import { CosmicAlarmsService } from "../../Services/CosmicAlarmsService";
import { UserService } from "../../Services/UserService";
import BoldCommonText from "../../Common/BoldCommonText";
import Badge from "@mui/material/Badge";
import "react-dropdown/style.css";
import Select from "react-select";
import "../Reports/Report.css";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import Popover from "@mui/material/Popover";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import useErrorHandler from "../../../utils/errorHandler";

const inverterService = new InverterService();
const userService = new UserService();
const alarmService = new CosmicAlarmsService();
const invService = new CosmicInverterService();

const shapeStyles = { bgcolor: "#90E900", width: 20, height: 20 };
const shapeCircleStyles = { borderRadius: "50%" };
const styles = {
  chatWindow: {
    display: "flex",
    height: "56vh",
    width: "100%",
    flexDirection: "column",
    overflowY: "auto",
    border: "0.0625rem solid #F4F4F4",
    padding: "1rem",
    borderRadius: "0.3125rem",
  },
  profileImage: {
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%",
    margin: "0rem 0.625rem",
  },
  userMessage: {
    alignSelf: "flex-end",
    background: "#E7E8EC",
    borderRadius: "1.5rem 0rem 1.5rem 1.5rem",
    wordBreak: "break-word",
    padding: "1rem",
    textAlign: "right",

    width: "100%",
  },
  messageDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: "10%",
    marginBottom: "4px",
  },
  aiMessage: {
    alignSelf: "flex-start",
    background: "#D9E5F0",
    borderRadius: "0rem 1.5rem 1.5rem 1.5rem",
    width: "100%",

    wordBreak: "break-word",
    padding: "1rem",
    textAlign: "left",
  },
};
const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "3rem 1.875rem 1.25rem 1.875rem",
  backgroundColor: "white",
  color: "black",
  borderRadius: "0.625rem !important",
};


const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);
function Alram() {
  const [message, setMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [alramData, setAlramData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceTypeValue, setDeviceTypeValue] = useState([]); //device type option

  const [commentData, setCommentData] = useState({});
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [userMessages, setUserMessages] = useState([
    // { role: "ai", content: "Comment here" },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [rowData, setRowData] = useState({});

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);


  const [openAssignClick, setOpenAssignClick] = useState(false);

  const [isDeviceType, setIsDeviceType] = useState("Select");
  const [isAssignerName, setIsAssignerName] = useState("Select");

  const [isAssignerMail, setIsAssignerMail] = useState("Select");

  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");
  const [originalDataArray, setOriginalDataArray] = useState([]);
  const [originalFilterArray, setOriginalFilterArray] = useState([]);
 
  const [assignerNameList, setAssignerNameList] = useState([
    "Select",
    "Nischitha LN",
    "Namratha",
    "Deepak",
    "Kiranmayi",
    "Johnson",
  ]);
  const [assignerEmailList, setAssignerEmailList] = useState([
    "Select",
    "nischitha@smarttrak.com",
    "namratha@smarttrak.com",
    "deepak@smarttrak.com",
    "kiranmayi@smarttrak.com",
    "johnson@smarttrak.com",
  ]);
  const [aiLoader, setAiLoder] = useState(false);

  const [isFilter, setIsFilter] = useState(false);
  const [warningCount, setWarningCount] = useState(0);
  const [criticalCount, setCriticalCount] = useState(0);
  const [resetData, setResetDataArray] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [alarmId, setAlarmId] = useState(0);
  const [plantListsArray, setPlantListsArray] = useState([]);
  const [selectedPlantIndex, setSelectedPlantIndex] = useState(0);
  const [selectedData, setSelectedData] = useState({
    staffId: "",
    name: "",
    reason: "",
  });
  const [status, setStatus] = useState("");
  const [filtertedVal, setFiltertedVal] = useState("all");

  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState("Active");

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(currentDate.getDate()).padStart(2, "0");
  const defaultEndValue = `${day1}-${month1}-${year1}`;
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  const [plantId, setPlantId] = useState(id);
  const token = localStorage.getItem("authToken");
  function getUserRole() {
    const role = localStorage.getItem("role");
    return role;
  }
  const history = useHistory();
  const handleFetchError = useErrorHandler();
  const valueArray = [
    "0-UnAcknowledged",
    `${
      alramData &&
      alramData.length >0
        ?  alramData.filter(alarm => alarm.alarm_severity === "high").length
        : 0
    }-Critical`,
    `${alramData &&
      alramData.length >0
        ?  alramData.filter(alarm => alarm.alarm_severity === "low").length
        : 0}-Warning`,
    `${selectedRows.length}-Custom`,
  ];

  useEffect(() => {
    getPlantsData();
    handleDeviceType();
  }, []);
  useEffect(() => {
    if (plantId) {
      handlePlantClick(selectedPlantIndex, plantId);
    }
  }, [plantId]);
  useEffect(() => {
    if (plantId) {
      if (isStartTime && isStopTime) {
        handleAlramData(plantId, true, isDeviceType, isStartTime, isStopTime);
      } else {
        const fetchData = () => {
          handleAlramData(plantId, true);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStartTime, isStopTime]);
  const columns = [
    { field: "status", title: "Status" },
    { field: "alarm_id", title: "Alarm ID" },
    { field: "device_name", title: "Device Name" },
    { field: "alarm_severity", title: "Severity" },

    { field: "raised_at", title: "Raised At" },
    { field: "active_since", title: "Active Since" },
    { field: "description", title: "Description" },
    { field: "assigned_to_name", title: "Assigned To" },
    { field: "assigner_by_name", title: "Assigned By" },
    { field: "last_active", title: "Last Active" },

    { field: "acknowledge", title: "Acknowledged" },

    { field: "resolved_timestamp", title: "Resolved Timestamp" },
    { field: "filter", title: "Filter" },
    { field: "view", title: "View" },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeviceType = async () => {
    try {
      setLoading(true);
      const res = await invService.getReportDeviceTypes();
      if (res.status === 200) {
        setDeviceTypeValue(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      handleFetchError(error, "error in the device type data ");
    }
  };
  const getPlantsData = async () => {
    try {
      setLoading(true);
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      handleFetchError(error, "error in the plants data ");
    }
  };

  const sendChatMessage = async (content) => {
    setAiLoder(true);

    const newMessageStrings = [];

    let lastAiMessage;
    let lastUserMessage;

    for (let i = userMessages.length - 1; i >= 0; i--) {
      const message = userMessages[i];
      if (message.role === "user" && !lastUserMessage) {
        if (message.content !== undefined) {
          lastUserMessage = message.content;
        } else {
          lastUserMessage = " ";
        }
      } else if (message.role === "ai" && !lastAiMessage) {
        lastAiMessage = message.content;
      }

      if (lastAiMessage && lastUserMessage) {
        break;
      }
    }

    if (userMessages.length > 1) {
      newMessageStrings.push(`user: ${lastUserMessage}`);
      newMessageStrings.push(`${lastAiMessage}`);
      if (content) {
        newMessageStrings.push(`User: ${content}`);
        setUserMessages((prevUserMessages) => [
          ...prevUserMessages,
          { role: "user", content: content },
        ]);
        setMessage("");
      }
    } else {
      newMessageStrings.push(`${lastAiMessage}`);
      if (content) {
        newMessageStrings.push(`User: ${content}`);
        setUserMessages((prevUserMessages) => [
          ...prevUserMessages,
          { role: "user", content: content },
        ]);
        setMessage("");
      }
    }

    const reqData =
      message === ""
        ? {}
        : {
            current_input: newMessageStrings.join("\n"),
            chat_history: [],
          };

    await inverterService
      .postChatBotData(reqData)
      .then((res) => {
        if (res.status === 200 && res?.data) {
          setUserMessages((prevUserMessages) => [
            ...prevUserMessages,
            { role: "ai", content: res.data.response },
          ]);
        } else if (res?.data?.length <= 0) {
          enqueueSnackbar("No data available,", {
            variant: "warning",
          });
        }
        setAiLoder(false);
      })
      .catch((err) => {
          setAiLoder(false);
    
          handleFetchError(err, "error in the message data ");
      });
  };
  const handleAssignClick = async (event, status, Id) => {
    event.preventDefault();
    setLoading(true);
    if (getUserRole() === "TECSTAFF") {
      if (status === "ACTIVE" || status === "ASSIGNED") {
        let reqData = {
          alarm_id: Id ? Id : alarmId,
        };
        await alarmService
          .postAcknowledgeAlarms(reqData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              setLoading(false);
              setOpen(false);
              handleAlramData(plantId, true);
              enqueueSnackbar(res.data.status, {
                variant: "success",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.response.data.detail, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            setOpen(false);
          });
      }
      if (status === "ACKNOWLEDGED") {
        let reqData = {
          alarm_id: Id ? Id : alarmId,
        };
        await alarmService
          .postResolveAlarms(reqData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              setLoading(false);
              setOpen(false);
              handleAlramData(plantId, true);
              enqueueSnackbar(res.data.status, {
                variant: "success",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.response.data.detail, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            setOpen(false);
          });
      }
    } else if (getUserRole() === "EXECUTIVE" || getUserRole() === "MANAGER") {
      if (status === "ACTIVE") {
        let reqData = {
          alarm_id: alarmId,
          assigned_to_id: selectedData.staffId,
          assigner_by_id: token,
          assignment_reason: selectedData.reason,
        };
        await alarmService
          .postAssignAlarms(reqData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              setLoading(false);
              setOpen(false);
              handleAlramData(plantId, true);
              enqueueSnackbar("Alarm Assigned successfully", {
                variant: "success",
              });
            } else if (res.status === 422) {
              enqueueSnackbar("Please select the required fields", {
                variant: "warning",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error", err);
            enqueueSnackbar(err.response.data.detail, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
          });
      }
      if (status === "ASSIGNED" || status === "ACKNOWLEDGED") {
        let reqData = {
          alarm_id: alarmId,
        };
        await alarmService
          .postResolveAlarms(reqData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              setLoading(false);
              setOpen(false);
              handleAlramData(plantId, true);
              enqueueSnackbar(res.data.status, {
                variant: "success",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.response.data.detail, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            setOpen(false);
          });
      }
    }
  };
  const handleTimings = () => {
    setOpenCalender(true);
  };
  const handleChangeDevice = (event) => {
    setIsDeviceType(event.value.toLowerCase());
    if (tabValue === "Active") {
      handleAlramData(
        id,
        true,
        event.value.toLowerCase(),
        isStartTime,
        isStopTime
      );
    } else {
      handleAlramData(
        id,
        false,
        event.value.toLowerCase(),
        isStartTime,
        isStopTime
      );
    }
  };

  const handleWarnings = () => {
    setIsFilter(true);
    if (alramData) {
      const criticalAlarms = alramData.critical_alarms.map((alarm) =>
        alarm.trim().toLowerCase()
      );
      const alarmsNames = Object.values(alramData.inverters_alarms.alarms_name);

      const nonCriticalAlarms = [];

      for (let i = 0; i < alarmsNames.length; i++) {
        let alarmName = alarmsNames[i];

        let isCritical = false;
        for (let j = 0; j < criticalAlarms.length; j++) {
          if (alarmName === criticalAlarms[j]) {
            isCritical = true;
            break;
          }
        }
        if (!isCritical) {
          nonCriticalAlarms.push(alarmName);
        }
      }
      const filteredWarnings = Object.values(
        alramData.inverters_alarms.alarms_name
      ).filter(
        (alarmName) => !criticalAlarms.includes(alarmName.trim().toLowerCase())
      );

      const filteredDataArray = dataArray.filter((item) =>
        filteredWarnings.includes(item.alarm_id)
      );
      setDataArray(filteredDataArray);
    }
  };
  const handleCritical = () => {
    setIsFilter(false);
    if (alramData) {
      const criticalAlarms = alramData.critical_alarms.map((alarm) =>
        alarm.trim().toLowerCase()
      );

      const filteredCriticalAlarms = Object.values(
        alramData.inverters_alarms.alarms_name
      ).filter((alarmName) =>
        criticalAlarms.includes(alarmName.trim().toLowerCase())
      );

      const filteredDataArray = originalDataArray.filter((item) =>
        filteredCriticalAlarms.includes(item.alarm_id)
      );
      setOriginalDataArray(filteredDataArray);
    }
  };
  const handleReset = () => {
    setIsFilter(true);

    setDataArray(resetData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  const handleAlramData = async (
    id,
    activeStatus,
    isDeviceType,
    start_time,
    end_time
  ) => {
    if (id) {
      try {
        setLoading(true);
        const reqData = {};
        if (activeStatus !== "") {
          reqData.is_active = activeStatus;
        }

        if (start_time && end_time) {
          reqData.start_time = decodeURIComponent(start_time);
          reqData.end_time = decodeURIComponent(end_time);
        }
        if (isDeviceType && isDeviceType !== "Select") {
          reqData.device_type = isDeviceType;
        }

        const res = await alarmService.getAlarms(id, reqData);
        if (res.status === 200) {
          setAlramData(res.data);
          // const {severity_data,alarms_value,alarms_name,inverter_id,location,raised_data,activeSince,description_data,acknowledge_data} = res.data.inverters_alarms;
          // const {status, alarm_type } =
          //   res.data;
          // const criticalAlarms = res.data.critical_alarms.map((alarm) =>
          //   alarm.trim().toLowerCase()
          // );
          // const filteredCriticalAlarms = Object.values(alarm_type).filter((alarmName) =>
          //   criticalAlarms.includes(alarmName.trim().toLowerCase())
          // );
          // const nonCriticalAlarms = Object.values(alarm_type).filter((alarmName) =>
          //   !criticalAlarms.includes(alarmName.trim().toLowerCase())
          // );
          // setWarningCount(nonCriticalAlarms.length);
          // setCriticalCount(filteredCriticalAlarms.length);
          // const invName = Object.values(res.data.inverters_alarms.inverter_id);
          // const uniqueAlarmNames = [...new Set(invName)]
          // setInverterCount(uniqueAlarmNames.length)
          const valueArray = [];
          // const keys = Object.keys(alarms_value);
          // const lastKey = Math.max(...keys);
          // for (let i = 0; i <= res.data.length; i++) {
          // const severity = severity_data[i] ? severity_data[i] :"--";
          // const status = res.data.status === "ACTIVE" ? "ON" : "OFF";
          // const alarm_id = res.data.alarm_type ? alarm_type : "--";
          // const device_name = inverter_id[i] ? inverter_id[i] : "--";
          // const zone = location[i] ? location[i] : "--";
          // const raised_at = "--";
          // const active_since = "--";
          // const description = "--";
          // const acknowledge = "--";
          // const raised_at = raised_data[i] ? raised_data[i] :"--";
          // const active_since = activeSince[i] ? activeSince[i] :"--";
          // const description = description_data[i] ? description_data[i] :"--";
          // const acknowledge = acknowledge_data[i] ? acknowledge_data[i] :"--";

          res.data.forEach((item) => {
            // Extract the desired values and push them into the extractedValues array
            valueArray.push({
              is_active:
                item.is_active === true
                  ? "Active"
                  : item.is_active === false
                  ? "Inactive"
                  : "--",
              alarm_type: item.alarm_type !== "string" ? item.alarm_type : "--",
              device_name:
                item.device_name !== null && item.device_name !== undefined
                  ? item.device_name
                  : "--",

              alarm_severity:
                item.alarm_severity !== null &&
                item.alarm_severity !== undefined
                  ? item.alarm_severity
                  : "--",
              active_since:
                item.active_since !== null && item.active_since !== undefined
                  ? item.active_since
                  : "--",
              assigned_timestamp: item.assigned_timestamp
                ? item.assigned_timestamp
                : "--",
              description: item.description ? item.description : "--",
              last_active: item.timestamp_edge ? item.timestamp_edge : "--",

              assigned_to_name: item.assigned_to_name
                ? item.assigned_to_name
                : "--",

              assigned_by_name: item.assigned_by_name
                ? item.assigned_by_name
                : "--",
              resolved_timestamp:
                item.resolved_timestamp !== null
                  ? item.resolved_timestamp
                  : "--",
              status: item.status ? item.status : "--",
              id: item.id,
            });
          });

          setDataArray(valueArray);
          setOriginalDataArray(valueArray);
          setResetDataArray(valueArray);
          setOriginalFilterArray(valueArray);
        }

        setLoading(false);
      } 
      catch (error) {
        setLoading(false);

        handleFetchError(error, "error in the alarms data ");
      }
    } 
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => ascendingComparator(a[orderBy], b[orderBy]);
  };

  const ascendingComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAlramComments();
    }
  };
  const handleAlramComments = async () => {
    const reqData = {
      device_name: commentData.device_name,
      oem_severity: commentData.alarm_severity,
      alarm_type: commentData.alarm_type,
      acknowlege_at: commentData.assigned_timestamp,
      acknowlege_by: commentData.assigned_by_name,
      comments: comment,
    };
    const isEmptyField =
      !comment ||
      !commentData.device_name ||
      !commentData.alarm_type ||
      !reqData.oem_severity;
    if (isEmptyField) {
      enqueueSnackbar(
        "Device type, OEM Severity, Alarm Type and Comments are the required fields",
        { variant: "warning" }
      );
    } else {
      setLoading(true);
      const newMessageStrings = [];

      let lastAiMessage;
      let lastUserMessage;

      for (let i = userMessages.length - 1; i >= 0; i--) {
        const message = userMessages[i];
        if (message.role === "user" && !lastUserMessage) {
          if (message.content !== undefined) {
            lastUserMessage = message.content;
          } else {
            lastUserMessage = " ";
          }
        } else if (message.role === "ai" && !lastAiMessage) {
          lastAiMessage = message.content;
        }

        if (lastAiMessage && lastUserMessage) {
          break;
        }
      }
      if (userMessages.length > 1) {
        newMessageStrings.push(`user: ${lastUserMessage}`);
        newMessageStrings.push(`ai: ${lastAiMessage}`);
        if (comment) {
          newMessageStrings.push(`User: ${comment}`);
          setComment("");
        }
      } else {
        newMessageStrings.push(`ai: ${lastAiMessage}`);
        if (comment) {
          newMessageStrings.push(`User: ${comment}`);
          setComment("");
        }
      }
      await inverterService
        .postAlram_Comments(reqData)
        .then((res) => {
          if (res.status === 200) {
            setUserMessages((prevUserMessages) => [
              ...prevUserMessages,
              { role: "user", content: comment },
              // { role: "ai", content: res.data.output },
            ]);
            setLoading(false);
            enqueueSnackbar("sent successfully", {
              variant: "success",
            });
            setComment("");
          } else if (res.status === 404) {
            enqueueSnackbar("There is No Alram Data", {
              variant: "warning",
            });
          }
          
        })
        .catch((err) => {
          setLoading(false);

          handleFetchError(err, "error in the alarms data ");
        });
    }
  };
  
  const handleEdit = async (alarmIds, alarmStatus) => {
    if (plantId) {
      try {
        setLoading(true);
        setStatus(alarmStatus);
        const res = await alarmService.getAdminStaff(plantId);
        if (res.status === 200) {
          setOpen(true);
          setLoading(false);
          setStaffData(res.data);
          const defaultName = `${res.data[0].first_name} ${res.data[0].last_name}`;
          setSelectedData((prevState) => ({
            ...prevState,
            name: defaultName,
            staffId: res.data[0].id,
          }));
          setAlarmId(alarmIds);
        }
      }
      catch (error) {
        setLoading(false);

        handleFetchError(error, "error in the alarms data ");
      }
    }
  };
  const handleReasonChange = (event) => {
    setSelectedData({
      ...selectedData,
      reason: event.target.value,
    });
  };
  const handleInputChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedId = staffData[selectedIndex].id;
    const selectedItem = staffData[selectedIndex];
    setSelectedData((prevData) => ({
      ...prevData,
      staffId: selectedId,
      name: `${selectedItem.first_name} ${selectedItem.last_name}`,
      reason: prevData.reason,
    }));
  };

  const handlePlantClick = (index, id) => {
    setSelectedPlantIndex(index);
    setPlantId(id);
    handleAlramData(id, true);
  };
  const handletabVal = (val) => {
    setTabValue(val);
  };
  const handlePopoverClose = (val) => {
    if (val === "All") {
      setDataArray(originalFilterArray);
      setFiltertedVal("all");
    }
    if (getUserRole() === "TECSTAFF") {
      if (val === "Resolve") {
        const filteredArray = originalFilterArray.filter(
          (row) => row.status === "ACKNOWLEDGED"
        );
        setFiltertedVal("ACKNOWLEDGED");

        setDataArray(filteredArray);
      } else if (val === "Acknowledge") {
        const filteredArray = originalFilterArray.filter(
          (row) => row.status === "ACTIVE" || row.status === "ASSIGNED"
        );
        setFiltertedVal("ACTIVE");

        setDataArray(filteredArray);
      }
      setAnchorEl(null);
    } else if (getUserRole() === "EXECUTIVE" || getUserRole() === "MANAGER") {
      if (val === "Assign") {
        const filteredArray = originalFilterArray.filter(
          (row) => row.status === "ACTIVE"
        );
        setDataArray(filteredArray);
        setFiltertedVal("ACTIVE");
      } else if (val === "Resolve") {
        const filteredArray = originalFilterArray.filter(
          (row) => row.status === "ACKNOWLEDGED" || row.status === "ASSIGNED"
        );
        setFiltertedVal("ACKNOWLEDGED");

        setDataArray(filteredArray);
      }
      setAnchorEl(null);
    }
  };

  const handleFilterAlarms = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAssignAlram = (row) => {
    // setOpenAssignClick(true);
    setRowData(row);
    setOpenDrawer(true);
  };
  const handleSurge = (row) => {
    history.push({
      pathname: "/iot-home/alarms-surge",
      state: { row },
    });
  };
  const handleAssignerEmailChange = (name) => {
    setIsAssignerMail(name.value);
  };
  const handleAssignerNameChange = (name) => {
    setIsAssignerName(name.value);
  };
  const handleCheckboxClick = (row, index) => {
    setCommentData(row);
    const selectedIndex = selectedRows.indexOf(index);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, index];
    } else {
      newSelectedRows = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ];
    }

    setSelectedRows(newSelectedRows);
  };

  // const handleSelectAll = (isChecked) => {
  //   if (isChecked) {
  //     setSelectedRows(dataArray.map((_, index) => index)); // Select all
  //   } else {
  //     setSelectedRows([]); // Deselect all
  //   }
  // };
  const handleSelectAllClick = () => {
    const allSelected = selectedRows.length === dataArray.length;
    const newSelectedRows = allSelected
      ? []
      : dataArray.map((_, index) => index);
    setSelectedRows(newSelectedRows);
  };
  const isSelected = (index) => selectedRows.indexOf(index) !== -1;

  const isAllSelected = selectedRows.length === dataArray.length;
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="home-container">
      
      
      
      {loading && <SimpleBackdrop open={loading} />}
      <Grid container className="grid-container">
        <Grid item lg={11.9} xs={12} sm={12} md={11.9} className="first_grid">
          <Paper className="first_paper">
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => handletabVal(newValue)}
              >
                <Tab
                  onClick={() =>
                    handleAlramData(
                      plantId,
                      true,
                      isDeviceType,
                      isStartTime,
                      isStopTime
                    )
                  }
                  sx={{color: tabValue==="Active" ? "black !important" : "gray !important"}}
                  value="Active"
                  className="tabTextColor"
                  label={
                    <div className="green-tab" >
                      Active
                      {dataArray.length > 0 && (
                        <div className="green-tab">
                          <Badge
                            color="black"
                            badgeContent={alramData && alramData.length>0 ? alramData.filter(obj => obj.is_active).length : 0}
                            showZero 
                            sx={{
                              "& .MuiBadge-badge": {
                                top: "50% !important",
                                right: "50% !important",
                              },
                            }}
                          >
                            {circle}
                          </Badge>
                        </div>
                      )}
                    </div>
                  }
                />
                <Tab
                  sx={{color: tabValue==="History" ? "black !important" : "gray !important"}}
                  value="History"
                  label="History"
                  className="tabTextColor"
                  onClick={() =>
                    handleAlramData(
                      plantId,
                      false,
                      isDeviceType,
                      isStartTime,
                      isStopTime
                    )
                  }
                />
              </Tabs>
            </Box>
            <div className="green-tab">
              <Tooltip title="Calendar" arrow>
                <CalendarMonthRoundedIcon
                  onClick={handleTimings}
                  sx={{
                    width: "1.6vw !important",
                    height: "fit-content",
                    cursor: "pointer",
                    color: "#00529A",
                  }}
                />
              </Tooltip>
              <Divider
                sx={{ margin: "0vh 1vh" }}
                style={{ border: "0.0625rem solid #969696", height: "2rem" }}
                orientation="vertical"
              />
              <div className="full-width" >
                {" "}
                <p className="typeText">Device Type:-</p>
              </div>
              <div style={{ width: "14rem", minWidth: 160,zIndex:"1" }}>
                <FormControl className="form-report" fullWidth>
                  <Select
                    options={deviceTypeValue.map((field) => ({
                      value: field,
                      label: field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase()),
                    }))}
                    isMulti={false}
                    onChange={handleChangeDevice}
                    value={{
                      value: isDeviceType,
                      label: isDeviceType
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase()),
                    }}
                    placeholder="Choose the Device Type"
                  />
                </FormControl>
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item lg={12} xs={12} sm={12} md={11.9} className="table-grid" style={{zIndex:"0"}}>
          <Paper className="table-paper">
            <div className="green-tab">
              {valueArray.map((ele, index) => (
                <div className="div-border" key={index}>
                  <p className="div-borderText">{ele}</p>
                </div>
              ))}
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="alram-btn_style"
              >
                {" "}
                <p className="raleway_20px_rem">Assign Selected</p>
              </Button>
            </div>
          </Paper>
          <TableContainer className="table-scrollbar-abt" component={Paper}>
        <div className="abt_table_scrol">

            {dataArray.length === 0 ? (
              <Table stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "0rem solid gray !important" }}
                    colSpan={15}
                    align="center"
                    className="div-borderText"
                  >
                    No data available
                  </TableCell>
                </TableRow>
                <Divider style={{ border: "1px solid #D9D9D9" }} />
              </Table>
            ) : (
              <Table stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead  
                        id="alarmCell"
                        >
                  <TableRow
                   
                  >
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      className="alaram-check"
                      sx={{
                        backgroundColor: "#E6EEF5 !important",
                      }}
                    >
                      <div className="checkbox-alram">
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={handleSelectAllClick}
                          // onChange={(e) => handleSelectAll(e.target.checked)}
                          style={{
                            accentColor: isAllSelected ? "#90E900" : "initial", // Set checkbox color
                            outlineColor: isAllSelected ? "#2F67F8" : "initial",
                          }}
                        />
                      </div>
                    </TableCell>
                    {columns.map((column) =>
                      column.field !== "resolved_timestamp" &&
                      dataArray.some((item) => item.is_active === "Active") ? (
                        <TableCell
                          key={column.field}
                          align="left"
                          sortDirection={
                            orderBy === column.field ? order : false
                          }
                          className="alaram-check"
                          sx={{
                            backgroundColor: "#E6EEF5 !important",
                          }}
                        >
                          {column.field === "filter" ? (
                            <Tooltip title="Filter" arrow>
                              <FilterListRoundedIcon
                                onClick={handleFilterAlarms}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : column.field === "view" ? (
                            <p className="alarm-tableText">{column.title}</p>
                          ) : (
                            <TableSortLabel
                              active={orderBy === column.field}
                              direction={
                                orderBy === column.field ? order : "asc"
                              }
                              onClick={() => {
                                setOrderBy(column.field);
                                setOrder((prevOrder) =>
                                  prevOrder === "asc" ? "desc" : "asc"
                                );
                              }}
                            >
                              <p className="alarm-tableText">{column.title}</p>
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ) : dataArray.some(
                          (item) => item.is_active === "Inactive"
                        ) ? (
                        <TableCell
                          key={column.field}
                          align="center"
                          sortDirection={
                            orderBy === column.field ? order : false
                          }
                          className="alaram-check"
                          sx={{
                            backgroundColor: "#E6EEF5 !important",
                          }}
                        >
                          {column.field === "filter" ? (
                            <Tooltip title="Filter" arrow>
                              <FilterListRoundedIcon
                                onClick={handleFilterAlarms}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : (
                          <TableSortLabel 

                              active={orderBy === column.field}
                              direction={
                                orderBy === column.field ? order : "asc"
                              }
                              onClick={() => {
                                setOrderBy(column.field);
                                setOrder((prevOrder) =>
                                  prevOrder === "asc" ? "desc" : "asc"
                                );
                              }}
                            >
                              <p className="alarm-tableText">{column.title}</p>

                            </TableSortLabel>
                          )}
                        </TableCell>
                      ) : (
                        ""
                      )
                    )}
                  </TableRow>
                </TableHead>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "left",
                    horizontal: "center",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      style={{ padding: "1vh" }}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue={filtertedVal}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All"
                        onClick={() => handlePopoverClose("All")}
                      />
                      {(getUserRole() === "EXECUTIVE" ||
                        getUserRole() === "MANAGER") && (
                        <>
                          {" "}
                          <FormControlLabel
                            value="ACTIVE"
                            control={<Radio />}
                            label="Assign"
                            onClick={() => handlePopoverClose("Assign")}
                          />
                          <FormControlLabel
                            value="ACKNOWLEDGED"
                            control={<Radio />}
                            label="Resolve"
                            onClick={() => handlePopoverClose("Resolve")}
                          />
                        </>
                      )}
                      {getUserRole() === "TECSTAFF" && (
                        <>
                          <FormControlLabel
                            value="ACTIVE"
                            control={<Radio />}
                            label="Acknowledge"
                            onClick={() => handlePopoverClose("Acknowledge")}
                          />
                          <FormControlLabel
                            value="ACKNOWLEDGED"
                            control={<Radio />}
                            label="Resolve"
                            onClick={() => handlePopoverClose("Resolve")}
                          />{" "}
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Popover>
                <TableBody>
                  {stableSort(dataArray, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        id="alarmCell"
                        key={index}
                        selected={isSelected(index)}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: isSelected(index)
                            ? "#FFFBE6"
                            : "transparent",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="checkbox-alram">
                            <input
                              type="checkbox"
                              checked={isSelected(index)}
                              onChange={() => handleCheckboxClick(row, index)}
                              style={{
                                accentColor: isSelected(index)
                                  ? "#90E900"
                                  : "initial", // Set checkbox color
                                outlineColor: isSelected(index)
                                  ? "#2F67F8"
                                  : "initial",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: "pointer" }}
                          align="left"
                        >
                          <p
                            className="alarm-tableText-val"
                            style={{
                              color:
                                row.is_active === "Active"
                                  ? "#04724D"
                                  : "#FF472E",
                            }}
                          >
                            {row.is_active}
                          </p>
                        </TableCell>
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          align="left"
                          // onClick={() => handleSurge(row)}
                        >
                          <p id="scrollingHistory"
                            className="alarm-tableText-val"
                            // style={{ color: "#2f67f8 " }}
                          >
                            {row.alarm_type}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">{row.device_name}</p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p
                            className="high-p"
                            style={{
                              color:
                                row.alarm_severity === "medium" ||
                                row.alarm_severity === "low"
                                  ? "black"
                                  : "white",
                              background:
                                row.alarm_severity === "high"
                                  ? "#ff472e"
                                  : row.alarm_severity === "medium"
                                  ? "#B1D3C8"
                                  : "#BBD6FF",
                            }}
                          >
                            {row.alarm_severity}
                          </p>
                        </TableCell>
                        {/* <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">{row.zone}</p>
                        </TableCell> */}
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {row.assigned_timestamp
                              .replace(/T|\.|Z/g, " ")
                              .slice(0, 16)}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {row.active_since
                              .replace(/T|\.|Z/g, " ")
                              .slice(0, 16)}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">{row.description}</p>
                        </TableCell>

                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {" "}
                            {row.assigned_to_name}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {row.assigned_by_name}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {row.last_active
                              .replace(/T|\.|Z/g, " ")
                              .slice(0, 16)}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ cursor: "pointer" }}>
                          <p className="alarm-tableText-val">
                            {row.assigned_timestamp
                              .replace(/T|\.|Z/g, " ")
                              .slice(0, 16)}
                          </p>
                        </TableCell>

                        {columns.some(
                          (column) => column.field === "resolved_timestamp"
                        ) &&
                        !dataArray.some(
                          (item) => item.is_active === "Inactive"
                        ) ? null : (
                          <TableCell align="left" sx={{ cursor: "pointer" }}>
                            <p className="alarm-tableText-val">
                              {" "}
                              {row.resolved_timestamp
                                .replace(/T|\.|Z/g, " ")
                                .slice(0, 16)}
                            </p>
                          </TableCell>
                        )}

                        <TableCell
                          sx={{
                            cursor:
                              row.status === "RESOLVED"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          align="left"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor:
                                row.status === "RESOLVED" ? "green" : "#2A3568",
                              borderRadius: "0.25rem",
                              fontSize: "0.625rem",
                              fontWeight: "700",
                              textTransform: "capitalize",
                              color: "white",
                              padding: "0.3125rem 0.625rem",
                              width: "100%",
                              pointerEvents:
                                row.status === "RESOLVED" ? "none" : "auto",
                              cursor:
                                row.status === "RESOLVED"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={
                              row.status !== "RESOLVED" &&
                              (getUserRole() === "EXECUTIVE" ||
                                getUserRole() === "MANAGER")
                                ? () => handleEdit(row.id, row.status)
                                : getUserRole() === "TECSTAFF"
                                ? (event) =>
                                    handleAssignClick(event, row.status, row.id)
                                : undefined
                            }
                            size="small"
                          >
                            {" "}
                            {getUserRole() === "TECSTAFF"
                              ? row.status === "ACTIVE"
                                ? "Acknowledge"
                                : row.status === "ASSIGNED"
                                ? "Acknowledge"
                                : row.status === "ACKNOWLEDGED"
                                ? "Resolve"
                                : row.status === "RESOLVED"
                                ? "Resolved"
                                : "--"
                              : row.status === "ACTIVE"
                              ? "Assign"
                              : row.status === "ASSIGNED"
                              ? "Resolve"
                              : row.status === "ACKNOWLEDGED"
                              ? "Resolve"
                              : row.status === "RESOLVED"
                              ? "Resolved"
                              : "--"}
                          </Button>
                        </TableCell>
                        <TableCell
                          className="div-borderText"
                          sx={{ cursor: "pointer" }}
                          align="center"
                        >
                          <RemoveRedEyeOutlinedIcon
                            onClick={() => handleAssignAlram(row)}
                            sx={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            </div>
            <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 5,
                  backgroundColor: "white",
                }}
              >
            <TablePagination
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              rowsPerPageOptions={[5, 10, 15, 25, 50]}
              component="div"
              count={dataArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openAssignClick}
              onClose={() => setOpenAssignClick(false)}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openAssignClick}>
                <Box sx={boxStyle}>
                  <div className="assignerpopup">
                    <p
                      className="raleway_20.25rem_rem"
                      style={{ textAlign: "center" }}
                    >
                      Assign to
                    </p>
                    <div style={{ width: "24rem", minWidth: 160 }}>
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={assignerNameList.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleAssignerNameChange}
                          value={{
                            value: isAssignerName,
                            label: isAssignerName,
                          }}
                          placeholder="Name of the Assigner"
                        />
                      </FormControl>
                    </div>
                    <div style={{ width: "24rem", minWidth: 160 }}>
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={assignerEmailList.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleAssignerEmailChange}
                          value={{
                            value: isAssignerMail,
                            label: isAssignerMail,
                          }}
                          placeholder="Email ID of the Assigner"
                        />
                      </FormControl>
                    </div>
                    <div className="green-tab">
                      <Button
                        type="submit"
                        variant="contained"
                        className="assign-btn_style"
                        // onClick={() => setOpenDrawer(false)}
                        // onClick={handleApply}
                      >
                        {" "}
                        <p className="raleway_20px_rem">Assign Now</p>
                      </Button>
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </TableContainer>
        </Grid>

        {openCalender && (
          <ModalCalender
            openDrawer={openCalender}
            setOpenDrawer={setOpenCalender}
            handleStopDateChange={setIsStopTime}
            handleStartDateChange={setIsStartTime}
            singledaySelect={false}
            selectedDateRangeVal={[
              {
                startDate: isStartTime
                  ? isStartTime
                  : new Date(),
                endDate: isStopTime ? isStopTime : new Date(),
                key: "selection",
              },
            ]}
          />
        )}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <Paper
          sx={{
            minWidth: "400px", // Adjust the minWidth value as needed
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BoldCommonText type="Bold_700" text="Alarm Details: " />
              <p style={{ cursor: "pointer" }} onClick={handleClose}>
                <CloseIcon />
              </p>
            </div>

            {status === "ACTIVE" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "30px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <Box sx={{ minWidth: 120, mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        className="customInputLabel"
                      >
                        Email
                      </InputLabel>
                      <NativeSelect
                        placeholder="Email"
                        value={selectedData.email}
                        onChange={handleInputChange}
                        defaultValue={30}
                        inputProps={{
                          name: "email",
                          id: "email-input",
                        }}
                      >
                        {staffData.map((item, index) => {
                          return <option value={index}>{item.email}</option>;
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                  <TextField
                    value={selectedData.name}
                    margin="normal"
                    required
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    disabled={true}
                    style={{
                      width: "100%",
                      textTransform: "capitalize",
                      borderColor: "transparent !important",
                    }}
                  />
                </div>
                <TextField
                  fullWidth
                  required
                  label="Reason"
                  color={selectedData.reason === " " ? "warning" : "success"}
                  rows={10}
                  variant="standard"
                  focused
                  onChange={handleReasonChange}
                  style={{ width: "100%" }}
                />
              </div>
            ) : status === "ASSIGNED" || status === "ACKNOWLEDGED" ? (
              <CommonText type="Regular_400" text="Resolve the Alarms" />
            ) : (
              "--"
            )}
          </div>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#2A3568",
                fontSize: "10px",
                fontWeight: "700",
                textTransform: "capitalize",
                padding: "5px 10px",
                color: "white",
              }}
              size="small"
              onClick={(event) => handleAssignClick(event, status)}
            >
              {getUserRole() === "TECSTAFF"
                ? status === "ACTIVE"
                  ? "Acknowledge"
                  : status === "ASSIGNED"
                  ? "Acknowledge"
                  : status === "ACKNOWLEDGED"
                  ? "Resolve"
                  : status === "RESOLVED"
                  ? "Resolved"
                  : "--"
                : status === "ACTIVE"
                ? "Assign"
                : status === "ASSIGNED"
                ? "Resolve"
                : status === "ACKNOWLEDGED"
                ? "Resolve"
                : status === "RESOLVED"
                ? "Resolved"
                : "--"}
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "50%",
            backgroundColor: "#ffff",
          },
        }}
      >
        <Grid container className="main-grid-surge">
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            className="surges-grid-drawer"
          >
            <Paper className="second_paper">
              <p className="surge-text-drawer">
                {rowData && rowData.alarm_type
                  ? rowData.alarm_type
                      .toLowerCase()
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                  : "--"}
              </p>
            </Paper>
          </Grid>
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            container
            className="main-drawer-grid"
          >
            <Grid
              item
              lg={11.9}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="second_paper">
                <p className="Poppins_24px_rem" style={{ color: "#1B2128" }}>
                  Alarm Details
                </p>
              </Paper>
            </Grid>
            <Grid
              item
              lg={5.8}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="severity-paper-drawer">
                <div className="severity-div">
                  <p className="Poppins_14px_rem">Device Type</p>
                  <p></p>
                </div>

                <div className="severity-div">
                  <p className="Poppins_14px_rem">OEM Severity</p>
                  <p></p>
                </div>
                <div className="severity-div">
                  <p className="Poppins_14px_rem">Alarm Type</p>
                  <p></p>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              lg={5.8}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="severity-paper-drawer">
                <div className="severity-div">
                  <p className="Poppins_secondText">Inverter</p>
                  <p></p>
                </div>

                <div className="severity-div-high">
                  <p className="high-p">
                    {rowData ? rowData.alarm_severity : "--"}
                  </p>

                  <p></p>
                </div>
                <div className="severity-div">
                  <p className="Poppins_secondText">
                    {rowData ? rowData.alarm_type : "--"}
                  </p>
                  <p></p>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            container
            className="main-drawer-grid"
          >
            <Grid
              item
              lg={11.9}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="second_paper" sx={{ flexDirection: "column" }}>
                <p className="Poppins_24px_rem" style={{ color: "#1B2128" }}>
                  Acknowledgment Details
                </p>
                <p className="acknowedgeGrayText">
                  Time to acknowledge : 2024 - 03 - 06, 04:42
                </p>
              </Paper>
            </Grid>
            <Grid
              item
              lg={5.8}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="aknowedlge-paper">
                <div className="div-acknowedge">
                  <p className="acknowedgeGrayHead">Acknowledge at</p>
                  <p className="acknowedgeGrayText">
                    {rowData?.assigned_timestamp
                      ? `${rowData.assigned_timestamp.slice(
                          0,
                          10
                        )} ${rowData.assigned_timestamp.slice(11, 16)}`
                      : "--"}
                  </p>
                </div>
                <div>
                  <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/clock.svg" alt="chatbot" />{" "}
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              lg={5.8}
              xs={12}
              sm={12}
              md={11.9}
              className="transparent-grid"
            >
              <Paper className="aknowedlge-paper">
                <div className="div-acknowedge">
                  <p className="acknowedgeGrayHead">Acknowledge By</p>
                  <p className="acknowedgeGrayText">
                    {rowData ? rowData.assigned_by_name : "--"}
                  </p>
                </div>
                <div>
                  <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/iconuser.svg" alt="userprofile" />{" "}
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            className="main-drawer-grid"
          >
            <Paper className="second_paper" sx={{ flexDirection: "column" }}>
             

              <div style={styles.chatWindow} className="msgview">
                {userMessages
                  .filter((message) => message.content !== "")
                  .map((message, index) => (
                    <div key={index}>
                      {message.role === "user" ? (
                        <div style={styles.messageDiv}>
                          <div style={styles.userMessage}>
                            <p className="chatText">{message.content} </p>
                          </div>
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg"
                            alt="User Profile"
                            style={styles.profileImage}
                          />
                        </div>
                      ) : (
                        <>
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg"
                            alt="User Profile"
                            style={styles.profileImage}
                          />
                          <div style={styles.aiMessage}>
                            <p className="chatText">{message.content} </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                {aiLoader && (
                  <div style={styles.aiMessage}>
                    <p className="loader"></p>
                  </div>
                )}
              </div>

              <div className="chatTextField">
                <div style={{ width: "100%" }}>
                  <TextField
                    className="text-field"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        sendChatMessage(e.target.value);
                        e.target.value = "";
                      }
                    }}
                    placeholder={"Comment here"}
                    fullWidth
                    value={message}
                    onChange={handleMessageChange}
                    InputProps={{
                      endAdornment: message ? (
                        <IconButton onClick={() => sendChatMessage(message)}>
                          <SendOutlinedIcon
                            style={{
                              fontSize: "2.5rem",
                              color: "green",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton disabled>
                          <SendOutlinedIcon
                            style={{
                              fontSize: "2.5rem",
                              color: "gray",
                              cursor: "no-drop",
                            }}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
     
    </div>
  );
}

export default Alram;
