import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../MainLandingPage/Navbar";
import Divider from '@mui/material/Divider';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./ProductSolns.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";

function ProductSolns() {
  const location = useLocation();
  useEffect(() => {
    setTabIndex(location.state.index);
  }, [location.state]);
  const [isHovered, setIsHovered] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const DiscriptionImage = [
    {
      description:
        "Experience the future of solar power plant monitoring with our cutting-edge Fault Detection and Prediction feature, where advanced deep learning and Large Language Models converge to ensure unparalleled precision.",
      descriptionImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/faultDetection.jpg",
    },
    {
      description:
        "Dive into the future of energy management with our state-of-the-art Energy Forecasting system. Utilizing advanced deep learning models, our system achieves an exceptional accuracy rate of over 98%, revolutionizing how solar power plants anticipate and manage energy production.",
      descriptionImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/deepLeaning.jpg",
    },
  ];
  const SummaryImage = [
    {
      description:
        "Our Fault Detection and Prediction system, empowered by generative AI, is more than just a technological advancement—it's a comprehensive solution that transforms the way solar power plants operate, paving the way for a more efficient, reliable, and sustainable future in energy management.",
      descriptionImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/firstSummary.svg",
    },
    {
      description:
        "Our Energy Forecasting system with advanced deep learning models is not just a tool—it's a game-changer in solar power management. Achieving over 98% accuracy, it empowers plant operators with foresight, efficiency, and economic benefits.",
      descriptionImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/firstSummary.svg",
    },
  ];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const keyFeautureOne = [
    {
      title: "Advanced Deep Learning Models:",
      description:
        "Physics-Based Modeling: Our deep learning models are not just data-driven; they are rooted in the physical principles that govern solar power plant operations. This means they can understand and interpret complex dynamics, ensuring a deeper and more accurate analysis of equipment behavior.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/advFea1.svg",
    },
    {
      title: "Large Language Model Integration:",
      description:
        "By integrating Large Language Models fine-tuned with specific data from solar power plants, our system achieves a new level of understanding. These models not only analyze data but also generate comprehensive reports, offering insights and actionable solutions for detected faults.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/videos/Chatbot.mp4",
    },
    {
      title: "Predictive Maintenance: A Step Ahead",
      description:
        "Proactive Approach: The true power of our system lies in its predictive capabilities. By analyzing patterns and trends from historical and real-time data, it can foresee potential faults, allowing operators to take preemptive actions that minimize downtime and enhance efficiency.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/prediction.svg",
    },
    {
      title: "Auto Assignment and Fault Tracking:",
      description:
        "Streamlined Fault Resolution: Once a fault is detected, our system doesn’t just alert the operators; it automatically assigns the issue to the relevant team based on predefined criteria. The progress of fault resolution is then meticulously tracked, offering transparency and efficiency in maintenance operations.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/alarm.png",
    },
  ];
  const keyFeautureTwo = [
    {
      title: "Unmatched Accuracy and Reliability",
      description:
        "High-Precision Forecasting: Our system sets a new standard in energy forecasting with an accuracy rate surpassing 98%. By harnessing the latest in AI and deep learning, we offer predictions you can trust.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/keyFeatureOne.svg",
    },
    {
      title: "Advanced Deep Learning at Work",
      description:
        "Sophisticated AI Algorithms: At the core of our forecasting system are advanced deep learning models, trained on vast datasets to recognize complex patterns and predict energy needs with unprecedented precision.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/keyFeatureTwo.jpg",
    },
    {
      title: "Tailored to Solar Power Plants",
      description:
        "Customised for Solar Energy: Specifically designed for solar power plants, our models factor in unique variables like weather patterns, seasonal changes, and historical energy production data, ensuring highly accurate forecasts tailored to the solar industry.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/keyFeatureThree.svg",
    },
  ];
  const keyBenifits = [
    {
      description:
        "With reliable forecasts, plant operators can make more informed decisions about energy distribution, maintenance schedules, and operational strategies.",
      title: "Enhanced Decision-Making",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/productBenifitsOne.jpg",
    },
    {
      description:
        "Optimize energy production and distribution, reducing waste and maximizing output.",
      title: "Operational Efficiency",

      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/productBenifitsTwo.jpg",
    },
    {
      description:
        "Accurate forecasts lead to better resource management, minimising unnecessary expenses and maximising profitability.",
      title: "Economic Advantages",

      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/productBenifitsThree.jpg",
    },
  ];
  const ourInsights = [
    {
      title: "Proactive Fault Management",
      description:
        "Our system enables operators to tackle faults proactively, reducing the risk of major breakdowns and ensuring continuous operation.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/active_irradiance.png",
    },
    {
      title: "Operational Efficiency",
      description:
        "By minimizing downtime and enhancing maintenance practices, our software significantly boosts the overall efficiency of solar power plants.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/perf_ratio.png",
    },
    {
      title: "Cost-Effective Operations",
      description:
        "Early fault detection and predictive maintenance translate to substantial cost savings, mitigating the expenses associated with extensive repairs and energy loss.",
      keyPicture: "https://s3.amazonaws.com/smarttrak.co/landing-images/perf_overview.png",
    },
  ];

  return (
    <div className="products-container">
      <Navbar />
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: "60vh",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={{ position: "relative" }}>
          <img
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/solarPanelHome.svg"
            style={{
              width: "22vw",
              top: "8vh",
              position: "relative",
            }}
            alt="solarPanelHome"
          />
          <div
            style={{
              position: "absolute",
              top: "11vh",
              left: "4.5vw",
            }}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
              alt="small-green-star"
              style={{ position: "absolute", top: "-1vh", width: "1.4vw" }}
              className={isHovered ? "blink-green-star" : "none"}
            />
            <img
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
              alt="big-green-star"
              style={{ position: "absolute", left: "1vw", width: "3vw" }}
              className={isHovered ? "blink-green-star" : "none"}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="bold-big-text">
            POWEROPTIX.AI
            <br />{" "}
            <p className="landing-heading">
              <>
                A futuristic Generative AI tool <br /> for Fault Detection,
                Predictions & Energy
                <br /> Generation Forcasting{" "}
              </>
            </p>
          </p>
          <div style={{ position: "relative" }}>
            <svg className="pro-soln-line-one"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 679 98"
              fill="none"
            >
              <path
                className="vectorAnime"
                d="M672.807 94.6662C672.807 96.1389 674.001 97.3328 675.474 97.3328C676.947 97.3328 678.141 96.1389 678.141 94.6662C678.141 93.1934 676.947 91.9995 675.474 91.9995C674.001 91.9995 672.807 93.1934 672.807 94.6662ZM249.067 10.6125L248.796 11.0326L249.067 10.6125ZM0.125 1.53027H216.54V0.530273H0.125V1.53027ZM248.796 11.0326L364.227 85.5041L364.769 84.6638L249.338 10.1923L248.796 11.0326ZM397.026 95.1662H675.474V94.1662H397.026V95.1662ZM364.227 85.5041C374.003 91.8115 385.391 95.1662 397.026 95.1662V94.1662C385.583 94.1662 374.384 90.8669 364.769 84.6638L364.227 85.5041ZM216.54 1.53027C227.982 1.53027 239.181 4.82951 248.796 11.0326L249.338 10.1923C239.562 3.88496 228.174 0.530273 216.54 0.530273V1.53027Z"
                fill="transparent"
              />
            </svg>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            position: "relative",
            paddingBottom: "4vh",
          }}
        >
          <img
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/solarPanelWheel.svg"
            style={{ width: "22vw", position: "relative" }}
            alt="solarPanelWhell"
          />
          <div
            style={{
              position: "absolute",
              right: "6.5vw",
              top: "13vh",
            }}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
              alt="big-green-star"
              className={isHovered ? "blink-green-star" : "none"}
              style={{ width: "3vw" }}
            />
            <img
              style={{
                position: "absolute",
                width: "1vw",
                right: "-0.5vw",
                top: "-2vh",
              }}
              src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
              alt="small-green-star"
              className={isHovered ? "blink-green-star" : "none"}
            />
          </div>
        </div>

        <div>
          <svg className="pro-soln-line-two"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 98 363"
            fill="none"
          >
            <path
              className="vectorAnime"
              d="M94.3703 356.751C95.843 356.751 97.0369 357.945 97.0369 359.418C97.0369 360.891 95.843 362.084 94.3703 362.084C92.8975 362.084 91.7036 360.891 91.7036 359.418C91.7036 357.945 92.8975 356.751 94.3703 356.751ZM17.2015 76.6336L17.5643 76.2895L17.2015 76.6336ZM1.23438 0.0434635L1.23438 35.3433L0.234378 35.3433L0.234378 0.0434635L1.23438 0.0434635ZM17.5643 76.2895L78.266 140.288L77.5404 140.976L16.8387 76.9776L17.5643 76.2895ZM94.8703 181.922L94.8703 359.418L93.8703 359.418L93.8703 181.922L94.8703 181.922ZM78.266 140.288C88.9272 151.528 94.8703 166.43 94.8703 181.922L93.8703 181.922C93.8703 166.686 88.0255 152.031 77.5404 140.976L78.266 140.288ZM1.23438 35.3433C1.23438 50.5794 7.07921 65.2349 17.5643 76.2895L16.8387 76.9776C6.17744 65.7373 0.234378 50.8355 0.234378 35.3433L1.23438 35.3433Z"
              fill="transparent"
            />
          </svg>
        </div>
      </div>
      <div className="demo-text" ><p className="bold-common-text" style={{ color: "#111" }}>Product Demo</p></div>
      <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw 5vh 2.3vw" }} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="product-video-container" style={{ marginTop: "0%" }}>
          <video
            autoPlay
            controls
            loop
            style={{
              position: "absolute",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "60px",
            }}
          >
            <source src="https://s3.amazonaws.com/smarttrak.co/videos/product.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0vh 3vw",
          flexDirection: "column",
          marginBottom: "8vh"
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChangeTabIndex}
          aria-label="wrapped label tabs example"
          style={{ display: "flex" }}
        >
          <Tab
            sx={{
              padding: "0vw ! important",
              maxWidth: "45vw ! important",
            }}
            label={
              <p id="big_text"
                className="bold-big-common-text"
                style={{
                  color: tabIndex === 0 ? "#2F67F8" : "#C6C6C6",
                  textAlign: "left",
                  paddingBottom: "2vh",
                }}
              >
                Fault Detection and Prediction <br />
                with Generative AI
              </p>
            }
          />
          <Tab
            sx={{
              padding: "0vw ! important",
              maxWidth: "45vw ! important",
              marginLeft: "13vw",
            }}
            label={
              <p id="big_text"
                className="bold-big-common-text"
                style={{
                  color: tabIndex === 1 ? "#2F67F8" : "#C6C6C6",
                  textAlign: "left",
                  paddingBottom: "2vh",
                }}
              >
                Energy Forecasting with Advanced Deep Learning Models
              </p>
            }
          />
        </Tabs>
        <Divider style={{ border: "0.5px solid #E6E6E6" }} />

        <div className="model-cards-container">
          <div
            className="discrptionImage"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <p id="auto-width-margin"
              className="semibold-24px_500"
              style={{ fontWeight: "600", color: "#111", width: "41vw", marginTop: "-5vh" }}
            >
              {DiscriptionImage[tabIndex].description}
            </p>
            <div className="key-picture"
              style={{
                position: "relative",
                backgroundColor: "#F1C0B2",
                borderRadius: "1.5vw 1.5vw 1.5vw 11vw ",
                width: "35vw",
              }}
            >
              <img className="key-picture"
                src={DiscriptionImage[tabIndex].descriptionImg}
                alt="our-faults"
                style={{
                  borderRadius: "1.5vw 1.5vw  1.5vw 11vw",
                  width: "35vw",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "-3vh",
                  right: "0vw",
                }}
              >
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                  alt="big-green-star"
                  className={isHovered ? "blink-green-star" : "none"}
                  style={{ width: "3vw" }}
                />
                <img
                  style={{ position: "absolute", width: "1vw" }}
                  src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                  alt="small-green-star"
                  className={isHovered ? "blink-green-star" : "none"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="model-cards-container">
          <div className="discrptionImage">
            <p className="bold-common-text" style={{ color: "#111" }}>
              Key Features
            </p>
            <p id="auto-width"
              className="regular-common-text"
              style={{ width: "36vw", color: "#111", textAlign: "justify" }}
            >
              Discover seamless integration and unmatched efficiency through our
              product's advanced technology. Our user-centric design and robust
              functionality redefine convenience and reliability.
            </p>
          </div>
          <Divider
            style={{ border: "0.5px solid #E6E6E6", margin: "3vh 0vw" }}
          />

          {tabIndex === 0
            ? keyFeautureOne.map((item, index) => (
              <div className="key-cards-container" key={index}>
                <div
                  className="keydiscrptionImage"
                  style={{
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  }}
                >
                  <div>
                    <p id="auto-width"
                      className="semibold-24px_500"
                      style={{
                        fontWeight: "600",
                        color: "#252525",
                        width: "25vw",
                      }}
                    >
                      {item.title}
                    </p>
                    <p id="auto-width"
                      className="regular-common-text"
                      style={{
                        color: "#111",
                        width: "24vw",
                        marginTop: "2vh",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      backgroundColor:
                        index % 2 === 0 ? "#D3DFFF" : "#FFCEDC",
                      borderRadius: "1.5vw",
                      width: "35vw",

                    }}
                    className="key-picture"
                  >
                    {index === 1 ? <video autoPlay muted loop style={{

                      width: "100%",
                      height: "100%",
                      borderRadius: "1.5vw",
                      objectFit: "cover",
                    }} className="product-video">
                      <source src={item.keyPicture} type="video/mp4" />

                    </video> : <img className="key-picture"
                      src={item.keyPicture}
                      alt="our-faults"
                      style={{
                        borderRadius: "1.5vw",
                        backgroundColor: "#f3f7f9",
                        padding: "1vw",
                        width: "35vw",
                      }}
                    />}
                  </div>
                </div>
              </div>
            ))
            : keyFeautureTwo.map((item, index) => (
              <div className="key-cards-container" key={index}>
                <div
                  className="keydiscrptionImage"
                  style={{
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  }}
                >
                  <div>
                    <p
                      className="semibold-24px_500"
                      style={{
                        fontWeight: "600",
                        color: "#252525",
                        width: "25vw",
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      className="regular-common-text"
                      style={{
                        color: "#111",
                        width: "24vw",
                        marginTop: "2vh",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      backgroundColor:
                      index % 2 === 0 ? "#D3DFFF" : "#FFCEDC",
                    borderRadius: "1.5vw",
                    width: "35vw",
                    }}
                  >
                    <img
                      src={item.keyPicture}
                      alt="our-faults"
                      style={{
                        borderRadius: "1.5vw",
                        backgroundColor: "#f3f7f9",
                        padding: "1vw",
                        width: "35vw",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="model-cards-container">
          {tabIndex === 0 ? (
            <>
              <div className="discrptionImage">
                <p className="bold-common-text" style={{ color: "#111" }}>
                  Maximising Operational Insights
                </p>
                <p id="auto-width"
                  className="regular-common-text"
                  style={{ width: "36vw", color: "#111", textAlign: "justify" }}
                >
                  Discover seamless integration and unmatched efficiency through
                  our product's advanced technology. Our user-centric design and
                  robust functionality redefine convenience and reliability.
                </p>
              </div>
              <Divider
                style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }}
              />

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4vh",
                }}
              >
                {ourInsights.map((item, index) => (
                  <Grid className="insight-container"
                    item
                    lg={11.9}
                    xs={12}
                    sm={12}
                    md={11.9}
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#111 ! important",
                      borderRadius: "1.5vw ! important",
                      padding: "6vh 3vw",
                      gap: "3vw",
                      boxShadow: "none ! important",

                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                    }}
                  >
                    <Paper

                      sx={{

                        height: "40vh",
                        backgroundColor: "#111 ! important",
                      }}
                    >
                      <img className="insight-img"
                        src={item.keyPicture}
                        alt="our-faults"
                        style={{
                          height: "40vh",
                          background: "white",
                          width: "42vw",
                          borderRadius: "1.5vw",
                        }}
                      />
                    </Paper>
                    <Paper
                      sx={{
                        height: "40vh",

                        backgroundColor: "#2F67F8 ! important",
                        width: "100% ! important",
                        textAlign: index % 2 === 0 ? "left" : "right",
                        borderRadius:
                          index % 2 === 0
                            ? "1.5vw 1.5vw 11vw 1.5vw ! important"
                            : "1.5vw 1.5vw 1.5vw 11vw ! important",
                        padding: "6vh 3vw",
                      }}
                    >
                      <p id="auto-width"
                        className="semibold-24px_500"
                        style={{
                          fontWeight: "600",
                          width: index === 1 ? "auto" : "30vw",
                        }}
                      >
                        {item.title}
                      </p>
                      <Divider
                        style={{
                          border: "0.5px solid #878787",
                          margin: "3vh 0vw",
                        }}
                      />

                      <p id="auto-width"
                        className="regular-common-text"
                        style={{ width: index === 1 ? "auto" : "31vw", marginTop: "2vh" }}
                      >
                        {item.description}
                      </p>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <div
              style={{
                backgroundColor: "#111 ",
                borderRadius: "1.5vw",
                padding: "6vh 3vw",
              }}
            >
              <div className="discrptionImage">
                <p className="bold-common-text">Benefits</p>
                <p
                  className="regular-common-text"
                  style={{ width: "36vw", textAlign: "justify" }}
                >
                  Discover seamless integration and unmatched efficiency through
                  our product's advanced technology. Our user-centric design and
                  robust functionality redefine convenience and reliability.
                </p>
              </div>
              <Divider
                style={{ border: "0.5px solid #656565", margin: "4vh 0vw" }}
              />
             
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2vw",
                  backgroundColor: "#111 ! important",
                }}
              >
                {keyBenifits.map((item, index) => (
                  <Grid
                    item
                    lg={3.8}
                    xs={12}
                    sm={5.8}
                    md={3.8}
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        index % 2 === 0
                          ? "#212121 ! important"
                          : " #2F67F8 ! important",
                      gap: "1vw",
                      boxShadow: "none ! important",
                      borderRadius: "1.5vw 1.5vw 13vw 1.5vw",
                    }}
                  >
                    <Paper
                      sx={{
                        backgroundColor:
                          index % 2 === 0
                            ? "#212121 ! important"
                            : " #2F67F8 ! important",

                        width: "100% ! important",
                        borderRadius: "1.5vw 1.5vw 14vw 1.5vw",
                        padding: "6vh 3vw",
                        boxShadow: "none ! important",
                      }}
                    >
                      <p
                        className="semibold-24px_500"
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {item.title}
                      </p>
                      <Divider
                        style={{
                          border: "0.5px solid #878787",
                          margin: "3vh 0vw",
                        }}
                      />

                      <p
                        className="regular-common-text"
                        style={{ marginTop: "2vh", height: "15vh" }}
                      >
                        {item.description}
                      </p>
                      <img
                        src={item.keyPicture}
                        alt="our-faults"
                        style={{
                          width: "100%",
                          marginTop: "3vh",
                          borderRadius: "1.5vw 1.5vw 10vw 1.5vw",
                        }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </div>
        <div className="model-cards-container">
          <div className="discrptionImage">
           

          </div>
          <Divider
            style={{ border: "0.5px solid #E6E6E6", margin: "3vh 0vw", marginBottom: "6vh" }}
          />
          <div
            className="discrptionImage"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <p id="auto-width-margin"
              className="semibold-24px_500"
              style={{ fontWeight: "600", color: "#111", width: "41vw", marginTop: "-5vh" }}
            >
              {SummaryImage[tabIndex].description}
            </p>
            <div id="auto-width"
              style={{
                position: "relative",
                backgroundColor: "#EBEAF9",
                borderRadius: "1.5vw 1.5vw 1.5vw 11vw ",
                width: "35vw",
              }}
            >
              <img className="insight-img"
                src={SummaryImage[tabIndex].descriptionImg}
                alt="our-faults"
                style={{
                  borderRadius: "1.5vw 1.5vw  1.5vw 11vw",
                  width: "35vw",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "-3vh",
                  right: "0vw",
                }}
              >
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                  alt="big-green-star"
                  className={isHovered ? "blink-green-star" : "none"}
                  style={{ width: "3vw" }}
                />
                <img
                  style={{ position: "absolute", width: "1vw" }}
                  src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                  alt="small-green-star"
                  className={isHovered ? "blink-green-star" : "none"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProductSolns;
