import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import "./Trackers.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AngleDrawer from "./AngleDrawer";
import { TextField, InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import WeatherBoard from "../../../Common/WeatherBoard";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import ModalCalender from "../../../Common/ModalCalender";
import TrackerDrawer from "./TrackerDrawer";
import useErrorHandler from "../../../../utils/errorHandler";
import TimestampFormatter from "../../../Common/TimestampFormatter";
import ButtonSlider from "../../../Common/ButtonSlider";
const invService = new CosmicInverterService();

function Trackers() {
  const location = useLocation();
  const [trackerId, setTrackerId] = useState(location.state?.trackerId);
  const globeRef = useRef(null);
  const [weatherInfoData, setWeatherInfoData] = useState({});
  const intervalRef = useRef(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [trackerFieldsData, setTrackerFieldsData] = useState({});
  const [eachTrackerInfo, setEachTrackerInfo] = useState({});
  const [trackersList, setTrackersList] = useState([]);
  const [deviceDatas, setDeviceDatas] = useState({});
  const [loadingState, setLoadingState] = useState({
    trackerRequest: false,
    fetchData: false,
    weatherStatus: false,
    updateTracker: false,
    eachTrackerData: false,
    trackerList: false,
  });
  const [tabValue, setTabValue] = useState("Location Setup");
  const [trackerAngle, setTrackerAngle] = useState(20); // Default tracker angle
  const [activeIndex, setActiveIndex] = useState(null);
  const [isManual, setIsManual] = useState(false);
  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const { enqueueSnackbar } = useSnackbar();
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const history = useHistory();
  const totalPages = Math.ceil(trackersList.length / itemsPerPage);
  const handleFetchError = useErrorHandler();

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const startIndex = currentPage * itemsPerPage;
  let visibleTrackers = trackersList.slice(startIndex, startIndex + itemsPerPage);
  // Insert "Trackers Dashboard" as the first item of the visibleTrackers list
  if (currentPage === 0) {
    visibleTrackers = [
      {
        NodeID: "Trackers Dashboard",
      },
      ...visibleTrackers,
    ];
  }

  const id = plantIdsArray[0];
  useEffect(() => {
    if (trackerId !== undefined) {
      const fetchTrackerData = () => {
        handleEachTrackerData(trackerId);
      };
      fetchTrackerData();
      const intervalId = setInterval(fetchTrackerData, 120000);// 120000 milliseconds = 2 minutes
      return () => clearInterval(intervalId)
    }
  }, [trackerId, isManual])

  useEffect(() => {
    handleWeatherInfo(id);
    handleTrackersList()
  }, []);
  const weatherInfo = [
    {
      title: "Sun Angle",
      temp: eachTrackerInfo?.Orientation?.Sun_Angle ? `${eachTrackerInfo?.Orientation?.Sun_Angle}°` : "--",

      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/sun_angle.svg",
    },
    {
      title: "Tracker Angle",
      temp: eachTrackerInfo?.Orientation?.Tracker_Angle ? `${eachTrackerInfo?.Orientation?.Tracker_Angle}°` : "--",
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_angle.svg",
    },
    {
      title: "Target Tilt",
      temp: eachTrackerInfo?.Orientation?.Target_Tilt ? `${eachTrackerInfo?.Orientation?.Target_Tilt}°` : "--",
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_mode.svg",
    },
    {
      title: "Motor Status",

      temp: "--",
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/motor_status.svg",
    },
  ];

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    const pointOfViewFunction = ({ latitude, longitude, altitude }) => {
      if (globeRef.current) {
        globeRef.current.pointOfView({ latitude, longitude, altitude });
      }
    };

    if (globeRef.current) {
      globeRef.current.pointOfView = pointOfViewFunction;
    }
  }, [globeRef]);

  const checkList = [
    {
      title: "Controller",
      name: "SMT01[Q3]",
      status: "Good",
    },
    {
      title: "Inclinometer",
      name: "SMT01[Q3]",
      status: "Good",
    },
    {
      title: "Motor",
      name: "SMT01[Q3]",
      status: "Poor",
    },
    {
      title: "VFD",
      name: "SMT01[Q3]",
      status: "Poor",
    },
    {
      title: "Actuator",
      name: "SMT01[Q3]",
      status: "Good",
    },
  ];

  const handleChange = (newValue) => {
    setTabValue(newValue.tabName)
  };

  const trackerStatus = [
    {
      title: "Sun Angle",
      value: eachTrackerInfo?.Orientation?.Sun_Angle || "--",
    },
    {
      title: "Tracker Angle",
      value: eachTrackerInfo?.Orientation?.Tracker_Angle || "--",
    },
    {
      title: "Tracker Mode",
      value: "Auto West",
    },
    {
      title: "Motor Status",
      value: "Stop",
    },
  ];

  const controlCenter = [
    {
      image: "https://s3.amazonaws.com/smarttrak.co/v2Images/control2.svg",
      name: "Automatic",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/v2Images/control3.svg",
      name: "Sun Track Start",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/v2Images/control4.svg",
      name: "Manual",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_stop_icon.svg",
      name: "Stop",
    },
  ];

  const navTabsList = [
    {
      tabName: "Location Setup"
    },
    {
      tabName: "Status"
    },
    {
      tabName: "Control"
    },
    {
      tabName: "Check List"
    },
    {
      tabName: "Trackers Setting"
    },
  ]

  let fetchTimeout = null;
  const handleWeatherInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherStatus: true }));

      const res = await invService.getWeatherInfo(id);
      if (res.status === 200) {
        const timestamp = res.data.dt * 1000;
        const date = new Date(timestamp);

        const month = date.toLocaleString("default", { month: "long" });
        const dayAbbreviation = date.toLocaleString("en-US", {
          weekday: "long",
        });
        setWeatherInfoData({
          weatherConditions: res.data.weather[0].main,
          weatherUrl: res.data.weather[0].icon,
          temperature: res.data.main.feels_like,
          airTemperature: res.data.main.temp,

          minTemp: res.data.main.temp_min,
          maxTemp: res.data.main.temp_max,
          humidity: res.data.main.humidity,
          windSpeed: res.data.wind.speed,
          day: dayAbbreviation,
          date: date.getDate(),
          month: month,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, weatherStatus: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherStatus: false }));
      handleFetchError(error, "CUF AC Data");
    }
  };
  const handleTrackersList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, trackerList: true }));
      const res = await invService.getTrackerLists();
      if (res.status === 200) {
        setTrackersList(res.data.nodes);
        const formattedData = res.data.nodes
          .map((item) => ({
            device_name: item?.NodeID ? item.NodeID : "--",
          }));

        setDeviceDatas([{
          device_name: "Trackers Dashboard",
        },
        ...formattedData])
      }
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
      handleFetchError(error, "Trackers List");
    }
  };

  const handleEachTrackerData = async (node_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, eachTrackerData: true }));
      const res = await invService.getEachTrackerData(node_id);

      if (res.status === 200) {
        if (res.data.error) {
         
        } else {
          setEachTrackerInfo(res.data)
          setTrackerAngle(parseFloat(res.data.Orientation?.Tracker_Angle))
        }
      }
      setLoadingState((prevState) => ({ ...prevState, eachTrackerData: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, eachTrackerData: false }));
      handleFetchError(error, "Tracker Data");
    }
  };

  const handleTrackerRequest = async (node_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, trackerRequest: true }));
      const res = await invService.getTrackerRequest(node_id);
      if (res.status === 200) {
        // Delay setting isRequested to true by 7 seconds (10000 ms)
        setTimeout(() => {
          setIsRequested(true);
          setLoadingState((prevState) => ({ ...prevState, trackerRequest: false }));
          handleFetchData(node_id);

        }, 7000);

        enqueueSnackbar(
          res?.data
            ? res?.data.details
            : res?.data.details,
          {
            variant: "success",
          }
        );

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, trackerRequest: false }));
      handleFetchError(error, "Trackers List");
    }
  }
  const handleFetchData = async (node_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fetchData: true }));
      setMessage("")
      const res = await invService.getFetchTrackerData(node_id);
      let foundRes = false;
      if (res.status === 200) {
        if ("Res" in res.data) {
          setIsReading(false);
          setIsFetchedAll(true)
          foundRes = true;
        } else {
          setIsReading(true);
          setMessage("Please wait for 20 seconds device is still reading.....")
        }

        setTrackerFieldsData(res.data)
      }
      setLoadingState((prevState) => ({ ...prevState, fetchData: false }));
      return foundRes;
    } catch (error) {
      setMessage(error?.response?.data?.detail)
      setLoadingState((prevState) => ({ ...prevState, fetchData: false }));
      handleFetchError(error, "Tracker fields data");
    }
  }

  useEffect(() => {
    if (isRequested && trackerId) {
      // Clear any previous timeouts when switching node_id
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
    }

    // Cleanup when node_id or isRequested changes
    return () => {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
    };
  }, [isRequested, trackerId]);
  const handleUpdateData = async (section) => {
    const reqData =
    {
      NodeID: trackerId,
      Res: "update",
      [section]: trackerFieldsData[section]
    }
    try {
      setLoadingState((prevState) => ({ ...prevState, updateTracker: true }));
      const res = await invService.updateTrackerData(reqData);

      if (res.status === 200) {
        enqueueSnackbar(
          res?.data
            ? res?.data.details
            : res?.data.details,
          {
            variant: "success",
          }
        );
        setLoadingState((prevState) => ({ ...prevState, updateTracker: false }));

      }
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, updateTracker: false }));
      handleFetchError(err, "update tracker data");
    }
  };
  const renderFields = (fields, section) => {
    return Object.keys(fields).map((key, index) => (
      <Grid item lg={2.5} sm={2.5} xs={2.5} md={2.5} key={index} className="timestamp">
        <p className="raleway_16px_rem" style={{ color: "black", fontWeight: "700", textTransform: "capitalize" }}>
          {key}
        </p>
        <TextField
          className="textfield_tracker"
          value={trackerFieldsData[section][key]}
          onChange={(e) => setTrackerFieldsData((prevData) => ({
            ...prevData,
            [section]: {
              ...prevData[section],
              [key]: e.target.value
            }
          }))}
          fullWidth
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       <img src={`icons/${key}_icon.png`} alt={key} /> {/* Assuming icons are named after the keys */}
        //     </InputAdornment>
        //   ),
        // }}
        />
      </Grid>
    ));
  };
  const renderFieldsThree = (fields, sectionName) => {
    if (!fields) return null;
    return Object.keys(fields).map((key, index) => (
      <Grid item lg={2.5} sm={2.5} xs={2.5} md={2.5} className="timestamp" key={`${sectionName}_${key}_${index}`}>
        <p className="raleway_16px_rem" style={{ color: "black", fontWeight: "700", textTransform: "capitalize" }}>
          {key}
        </p>
        <TextField
          className="textfield_tracker"
          value={fields[key]}
          disabled
          fullWidth
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       <img
        //         src={getIconForKey(key)}
        //         alt="icon"
        //       />
        //     </InputAdornment>
        //   ),
        // }}
        />
      </Grid>
    ));
  };
  const renderIncFields = (fields, sectionName) => {
    if (!fields || typeof fields !== 'object') return null; // Check if fields is null, undefined, or not an object

    return Object.keys(fields).map((key, index) => {
      if (key === "Register") {
        return Object.keys(fields[key]).map((registerKey, registerIndex) => (
          <Grid item lg={2.5} sm={5.9} xs={5.9} md={2.5} className="timestamp" key={`${sectionName}_${key}_${registerKey}_${registerIndex}`}>
            <p className="raleway_16px_rem" style={{ color: "black", fontWeight: "700", textTransform: "capitalize" }}>
              {registerKey}
            </p>
            <TextField
              className="textfield_tracker"
              value={fields[key][registerKey]}
              disabled
              fullWidth
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <img
            //         src={getIconForKey(registerKey)} 
            //         alt="icon"
            //       />
            //     </InputAdornment>
            //   ),
            // }}
            />
          </Grid>
        ));
      }
      return (
        <Grid item lg={2.5} className="timestamp" key={`${sectionName}_${key}_${index}`}>
          <p className="raleway_16px_rem" style={{ color: "black", fontWeight: "700", textTransform: "capitalize" }}>
            {key}
          </p>
          <TextField
            className="textfield_tracker"
            value={fields[key]}
            disabled
            fullWidth
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <img
          //         src={getIconForKey(key)}
          //         alt="icon"
          //       />
          //     </InputAdornment>
          //   ),
          // }}
          />
        </Grid>
      );
    });
  };

  const getIconForKey = (key) => {
    switch (key) {
      case "latitude":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/mdi_longitude.svg";
      case "longitude":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/lattitude_svg.svg";
      case "altitude":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/lattitude_svg.svg";
      case "timezone":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/calender_svg.svg";
      default:
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/alram_svg.svg";
    }
  };

  const convertBuildDate = (buildDate) => {
    if (!buildDate) return '';

    const [hours, minutes, seconds, day, month, year] = buildDate.split(':');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  const handleTrackerDetails = (tracker, id, index) => {
    if (id === "Trackers Dashboard") {
      history.push("/iot-home/dev-monitoring/trackers")
    } else {
      // setTrackerId(id)
      // handleEachTrackerData(id);
      if (trackerId !== id) {
        // Reset states before fetching new tracker data
        setErrMessage('');        // Clear any previous error messages
        setIsRequested(false);    // Reset the request status
        setIsReading(false);      // Reset reading state
        setIsFetchedAll(false);   // Reset fetch all state
        setMessage('');           // Clear any messages
        setTrackerFieldsData({}); // Clear tracker data

        setTrackerId(id);         // Set new tracker ID
        handleEachTrackerData(id); // Fetch new tracker data
      }
    }
  };


  const handleControlClick = (index, angle) => {
    switch (index) {
      case 0:
        setIsManual(false)
        setTrackerAngle(parseFloat(eachTrackerInfo?.Orientation?.Target_Tilt))
        break;
      case 1:
        setIsManual(false)
        handleEachTrackerData(trackerId);
        break;
      case 2:
        setIsManual(true);
        setTrackerAngle(angle)
        break;
      case 3:
        setIsManual(false)
        break;
    }
    setActiveIndex(index)
  }
  return (
    <div
      className="home-container"
      style={{ padding: "0vh 1vh 1vh 1vh" }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Grid
          container
          sx={{
            backgroundColor: "#f4f4f4",
            position: 'sticky',
            top: 0,
            zIndex: 10,
            paddingTop: "1vh",
            flexDirection: 'column', // Ensure the two inner grids stack vertically
          }}
        >
          <Grid
            container
            className="inv_names_container"
            sx={{
              padding: "1rem !important", backgroundColor: "white !important",
              border: "0.0625rem solid #D8D8D8 !important", borderRadius: "0.75rem !important",
            }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="only_flex-start"
            >
              <Grid
                container
                className="inv-names_grid"
              >
                {trackersList.length > 0 ? (
                  <>
                    <ButtonSlider 
                    isValueData={visibleTrackers} 
                    deviceId={trackerId} 
                    handleInvDeatil={handleTrackerDetails} 
                    itemsPerPage={itemsPerPage} />
                  </>
                ) : (
                  <div className="no-data">
                    <p className="Poppins_16px_rem" style={{ fontWeight: '400' }}>
                      No data is available!
                    </p>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className="inv_names_container"
            sx={{
              padding: "1rem !important", backgroundColor: "white !important",
              border: "0.0625rem solid #D8D8D8 !important", borderRadius: "0.75rem !important",
              marginTop: "0.5rem",
            }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="only_flex-start"
            >
              <Grid
                container
                className="inv-names_grid"
              >
                {navTabsList.length > 0 ? (
                  navTabsList.map((ele, index) => (<Grid
                    item
                    key={index}
                    lg={2.2}
                    xs={12}
                    sm={5.8}
                    md={2.9}
                    onClick={() => handleChange(ele)}
                    sx={{ flex: "0 0 auto", width: "auto" }}
                    className="flex-start_gap"
                  >
                    <Paper
                      className="inv-names_paper"
                      sx={{
                        backgroundColor: tabValue === ele.tabName ? "#2F67F8" : "#ffff",
                        border: "0.0625rem solid #D8D8D8 !important"
                      }}
                    >
                      <div className="inv-names_div" style={{
                        justifyContent: "center",
                        backgroundColor: tabValue === ele.tabName ? "#2F67F8" : "#ffff",
                      }}>
                        <p className="Poppins_18px_rem" style={{ color: tabValue === ele.tabName ? "#ffff" : "rgb(47, 103, 248)" }}>
                          {ele.tabName}</p>
                      </div>
                    </Paper>
                  </Grid>

                  ))

                ) : (
                  <div className='no-data'>
                    <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>No data is available!</p>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TabContext value={tabValue}>

          <TabPanel className="tab_panel" value="Location Setup">
            <Grid
              container className="abt-grid-container nogap"
            >
              <Grid item lg={9.6} xs={12} sm={12} md={12} className="scrolling-tracker">
                {errMessage ? <p className="raleway_16px_rem" style={{ color: "red" }}>{errMessage}</p> : <Grid container className="tracker-container" sx={{ position: "relative" }}>
                  {/* Time Stamp Section */}
                  {eachTrackerInfo.BuildDate && <Grid item lg={11.9} sm={11.9} xs={11.9} md={11.9} className="cube_row">
                    <Paper className="location_paper">
                      <p className="raleway_24px_rem" style={{ color: "#2F67F8" }}>
                        Time Stamp
                      </p>
                      <div className="timestamp_input_container">
                        <div className="timestamp">
                          <p className="raleway_16px_rem" style={{ color: "black", fontWeight: "700" }}>
                            Build Date
                          </p>
                          <TextField
                            className="textfield_tracker"
                            value={convertBuildDate(eachTrackerInfo.BuildDate) || ''}
                            disabled
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/calender_svg.svg"
                                    alt="icon"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Paper>
                  </Grid>}

                  {/* Other dynamic sigle object data */}
                  {Object.keys(eachTrackerInfo).filter(key => key !== 'NodeID' && key !== 'IncSensor' && key !== 'BuildDate').map((section, index) => (
                    <Grid key={index} item lg={11.9} sm={11.9} xs={11.9} md={11.9} className="cube_row">
                      <Paper className="location_paper">
                        <p className="raleway_24px_rem" style={{ color: "#2F67F8" }}>{section}</p>
                        <Grid container className="timestamp_input_container">
                          {renderFieldsThree(eachTrackerInfo[section], section)}
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}

                  {/*IncSensor nested object */}
                  {Object.keys(eachTrackerInfo).filter(key => key === 'IncSensor').map((section, index) => (
                    <Grid key={index} item lg={11.9} sm={11.9} xs={11.9} md={11.9} className="cube_row">
                      <Paper className="location_paper">
                        <p className="raleway_24px_rem" style={{ color: "#2F67F8" }}>{section}</p>
                        <p className="raleway_24px_rem" style={{ color: "#2F67F8" }}>{Object.keys(eachTrackerInfo[section])} :</p>
                        <Grid container className="timestamp_input_container">
                          {renderIncFields(eachTrackerInfo[section], section)}
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                  {loadingState.eachTrackerData && (
                    <SimpleBackdrop open={loadingState.eachTrackerData} />
                  )}
                </Grid>}
              </Grid>

              <Grid
                item
                lg={2.3}
                xs={12}
                sm={12}
                md={12}
                className="tracker_weather_grid"
              >
                <Paper
                  className="notification-paper-one"
                >
                  <WeatherBoard
                    weatherParam={weatherInfoData}
                    weatherData={weatherInfo}
                    title="Trackers Status"
                    weaHeight="72vh"
                  />
                </Paper>
                {loadingState.weatherStatus && (
                  <SimpleBackdrop open={loadingState.weatherStatus} />
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel className="tab_panel" value="Status">
            <Grid className="abt-grid-container nogap"
              container

            >
              <Grid
                item
                lg={9.6}
                xs={12}
                sm={12}
                md={12}

                className="scrolling-tracker"
              >
                <Grid
                  container className="tracker-container"

                >
                  {trackerStatus.map((item, index) => {
                    return (
                      <Grid
                        item
                        lg={5.5}
                        sm={11.9}
                        xs={11.9}
                        md={5.75} className="cube_row"

                      >
                        <Paper className="tracker_paper_container"
                          key={index}

                        >
                          <div
                            className="cubeone"

                          >
                            <p
                              className="raleway_24px_rem"
                            >
                              {item.title}
                            </p>
                            <p className="Poppins_24px_rem" style={{ color: "black" }}>{item.value}</p>
                          </div>
                          {index === 0 ? (
                            <div style={{ position: "relative" }}>
                              <AngleDrawer sunAngle={eachTrackerInfo?.Orientation?.Sun_Angle ? `${eachTrackerInfo?.Orientation?.Sun_Angle}` : null} trackerAngle={null} />

                              {loadingState.eachTrackerData && (
                                <SimpleBackdrop open={loadingState.eachTrackerData} />
                              )}
                            </div>
                          ) : index === 1 ? (
                            <div style={{ position: "relative" }}>
                              <AngleDrawer sunAngle={null} trackerAngle={eachTrackerInfo?.Orientation?.Tracker_Angle ? `${eachTrackerInfo?.Orientation?.Tracker_Angle}` : null} />

                              {loadingState.eachTrackerData && (
                                <SimpleBackdrop open={loadingState.eachTrackerData} />
                              )}
                            </div>
                          ) : index === 2 ? (
                            <div className="tracker_status_container">
                              <img
                                src="https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_status_mode.svg"
                                className="tracker_status_img"
                                alt="tracker"
                              />
                            </div>
                          ) : (
                            <div className="motor_status_container">
                              <p
                                className="Poppins_36px_rem stop_tracker"

                              >
                                Stop
                              </p>
                              <img
                                className="motor_status_img"
                                src="https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_motor_status.svg"
                                alt="dot"
                              />
                            </div>
                          )}
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                item
                lg={2.3}
                xs={12}
                sm={12}
                md={12}
                className="tracker_weather_grid"

              >
                <Paper className="notification-paper-one"

                >
                  <WeatherBoard
                    weatherParam={weatherInfoData}
                    weatherData={weatherInfo}
                    title="Trackers Status"
                    weaHeight="72vh"
                  />
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel className="tab_panel" value="Control">
            <div className="tracker_control_container">
              <div style={{ position: "relative" }}>
                <TrackerDrawer
                  sunAngle={eachTrackerInfo?.Orientation?.Sun_Angle}
                  trackerAngle={trackerAngle}
                  isManual={isManual}
                  handleControlClick={(index, angle) => handleControlClick(index, angle)}
                />
                {loadingState.eachTrackerData && (
                  <SimpleBackdrop open={loadingState.eachTrackerData} />
                )}
              </div>
              <div className="control_center">
                <p className="raleway_24px_rem" style={{ color: "white" }}>
                  Control Center
                </p>
                <div className="control_container">
                  {controlCenter.map((item, index) => {
                    return (
                      <><div className="control_buttons" style={{
                        backgroundColor: activeIndex === index ? 'rgba(197, 179, 179, 0.57)' : 'transparent'
                      }} key={index} onClick={() => handleControlClick(index)}>
                        <div className="control-btn-img">
                          <img src={item.image} className="control_img" />
                        </div>
                        <p
                          className="raleway_16px_rem"
                          style={{ fontWeight: "700", textAlign: "center" }}
                        >
                          {item.name}
                        </p>
                      </div>
                      </>
                    );
                  })}

                </div>

              </div>

            </div>
          </TabPanel>
          <TabPanel className="tab_panel" value="Check List">

            <Grid className="abt-grid-container"
              container

            >
              <Grid
                item
                lg={9.6}
                xs={12}
                sm={12}
                md={12}

                className="scrolling-tracker"
              >
                <Grid
                  container className="checklist_grid"
                  sx={{ marginRight: "0.5rem" }}

                >
                  {checkList.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      lg={2.87}
                      xs={12}
                      sm={12}
                      md={2.87}
                      className="checklist_grid_sub"

                    >
                      <Paper className="checklist_paper_sub"

                      >
                        <p
                          className="raleway_18px_rem"
                          style={{ fontWeight: "700" }}
                        >
                          {item.title}
                        </p>
                        <p
                          className="Poppins_18px_rem"
                          style={{ fontWeight: "400" }}
                        >
                          {item.name}
                        </p>
                        <div
                          className="emoji_button"
                          style={{
                            backgroundColor:
                              item.status === "Poor" ? "#FF472E" : "#90E900",
                          }}
                        >
                          <p
                            className="raleway_14px_rem"
                            style={{
                              fontWeight: "700",
                              color: item.status === "Poor" ? "#ffff" : "black",
                            }}
                          >
                            {item.status}
                          </p>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                lg={2.3}
                xs={12}
                sm={12}
                md={12}
                className="tracker_weather_grid"


              >
                <Paper className="notification-paper-one"

                >
                  <WeatherBoard
                    weatherParam={weatherInfoData}
                    weatherData={weatherInfo}
                    title="Trackers Status"
                    weaHeight="72vh"
                  />
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel className="tab_panel" value="Trackers Setting">
            <Grid className="abt-grid-container"
              container

            >
              <Grid
                item
                lg={9.6}
                xs={12}
                sm={12}
                md={9}
                sx={{ position: "relative" }}
                className="scrolling-tracker"
              >
                <Paper className="tracker_paper_container" sx={{ alignItems: "flex-start", border: "none !important", backgroundColor: "transparent !important" }}>
                  <div className="space-btn-center">
                    <div className="tracker_time">
                      <p className="raleway_24px_rem">Tracker Status</p>
                      {trackerFieldsData?.last_active &&
                        <div className="updated_time_container" style={{ padding: "1rem" }}>
                          <p className="raleway_14px_rem">
                            Last Updated on :
                          </p>
                          <p className="Poppins_18px_rem">
                            {trackerFieldsData?.last_active
                              ? <TimestampFormatter dateString={trackerFieldsData?.last_active}/>
                              : ""}
                          </p>



                        </div>}
                    </div>
                  </div>
                  <div className="request_container">
                    {!isFetchedAll && <div
                      className="emoji_button"
                      style={{
                        alignSelf: "flex-start",
                        backgroundColor: "#90E900", cursor: "pointer",
                        // display: isFetchedAll ? "none" : "block"
                      }}
                      onClick={() => handleTrackerRequest(trackerId)}
                    >
                      <p
                        className="raleway_14px_rem"
                        style={{
                          fontWeight: "700",
                          color: "black",
                        }}
                      >
                        Request
                      </p>
                    </div>}
                  </div>
                  {isReading ?
                    <div className="error-container">
                      <p className="Poppins_16px_rem" style={{ color: "red" }}>{message}</p>
                      </div>
                    : <Grid
                      container className="abt-grid-container nogap"
                      sx={{ padding: "1rem 0rem" }}
                    >

                      <Grid container className="tracker-container">
                        {Object.keys(trackerFieldsData).filter(key => key !== 'NodeID' && key !== 'CMD' && key !== 'Res' && key !== 'last_active').map((section, index) => (
                          <Grid key={index} item lg={11.9} sm={11.9} xs={11.9} md={11.9} className="cube_row">
                            <Paper className="location_paper">
                              <p className="raleway_24px_rem" style={{ color: "#2F67F8" }}>{section}</p>
                              <Grid container className="timestamp_input_container">
                                {renderFields(trackerFieldsData[section], section)}
                              </Grid>
                              <div className="compare_button"
                                onClick={() => handleUpdateData(section)}
                                style={{ marginTop: '10px', color: "white" }}
                              >
                                Send
                              </div>
                            </Paper>
                            {loadingState.updateTracker && (
                              <SimpleBackdrop open={loadingState.updateTracker} />
                            )}
                          </Grid>
                        ))}
                      </Grid>

                    </Grid>
                  }
                </Paper>
                {loadingState.trackerRequest && (
                  <SimpleBackdrop open={loadingState.trackerRequest} />
                )}
                {loadingState.fetchData && (
                  <SimpleBackdrop open={loadingState.fetchData} />
                )}

              </Grid>
              <Grid
                item
                lg={2.3}
                xs={12}
                sm={12}
                md={12}
                className="tracker_weather_grid"
                sx={{ position: "relative" }}
              >
                <Paper className="notification-paper-one"
                >
                  <WeatherBoard
                    weatherParam={weatherInfoData}
                    weatherData={weatherInfo}
                    title="Trackers Status"
                    weaHeight="72vh"
                  />

                </Paper>
                {loadingState.eachTrackerData && (
                  <SimpleBackdrop open={loadingState.eachTrackerData} />
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
        {openCalender && (
          <ModalCalender
            openDrawer={openCalender}
            setOpenDrawer={setOpenCalender}
            handleStopDateChange={setIsStopTime}
            handleStartDateChange={setIsStartTime}
            singledaySelect={false}
            selectedDateRangeVal={[
              {
                startDate: isStartTime ? isStartTime : new Date(),
                endDate: isStopTime ? isStopTime : new Date(),
                key: "selection",
              },
            ]}
          />
        )}
      </Box>
    </div>
  );
}

export default Trackers;
