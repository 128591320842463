import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./BarPlotForIOT.scss";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Select from "react-select";
import Dropdown from "react-dropdown";
import Divider from "@mui/material/Divider";
const SingleLineTempGraph = (props) => {
  const {
    isValueData,
    titleText,
    ispeakValue,
    yAxisText,
    legendName,
    indicateUnit,
    calenderClick,
    fieldsDropdata,
    durationDropData,
    durationTypes,selectedDateRangeProp
  } = props;
  const [series, setSeries] = useState([]);
  const [xaxisData, setXaxisData] = useState([]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isValueData && isValueData.length > 0) {
      const seriesData = [];
      const xaxisLabels = [];

      const parameterNames = Object.keys(isValueData[0]).filter(key => key !== "timestamp_edge");

      parameterNames.forEach((parameter, index) => {
        seriesData.push({
          name: parameter,
          data: isValueData.map(item => item[parameter]),
          colors: index < COLORS.length ? COLORS[index] : getRandomColor(),
        });
      });



      xaxisLabels.push(...isValueData.map(item => item.timestamp_edge));
      setSeries(seriesData);
      setXaxisData(xaxisLabels);
    }

  }, [isValueData]);

  const [options, setOptions] = useState({
    chart: { type: "area" },
    // colors: ["#90E900"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      categories: xaxisData,
      type: "datetime",

      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
    },
    yaxis: {

      title: {
        text: isFullScreen ? yAxisText : "",
        style: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
      labels: {
        formatter: function (value) {
          if (typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          // if (Math.abs(value) >= 1000) {
          //   return (value / 1000).toFixed(2) + 'k';
          // } else {
            return value.toFixed(2);
          // }
        },
        style: {
          colors: "#000000",
        },
        y: {
          formatter: function (value) {
            if (typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            // if (Math.abs(value) >= 1000) {
            //   return (value / 1000).toFixed(2) + 'k';
            // } else {
              return value.toFixed(2);
            // }
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: isFullScreen ? 'dark' : 'light',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
    // grid: {
    //   show: false,
    // },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      // position: "top",
      showForSingleSeries: false,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
            return value.toFixed(2) + indicateUnit;
        },
      },
    },
    toolbar: {
      show: false,
    },
  });

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [isFullScreen]);
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      // colors: ["#90E900"],
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: isFullScreen ? 'dark' : 'light',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
              return value.toFixed(2) + indicateUnit;
          },
        },
      },
      yaxis: {
        ...prevOptions.yaxis,
        text: yAxisText,
        style: {
          colors: isFullScreen ? "white" : "#000000",
        },

        labels: {
          ...prevOptions.yaxis.labels,
          formatter:  function (value) {
            if (typeof value === "undefined") {
              return ""; // Return an empty string if value is undefined
            }
            // if (Math.abs(value) >= 1000) {
            //   return (value / 1000).toFixed(2) + 'k';
            // } else {
              return value.toFixed(2);
            // }
          },
          style: {
            colors: isFullScreen ? "white" : "#000000",
          },
          y: {
            formatter: function (value) {
              if (typeof value === "undefined") {
                return ""; // Return an empty string if value is undefined
              }
              // if (Math.abs(value) >= 1000) {
              //   return (value / 1000).toFixed(2) + 'k' + indicateUnit;
              // } else {
                return value.toFixed(2) + indicateUnit;
              // }
            },
          },
        },
      },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
    }));
  }, [
    isValueData,
    isFullScreen,
    xaxisData,
    series,
    indicateUnit,
    legendName,
    yAxisText,
  ]);
  const COLORS = ["#1569B1", "#FF5733", "#2ECC71", "#9B59B6", "#F1C40F"];
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const handleFullScreenToggle = () => {
    const chartContainer = chartContainerRef.current;

    if (!isFullScreen) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) {
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) {
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) {
        chartContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  const chartContainerRef = useRef(null);
 
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
  const [isOptionType, setIsOptionType] = useState([]);
  const [isDurationType, setIsDurationType] = useState("");


  const handleChangeDeviceFields = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setIsOptionType(selectedValues);
    const selectedValuesString = selectedValues.join(",");
    props.selectedType(selectedValuesString);

  };
  const handleChangeDeviceDuration = (selectedOptions) => {
    // const selectedValues = selectedOptions.map((option) => option.value);

    setIsDurationType(selectedOptions.value);
    props.durationTypes(selectedOptions.value);

  };

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    }}
    >
       <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: isFullScreen ? "white" : "#212121" }}
          >
            {titleText}
          </p>{" "}
        </div>
        <div style={{  display: "flex",
            gap: "10px",
            alignItems: "baseline",
            justifyContent: "center",
      }}>
       
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>


          {(titleText === "Device Field Comparison" || titleText === "MFM Field Comparison") && (
            <div style={{ width: "auto", minWidth: "1.6vw", cursor: "pointer" }}>
              <Select
                className="recommendations-text"
                options={fieldsDropdata.map((field) => ({
                  value: field,
                  label: field.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
                }))}
                isMulti
                onChange={handleChangeDeviceFields}
                value={isOptionType.map((field) => ({
                  value: field,
                  label: field.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
                }))}
                placeholder="Select"
              />

            </div>
          )}
          {titleText === "MFM Field Comparison" && (
            <div style={{ width: "auto", minWidth: 160, cursor: "pointer" }}>
              <Dropdown
                className="recommendations-text"
                options={durationDropData.map((field) =>
                  field
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())
                )}
                onChange={handleChangeDeviceDuration}
                value={isDurationType}
                placeholder="Select"
              />

            </div>)
          }
          <Tooltip title="Calendar" arrow>
            <CalendarMonthIcon
              onClick={handleTimings}
             className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={handleFullScreenToggle}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? <FullscreenExitIcon className='icon-css' /> : <FullscreenIcon className='icon-css' />}
            </button>
          </Tooltip>
        </div>
        </div>
        <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}
 <div
        style={{
          height: isValueData.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
           display: isValueData.length === 0 && "flex", alignItems:"center", justifyContent:"center"
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {isValueData && isValueData.length === 0 ? (
          <div>
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <Chart options={options} series={series} type="area" height={350} />
        )}
      </div>
      {/* <div
        style={{ height: "90%", width: "100%", position: "relative" }}
        ref={chartContainerRef}
        // id="barplotforIotchart"
      >
        {!isFullScreen && <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{position:"absolute", zIndex:10, fontFamily: "Poppins-Bold", fontSize: "18px", margin: 0}}>{titleText}</div>
        </div>}
        {ispeakValue && (
          <div
            style={{
              marginTop: "30px",
              position: "absolute",
              marginBottom: "10px",
              left: "4vw",
            }}
          >
            {ispeakValue.map((item, index) => (
              <div key={index} style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "flex-start" }}>
                <CommonText
                  type="Regular_400"
                  text={
                    <span>
                      <span style={{ color: isFullScreen ? "white" : "#000000", }}>{item.peak_name} </span>
                      <span
                        style={{ color: "#1569B1" }}
                      >{`${item.peak_active_power.toFixed(2)}${item.peak_unit}`}</span>
                    </span>
                  }
                />

               {item.corresponding_time &&  (<CommonText
                  type="Regular_400"
                  text={
                    <span>
                      <span style={{ color: isFullScreen ? "white" : "#000000", }}>Time : </span>
                      <span style={{ color: "#1569B1" }}>
                        {item.corresponding_time.replace(/T|Z/g, ' ').slice(0, -4)}
                      </span>
                    </span>
                  }
                />)}
              </div>
            ))}
          </div>
        )}

        <Chart options={options} series={series} type="area" height={350} style={{paddingTop:"20px",position: "relative",
    zIndex: 9}}/>
        {isValueData && isValueData.length === 0 && (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CommonText type="Regular_400" text="No Data" />
          </div>
        )}
      </div> */}
     
    </div>
  );
};

export default SingleLineTempGraph;