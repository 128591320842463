import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function AiControllerProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/sixthImgOne.svg",
            title:"Advanced Control and Precision",
            description:"Optimizes solar energy capture by continuously monitoring motor currents and utilizing accelerometer data for precise panel positioning. It integrates a Physics-Informed Neural Network (PINN) to enhance tracking accuracy and efficiency, adapting to various conditions to maximize energy production."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/SixthImgTwo.svg",
            title:"Versatile Tracking Modes",
            description:"Offers multiple operational modes including Auto, where the tracker aligns panels to a target angle; Sun Track Start, which adjusts based on solar angles; Manual, for user-controlled angle adjustments; and Stop, halting panel movement when necessary, ensuring flexibility and control."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/SixImgThree.svg",
            title:"PINN-Optimized Tracking & Fault Prediction",
            description:"Leverage advanced AI and Machine Learning with the PINN-enhanced Controller for exceptional panel alignment and real-time fault prediction. The system seamlessly integrates comprehensive load monitoring and also features adaptive tracking modes that adjust to dynamic conditions. This ensures optimal performance, precise energy capture, and proactive resolution of potential issues before they impact your system."
        },
    
    ]

  const keyPoints = [
    {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey1.svg",
      title:"Optimized Capture",
      description:"Cutting-edge AI and real-time data fine-tune panel positioning for peak solar energy absorption."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey2.svg",
      title:"Adaptive Control",
      description:"Offers versatile tracking modes for dynamic adjustments and precise manual management."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixKey3.svg",
      title:"Efficient Flexibility",
      description:"Enhances performance with responsive, intelligent tracking that adapts to diverse conditions."
  },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideSix.png" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Smarttrak AI Controller</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Boosts solar energy capture with cutting-edge precision and adaptive tracking, offering flexible control for peak performance in every scenario.</p>
        </div>
      </div>
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Enhances solar energy capture by using real-time motor and accelerometer data for precise panel positioning, guided by advanced AI models like PINN. Supports multiple tracking modes, offering flexibility between automated solar alignment and manual control for dynamic performance optimization.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphSix.jpg' alt='prod' className='infoGraph' />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
      <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
          {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
                <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default AiControllerProductsPage;