import React, { useState } from 'react';
import "./PowerOptix.scss";
import Navbar from '../MainLandingPage/Navbar';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";
function PowerOptix() {

  const features = [
    {
      featureImg: "https://s3.amazonaws.com/smarttrak.co/newProductPage/productOne.jpg",
      title: "Accurate Generation Forecasting",
      description: "Leverage the power of deep learning with Fourcastnet and Graphcast for precise energy generation forecasts. Optimize energy distribution and maximize efficiency."
    },
    {
      featureImg: "https://s3.amazonaws.com/smarttrak.co/newProductPage/productTwo.jpg",
      title: "Fault Prediction",
      description: "Stay ahead of potential issues with our advanced machine learning algorithms. Detect anomalies early and schedule predictive maintenance effortlessly."
    },
   {
      featureImg: "https://s3.amazonaws.com/smarttrak.co/newProductPage/slideThree.jpg",
      title: "Real-Time 3D Digital Twin",
      description: "Experience detailed, high fidelity 3D models of your entire solar farm. Interact with every component, from panels to inverters, in a visually stunning and immersive way."
    },
    {
      featureImg: "https://s3.amazonaws.com/smarttrak.co/newProductPage/productFour.jpg",
      title: "Remote Management",
      description: "Utilise our virtual control room to remotely manage your solar farm. As demand and the weather change, instantly adjust panel angles and settings for best results."
    },
    {
      featureImg: "https://s3.amazonaws.com/smarttrak.co/newProductPage/productFive.jpg",
      title: "Drone Integration",
      description: "Utilize autonomous drones for real time image capture and AI-powered analysis. Detect panel damage, soiling, and vegetation encroachment seamlessly."
    },

  ]
  const reasons = [
    {
      icon: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/Timer.svg",
      title: "Efficiency",
      description: "Boost your solar farm’s performance with real-time data and predictive insights."
    },
    {
      icon: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/LightbulbFilament.svg",
      title: "Innovation",
      description: "Embrace the latest in AI, VR, and AR technologies to stay ahead in the renewable energy sector."
    },
    {
      icon: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/UserFocus.svg",
      title: "Simplicity",
      description: "Enjoy an intuitive, user-friendly interface that makes managing your solar farm easy and efficient."
    },
    {
      icon: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/ShieldCheck.svg",
      title: "Reliability",
      description: "Trust in accurate predictions and automated maintenance to keep your solar farm running smoothly."
    },
  ]
  const addCards = [
    {
      title: "Real-time 3D Digital Twin",
      t1: "Experience High-Fidelity 3D Models",
      t1p1: "Get detailed, accurate representations of your entire solar farm.",
      t1p2: "Visualize every component, including panels, inverters, and cables.",
      t2: "Live Data Integration",
      t2p1: "Stay updated with real-time data from IoT sensors on panels, inverters, and weather stations.",
      t2p2: "Monitor performance and environmental conditions comprehensively.",
      t3: "Immersive Interaction",
      t3p1: "Access the 3D Digital Twin via VR (Oculus) and AR (Apple Vision Pro).",
      t3p2: "Engage with your solar farm in a visually stunning, interactive manner.",
      t4: "Benefits",
      t4p1: "Enhanced visualization aids in quick identification of issues.",
      t4p2: "Immersive technology improves user experience and training.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add7.png"
    },
    {
      title: "AI-Powered Fault Detection and Predictive Maintenance",
      t1: "Anomaly Detection",
      t1p1: "Leverage advanced machine learning algorithms to continuously monitor sensor data.",
      t1p2: "Detect anomalies early to prevent potential issues and avoid costly downtime.",
      t2: "Predictive Maintenance",
      t2p1: "Forecast potential failures before they occur using sophisticated predictive models.",
      t2p2: "Schedule maintenance proactively, minimizing disruptions and ensuring smooth operations.",
      t3: "Automated Alerts",
      t3p1: "Receive instant alerts for detected faults, allowing for quick response.",
      t3p2: "Benefit from automated and efficient maintenance scheduling, keeping your solar farm in optimal condition.",
      t4: "Benefits",
      t4p1: "Reduce operational costs by preventing unexpected breakdowns.",
      t4p2: "Increase system reliability and lifespan with timely maintenance.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add4.png"
    },
    {
      title: "Fault Prediction",
      t1: "Advanced AI Models",
      t1p1: "Predict potential faults days or weeks in advance with high accuracy.",
      t1p2: "Utilize historical data, current sensor readings, and environmental factors for comprehensive analysis.",
      t2: "Comprehensive Data Analysis",
      t2p1: "Integrate various data sources to enhance prediction accuracy.",
      t2p2: "Provide probability scores for different fault types (e.g., inverter failure, panel degradation).",
      t3: "Early Warning System",
      t3p1: "Receive early warnings for potential faults to take preventive measures.",
      t3p2: "Plan and execute targeted interventions based on fault probability scores.",
      t4: "Benifits",
      t4p1: "Make informed decisions with fault probability scores.",
      t4p2: "Target interventions precisely, reducing maintenance time and cost.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add1.png"
    },
    {
      title: "Ideal Generation Digital Modeling",
      t1: "Simulation of Optimal Performance",
      t1p1: "Create a digital model of your solar farm operating under ideal conditions.",
      t1p2: "Use this model as a benchmark to gauge real-time performance.",
      t2: "Performance Comparison",
      t2p1: "Continuously compare your solar farm’s real-time performance against the ideal model.",
      t2p2: "Quickly identify deviations and inefficiencies for immediate action.",
      t3: "Optimization Recommendations",
      t3p1: "Pinpoint areas of underperformance with detailed insights.",
      t3p2: "Receive actionable recommendations to optimize energy production and enhance efficiency.",
      t4: "Benefits",
      t4p1: "Maximize energy production by addressing inefficiencies.",
      t4p2: "Optimize overall solar farm performance.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add2.png"
    },
    {
      title: "LLM Agent Integration",
      t1: "Natural Language Interface",
      t1p1: "Query system status and receive recommendations effortlessly using an intuitive natural language interface.",
      t1p2: "Engage in conversational interactions for seamless operations.",
      t2: "Multiple LLM Agents",
      t2p1: "Deploy specialized LLM agents tailored for various tasks within your solar farm management.",
      t2p2: "Benefit from agents dedicated to different functions such as system monitoring, troubleshooting, reporting, and optimization.",
      t3: "Complex Scenario Analysis",
      t3p1: "Utilize AI-powered analysis for deep insights into complex issues.",
      t3p2: "LLM agents collaborate to solve problems efficiently with AI-driven solutions.",
      t4: "Detailed Reporting",
      t4p1: "Generate comprehensive reports and actionable insights in natural language.",
      t4p2: "Each agent provides targeted insights, ensuring clear and precise information for various stakeholders.",
      t5: "Benefits",
      t5p1: "Simplified management with natural language queries.",
      t5p2: "Enhanced decision-making with detailed, easy-to-understand reports.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add8.png"
    },
    {
      title: "Drone Integration",
      t1: "Autonomous Operation",
      t1p1: "Utilize autonomous drones for real-time image capture and monitoring of your solar farm.",
      t1p2: "Cover large areas quickly and efficiently, ensuring comprehensive surveillance.",
      t2: "AI-Powered Image Analysis",
      t2p1: "Detect panel damage, soiling, and vegetation encroachment through automated image processing.",
      t2p2: "Leverage our AI platform to filter drone image data with trained models, extracting the most useful and relevant content.",
      t3: "Seamless Integration",
      t3p1: "Integrate drone imagery directly into the 3D Digital Twin for enhanced visualization and analysis.",
      t3p2: "Gain real-time insights and take immediate action based on accurate, up-to-date imagery.",
      t4: "Benefits",
      t4p1: "Enhanced monitoring with precise, real-time data.",
      t4p2: "Reduce manual inspection efforts and costs with automated drone operations and AI analysis.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add6.png"
    },
    {
      title: "Remote Monitoring and Control",
      t1: "Virtual Control Room",
      t1p1: "Centralized interface for remote management of your solar farm.",
      t1p2: "Monitor and adjust operations from any location, providing you with ultimate control and flexibility.",
      t2: "Dynamic Adjustments",
      t2p1: "Real-time adjustment of panel angles and inverter settings to optimize performance.",
      t2p2: "Adapt to changing weather conditions and energy demand instantly for maximum efficiency.",
      t3: "Performance Optimization",
      t3p1: "Utilize real-time data and insights to enhance your solar farm's performance.",
      t3p2: "Integrate weather data to make informed adjustments, ensuring optimal energy production.",
      t4: "Benefits",
      t4p1: "Ultimate flexibility with remote management.",
      t4p2: "Increased efficiency with real-time dynamic adjustments.",
      t4p3: "Enhanced performance through continuous optimization.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add9.png"
    },
    {
      title: "Accurate Energy Generation Forecasting",
      t1: "Advanced Deep Learning Models",
      t1p1: "Utilize Fourcastnet and Graphcast for precise energy generation forecasting.",
      t1p2: "Rely on state-of-the-art deep learning techniques to ensure high accuracy.",
      t2: "High-Accuracy Predictions",
      t2p1: "Forecast energy output based on weather forecasts, historical data, and current conditions.",
      t2p2: "Provide detailed predictions at each inverter level, ensuring granular control and optimization.",
      t3: "Integration with Grid Management",
      t3p1: "Seamlessly integrate with grid management systems for optimal energy distribution.",
      t3p2: "Enhance energy efficiency and output by aligning production with demand forecasts.",
      t4: "Benefits",
      t4p1: "Precision forecasting for better planning and optimization.",
      t4p2: "Granular control for precise management and adjustments.",
      t4p3: "Optimal distribution with seamless grid management integration.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add5.png"
    },
    {
      title: "Historical Data Analysis and Reporting",
      t1: "Performance Dashboard",
      t1p1: "Access a comprehensive dashboard showcasing key performance metrics of your solar farm.",
      t1p2: "Monitor and analyze long-term trends to understand performance patterns and improvements.",
      t2: "Custom Reports",
      t2p1: "Generate tailored reports for different stakeholders, providing clear and concise information.",
      t2p2: "Customize reports to meet specific needs and objectives, ensuring relevant and actionable insights.",
      t3: "Long-term Analysis",
      t3p1: "Conduct longitudinal data analysis for strategic planning and decision-making.",
      t3p2: "Utilize historical data to identify trends, predict future performance, and optimize operations.",
      t4: "Benefits",
      t4p1: "Make informed decisions with detailed performance metrics and long-term analysis.",
      t4p2: "Clear reporting for stakeholders with customized, easy-to-understand reports.",
      t4p3: "Strategic planning supported by historical data for continuous improvement.",
      dropImg: "https://s3.amazonaws.com/smarttrak.co/new_landing_images/add3.png"
    },
  ]
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleSubmit = () => {
    if (!email) {
      enqueueSnackbar("Please enter your email address", {
        variant: "warning",
      });
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Email submitted successfully", {
        variant: "success",
      });
    }
    setEmail("");
  }

  const handleOpenCard = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };
  const handleProductClick = (name) => {
    if(name === "Accurate Generation Forecasting"){
    history.push("/products/energy_forecasting")
     } else if (name === "Fault Prediction"){
      history.push("/products/fault_prediction")
     } else if (name === "Real-Time 3D Digital Twin"){
      history.push("/products/three_d_twin")
     } else if (name === "Remote Management"){
      history.push("/products/remote_management")
     } else if (name === "Drone Integration"){
      history.push("/products/drone_integration")
     } 
  };

  return (
    <div className='landing-container'>
      <Navbar />
      <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/powerOptix.png" alt="powerOptix" />
        <div className="powerOptix_overlay">
          <p className="Poppins_45px_rem">Transform Your Solar Farm with PowerOptix</p>
          <p style={{ padding: "1vw 0vw", width: "96%" }} className="Poppins_20px_rem">Revolutionize Your Solar Operations with AI-Driven Solutions and Cutting-Edge Technology</p>
        </div>
      </div>
      <div className='title-description_container'>
        <p className='Poppins_35px_rem'>Moving Forward with Managing Solar Farms</p>
        <p className='Poppins_18px_rem' style={{ fontWeight: "400", textAlign: "center" }}>
        Transform the management of your solar farms with cutting-edge technology and AI-driven solutions. PowerOptix offers a comprehensive range of tools designed to enhance productivity, maximize performance, and ensure reliability. Discover our innovative products and see how we can revolutionize your solar energy business.</p>      </div>
      <div className='feature_container'>
        <p className='Poppins_35px_rem'>Key Features</p>
        <Grid container className="feature-grid"
        >
          {features.map((item, index) => (
            <Grid item key={index} lg={3.3} xs={11} sm={11} md={5.3}>
              <Paper className="feature-cards"
              onClick={()=>handleProductClick(item.title)}
                sx={{ cursor:"pointer",
                  ":hover": {
                    backgroundColor: "#CCDDEB !important",
                  },
                }}
              >
                <img src={item.featureImg} alt='poweroptix' className='power_gridImg'/>
                <p className='Poppins_20px_rem' id="hover_title" style={{ color: "#FFFFFF" }}>{item.title}</p>
                <p className='Poppins_18px_rem' id='hover_black' style={{ fontWeight: "500", color: "#FFFFFF", height:"9.5rem" }}>{item.description}</p>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className='reason_container'>
        <p className='Poppins_35px_rem' style={{ color: "#ffff" }}>Reason to choose PowerOptix</p>
        <Grid container className="feature-grid"
        >
          {reasons.map((item, index) => (
            <Grid item key={index} lg={4} xs={11} sm={5} md={3.3}>
              <Paper className="reason-cards"
              >
                <img src={item.icon} style={{ width: "2.3vw" }} alt="clock" className='reason_icons' />
                <p className='Poppins_20px_rem' style={{ color: "#015399", fontWeight: "700" }}>{item.title}</p>
                <p className='Poppins_18px_rem' style={{ fontWeight: "500", height: "13vh" }}>{item.description}</p>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className='title-description_container'>
        <p className='Poppins_35px_rem'>Moving forward with managing Solar Farms</p>
        <p className='Poppins_18px_rem' style={{ fontWeight: "500", textAlign: "center" }}>With the use of cutting-edge technology and AI-driven solutions, transform the management of your solar farms. A whole range of tools from PowerOptix are available to improve productivity, maximise performance, and guarantee dependability. Examine our cutting-edge products to see how we can transform your solar energy business.</p>
      </div>
      <div className='add_cards_container'>
        {addCards.map((item, index) => {
          const isExpanded = expandedCardIndex === index;
          return (
            <div
              className='add_cards'
              key={index}
              onClick={() => handleOpenCard(index)}
              style={{ flexDirection: isExpanded ? "column" : "row" }}
            >
              <div className='flex-card'
                style={{
                  boxShadow: isExpanded ? "0px 1px 3px 0px #00000059" : "none",
                  padding: isExpanded ? "1vw 2vw" : "0vw", borderRadius: isExpanded ? "1vw 1vw 0vw 0vw" : "0vw"
                }}>
                <p className='Poppins_20px_rem' style={{color:"#212121"}}>{item.title}</p>
                <img
                  src={
                    isExpanded
                      ? 'https://s3.amazonaws.com/smarttrak.co/new_landing_images/minus_icon.svg'
                      : 'https://s3.amazonaws.com/smarttrak.co/new_landing_images/plus_icon.svg'
                  }
                  className="plus_minus"
                  style={{ width: '1.2rem' }}
                  alt={isExpanded ? 'minus' : 'plus'}
                />
              </div>
              {isExpanded && (
                <div className='expanded_content'>
                  <div>
                    <div className='bullet_points'>
                      <p className='Poppins_18px_rem'>{item.t1}</p>
                      <ui className="left_margin">
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t1p1}</li>
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t1p2}</li>
                      </ui>
                    </div>
                    <div className='bullet_points'>
                      <p className='Poppins_18px_rem'>{item.t2}</p>
                      <ui className="left_margin">
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t2p1}
                        </li>
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t2p2}</li>
                      </ui>
                    </div>
                    <div className='bullet_points'>
                      <p className='Poppins_18px_rem'>{item.t3}</p>
                      <ui className="left_margin">
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t3p1}</li>
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t3p2}</li>
                      </ui>
                    </div>
                    <div className='bullet_points'>
                      <p className='Poppins_18px_rem'>{item.t4}</p>
                      <ui className="left_margin">
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t4p1}</li>
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t4p2}</li>
                        {item.t4p3 && <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t4p3}</li>}
                      </ui>
                    </div>
                    {item.t5 && <div className='bullet_points'>
                      <p className='Poppins_18px_rem'>{item.t5}</p>
                      <ui className="left_margin">
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t5p1}</li>
                        <li className='Poppins_18px_rem' style={{ fontWeight: "500" }}>{item.t5p2}</li>
                      </ui>
                    </div>}
                  </div>
                  <div>
                    <img className='card_image' src={item.dropImg} alt='add1' />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "11vh 0vh" }}>
        <div className="subscribe-news">
          <div className="service-heading" style={{ padding: "0px" }}>
            <p className="bold-common-text" >Subscribe to our newsletter!</p>
            <p id="expertise-description" className="regular-common-text" style={{ width: "39%" }}>Unlocking creativity across industries, including content generation, design, virtual avatars, and personalised storytelling, revolutionizing innovation and audience engagement.</p>
          </div>
          <Divider style={{ border: "0.5px solid #656565", margin: "20px 0px" }} />
          <div className="submit-main">
            <div className="submit-box">

              <div className="submit-btn">
                <input type="email" value={email} onChange={handleEmailChange} placeholder="Input your email to stay updated!" style={{ color: "gray" }} id="email-input" className="regular-common-text" />
                <Button onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  className="regular-common-text"
                  id="common-btn"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#212121",
                    borderRadius: "3vw",
                    padding: "1.9vh 3vw",
                    textTransform: "capitalize",
                    width: "13vw",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PowerOptix