import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, { useNodesState, useEdgesState, addEdge, MiniMap, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./PlantLayout.scss";
import CommonText from '../../Common/CommonText';
import { CosmicInverterService } from '../../Services/CosmicInverterService';
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import SimpleBackdrop from '../../../LifeScience/components/common/SimpleBackdrop';
import { useLocation } from 'react-router-dom';
import useErrorHandler from '../../../utils/errorHandler';
const initBgColor = 'white';

const connectionLineStyle = { stroke: 'black' };
const snapGrid = [20, 20];

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };
const invService = new CosmicInverterService();
const PlantLayout = () => {
  const location = useLocation();
  const { newInvArray } = location.state;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [bgColor, setBgColor] = useState(initBgColor);
  const [isWeatherData, setIsWeatherData] = useState([]);
  const [mfmData, setIsMfmData] = useState([]);
  const [ghiOverview, setGHIOverview] = useState({});
  const handleFetchError = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const scbBoxes = [
    {
      name: "A1",
      value: "20A"
    },
    {
      name: "B1",
      value: "20A"
    },
    {
      name: "B2",
      value: "20A"
    },
    {
      name: "B3",
      value: "20A"
    },
    {
      name: "C1",
      value: "20A"
    },
    {
      name: "C2",
      value: "20A"
    },
    {
      name: "C3",
      value: "20A"
    },
    {
      name: "C4",
      value: "20A"
    },
    {
      name: "C5",
      value: "20A"
    },
    {
      name: "C6",
      value: "20A"
    },
    {
      name: "D1",
      value: "20A"
    },
    {
      name: "D2",
      value: "20A"
    },
    {
      name: "D3",
      value: "20A"
    },
    {
      name: "D4",
      value: "20A"
    },
    {
      name: "D5",
      value: "20A"
    },
    {
      name: "D6",
      value: "20A"
    },
    {
      name: "D7",
      value: "20A"
    },
    {
      name: "E6",
      value: "20A"
    },
    {
      name: "E7",
      value: "20A"
    },
    {
      name: "E8",
      value: "20A"
    },
    {
      name: "F4",
      value: "20A"
    },
    {
      name: "F5",
      value: "20A"
    },
    {
      name: "F6",
      value: "20A"
    },
    {
      name: "F7",
      value: "20A"
    },
    {
      name: "G2",
      value: "20A"
    },
    {
      name: "G3",
      value: "20A"
    },
    {
      name: "G4",
      value: "20A"
    },
    {
      name: "G5",
      value: "20A"
    },
    {
      name: "H1",
      value: "20A"
    },
    {
      name: "H2",
      value: "20A"
    },
    {
      name: "E1",
      value: "20A"
    },
    {
      name: "E2",
      value: "20A"
    },
    {
      name: "E3",
      value: "20A"
    },
    {
      name: "E4",
      value: "20A"
    },
    {
      name: "E5",
      value: "20A"
    },
    {
      name: "F1",
      value: "20A"
    },
    {
      name: "F2",
      value: "20A"
    },
    {
      name: "F3",
      value: "20A"
    },
    {
      name: "F8",
      value: "20A"
    },
    {
      name: "G1",
      value: "20A"
    },
  ]
  const history = useHistory();
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  useEffect(() => {
    handleWeatherParameters(id);
    handleMfmParameters(id);
    handleGHIOverview(id);
  }, [])
  const handleGHIOverview = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getGHIOverview(id);
      if (res.status === 200) {
        setGHIOverview(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleFetchError(error, "GHI Overview");
    }
  };

  const handleInverterClick = (invData) => {
    history.push({
      pathname: `./dev-monitoring/inv-dashboard/inv-details`,
      state: {
        invDeviceData: invData,
        ghiData: ghiOverview,
      },
    });
  }
  const handleWeatherClick = () => {
    history.push({
      pathname: `./dev-monitoring/weather-dashboard`,
    });
  }
  const handleVCBPanelClick = () => {
    history.push({
      pathname: `./dev-monitoring/vcb-panel`,
    });
  }
  const handleHTPanelClick = () => {
    history.push({
      pathname: `./dev-monitoring/ht-panel`,
    });
  }
  useEffect(() => {
    const onChange = (event) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id !== '2') {
            return node;
          }

          const color = event.target.value;

          setBgColor(color);

          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        })
      );
    };
   
    setNodes([
      {
        id: '5',
        type: 'selectorNode',
        data: {
          label: (
            <>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/transformer.png" alt="transformer" style={{ width: '6vw' }} />
              <div><p className='Poppins_16px_rem' style={{fontWeight:"400"}}>Transformer 01</p></div>
            </>
          )
        },
        position: { x: -160, y: -180 }, 
        targetPosition: 'bottom',
        sourcePosition: 'top',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '6',
        type: 'selectorNode',
        data: {
          label: (
            <>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/transformer.png" alt="transformer" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="Transformer 02" /></div>
            </>
          )
        },
        position: { x: 720, y: -180 },
        targetPosition: 'bottom',
        sourcePosition: 'top',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '7',
        type: 'selectorNode',
        data: {
          label: (
            <div style={{ cursor: "pointer" }} onClick={handleVCBPanelClick}>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/vcb-panel.png" alt="vcb-panel" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="VCB Panel" /></div>
            </div>
          )
        },
        position: { x: -160, y: -500 },
        targetPosition: 'bottom',
        sourcePosition: 'right',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '8',
        type: 'selectorNode',
        data: {
          label: (
            <div style={{ cursor: "pointer" }} onClick={handleHTPanelClick}>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/ht-panel.png" alt="ht-panel" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="HT Panel(VCB 04)" /></div>
            </div>
          )
        },
        position: { x: 680, y: -500 },
        targetPosition: 'left',
        sourcePosition: 'right',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '9',
        type: 'selectorNode',
        data: {
          label: (
            <>
              <img data-tooltip-id="gross-gen-meter" src="https://s3.amazonaws.com/smarttrak.co/images/solar-meter.png" alt="ht-panel" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="Gross Generation Meter" /></div>
            </>
          )
        },
        position: { x: 1300, y: -500 },
        targetPosition: 'left',
        sourcePosition: 'right',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '10',
        type: 'output',
        data: {
          label: (
            <>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/power-grid.png" alt="grid" style={{ width: '15vw' }} />
              <div><CommonText type="Regular_400" text="Grid" /></div>
            </>
          )
        },
        position: { x: 1900, y: -500 },
        targetPosition: 'left',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '11',
        type: 'input',
        data: {
          label: (
            <>
              <img src="https://s3.amazonaws.com/smarttrak.co/images/computer.png" alt="scada" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="SCADA System" /></div>
            </>
          )
        },
        position: { x: 1900, y: -180 },
        sourcePosition: 'left',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '12',
        type: 'selectorNode',
        data: {
          label: (
            <>
              <div><CommonText type="Regular_400" text="DATA LOGGER CONTROL ROOM 02" /></div>
            </>
          )
        },
        position: { x: 1280, y: -140 },
        targetPosition: 'right',
        sourcePosition: 'left',
        style: { background: '#fff', border: "1px solid rgb(119, 119, 119)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '13',
        type: 'selectorNode',
        data: {
          label: (
            <>
              <div><CommonText type="Regular_400" text="DATA LOGGER CONTROL ROOM 01" /></div>
            </>
          )
        },
        position: { x: 300, y: -140 },
        targetPosition: 'left',
        sourcePosition: 'bottom',
        style: { background: '#fff', border: "1px solid rgb(119, 119, 119)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '14',
        type: 'selectorNode',
        data: {
          label: (
            <div style={{ cursor: "pointer" }} onClick={handleWeatherClick}>
              <img data-tooltip-id="weather-data" src="https://s3.amazonaws.com/smarttrak.co/images/weatherPic.png" alt="weather" style={{ width: '6vw' }} />
              <div><CommonText type="Regular_400" text="Weather Station" /></div>
            </div>
          ),
        },
        position: { x: -500, y: -180 },
        targetPosition: 'bottom',
        sourcePosition: 'right',
        style: { background: 'transparent', border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '15',
        type: 'input',
        data: {
          label: (
            <>
              <img src="	https://s3.amazonaws.com/smarttrak.co/images/sun.png" alt="weather" style={{ width: '6vw' }} />
            </>
          ),
        },
        position: { x: -560, y: -560 },
        style: { backgroundColor: "transparent", border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '16',
        type: 'input',
        data: {
          label: (
            <div className='status_container'>
            <p className='inv_status_color' style={{backgroundColor:"#04724D"}}></p>
            <p className='Poppins_16px_rem' style={{fontWeight:"400"}}>Excellent</p>
            </div>
          ),
        },
        position: { x: 1440, y: -720 },
        style: { backgroundColor: "transparent", border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '17',
        type: 'input',
        data: {
          label: (
            <div className='status_container'>
            <p className='inv_status_color' style={{backgroundColor:"#FCD303"}}></p>
            <p className='Poppins_16px_rem' style={{fontWeight:"400"}}>Good</p>
            </div>
          ),
        },
        position: { x: 1580, y: -720 },
        style: { backgroundColor: "transparent", border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
      {
        id: '18',
        type: 'input',
        data: {
          label: (
            <div className='status_container'>
            <p className='inv_status_color' style={{backgroundColor:"#FF472E"}}></p>
            <p className='Poppins_16px_rem' style={{fontWeight:"400"}}>Faulty</p>
            </div>
          ),
        },
        position: { x: 1700, y: -720 },
        style: { backgroundColor: "transparent", border: "1px solid transparent", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
      },
    ]);

    setEdges([
      {
        id: 'e1-5',
        source: '1',
        target: '5',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e2a-5',
        source: '2',
        target: '5',
        sourceHandle: 'a',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e3b-6',
        source: '3',
        target: '6',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e4b-6',
        source: '4',
        target: '6',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e5b-7',
        source: '5',
        target: '7',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e7b-8',
        source: '7',
        target: '8',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e6b-8',
        source: '6',
        target: '8',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e8b-9',
        source: '8',
        target: '9',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e9b-10',
        source: '9',
        target: '10',
        sourceHandle: 'b',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e11b-12',
        source: '11',
        target: '12',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'e12b-13',
        source: '12',
        target: '13',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(246 237 26)', strokeWidth: "2" },
      },
      {
        id: 'e13b-1',
        source: '13',
        target: '1',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'e13b-2',
        source: '13',
        target: '2',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'e12b-3',
        source: '12',
        target: '3',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'e12b-4',
        source: '12',
        target: '4',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'e14b-13',
        source: '14',
        target: '13',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
      {
        id: 'escbNode1b-14',
        source: 'scbNode1',
        target: '14',
        sourceHandle: 'b',
        // animated: true,
        style: { stroke: 'rgb(27 229 65)', strokeWidth: "2" },
      },
    ]);
  }, []);
  useEffect(() => {
    // Creating a new node for the collection of the first 10 scbBoxes
    const scbNode1 = {
      id: 'scbNode1',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="Modbus RTU" /></div>,
      },
      position: { x: -160, y: 280 }, // Adjust position as needed
      sourcePosition: "top",
      targetPosition: "bottom",
      style: { border: '1px solid #777', padding: 10 },
    };
    const scbNode2 = {
      id: 'scbNode2',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="SCBs" /></div>,
      },
      position: { x: 440, y: 280 },
      sourcePosition: "top",
      targetPosition: "bottom",
      style: { border: '1px solid #777', padding: 10 },
    };
    const scbNode3 = {
      id: 'scbNode3',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="SCBs" /></div>,
      },
      position: { x: 1040, y: 280 },
      sourcePosition: "top",
      targetPosition: "bottom",
      style: { border: '1px solid #777', padding: 10 },
    };
    const scbNode4 = {
      id: 'scbNode4',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="SCBs" /></div>,
      },
      position: { x: 1640, y: 280 },
      sourcePosition: "top",
      targetPosition: "bottom",
      style: { border: '1px solid #777', padding: 10 },
    };

    // Adding the first 10 scbBoxes to the nodes state
    const scbBoxNodes1 = scbBoxes.slice(0, 10).map((box, index) => ({
      id: `scbBox1-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br /> {box.value}
          </div>
        ),
      },
      position: { x: -380 + index * 60, y: 400 },
      sourcePosition: "top",
      style: { border: '1px solid #777', padding: 10, width: "50px" },
    }));
    const scbBoxNodes2 = scbBoxes.slice(10, 20).map((box, index) => ({
      id: `scbBox2-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br /> {box.value}
          </div>
        ),
      },
      position: { x: 220 + index * 60, y: 400 },
      sourcePosition: "top",
      style: { border: '1px solid #777', padding: 10, width: "50px" },
    }));
    const scbBoxNodes3 = scbBoxes.slice(20, 30).map((box, index) => ({
      id: `scbBox3-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br /> {box.value}
          </div>
        ),
      },
      position: { x: 820 + index * 60, y: 400 },
      sourcePosition: "top",
      style: { border: '1px solid #777', padding: 10, width: "50px" },
    }));
    const scbBoxNodes4 = scbBoxes.slice(30, 40).map((box, index) => ({
      id: `scbBox4-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br /> {box.value}
          </div>
        ),
      },
      position: { x: 1420 + index * 60, y: 400 },
      sourcePosition: "top",
      style: { border: '1px solid #777', padding: 10, width: "50px" },
    }));
    const inverterNodes = newInvArray.map((inverter, index) => ({
      id: (index + 1).toString(),
      type: 'selectorNode',
      data: {
        label: (
          <div style={{ cursor: "pointer" }} onClick={() => handleInverterClick(inverter)}>
            <img
              data-tooltip-id={`inv${index + 1}-tooltip-${index + 1}`}
              src={getStatusImage(inverter?.device_details?.status === true ? "green" : "red")}
              alt="Inverter"
              style={{ width: '6vw' }}
            />
            <div><CommonText type="Regular_400" text={`Inverter 0${index + 1}`} /></div>
          </div>
        ),
      },
      position: { x: index * 700 - 400, y: 80 },
      sourcePosition: 'top',
      targetPosition: index % 2 === 0 ? "right" : "left",
      style: {
        background: 'transparent',
        border: "1px solid transparent",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      },
    }));
    // Update nodes and edges state
    setNodes((prevNodes) => [...prevNodes, scbNode1, scbNode2, scbNode3, scbNode4, ...scbBoxNodes1, ...scbBoxNodes2, ...scbBoxNodes3, ...scbBoxNodes4, ...inverterNodes]);
    setEdges((prevEdges) => [
      ...prevEdges,
      ...scbBoxNodes1.map((boxNode1) => ({
        id: `e-scbBox-${boxNode1.id}`,
        source: boxNode1.id,
        target: 'scbNode1',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode1-inverter1',
        source: 'scbNode1',
        target: '1',
        animated: true,
        style: { stroke: 'black' },
      },
      ...scbBoxNodes2.map((boxNode2) => ({
        id: `e-scbBox-${boxNode2.id}`,
        source: boxNode2.id,
        target: 'scbNode2',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode2-inverter2',
        source: 'scbNode2',
        target: '2',
        animated: true,
        style: { stroke: 'black' },
      },
      ...scbBoxNodes3.map((boxNode3) => ({
        id: `e-scbBox-${boxNode3.id}`,
        source: boxNode3.id,
        target: 'scbNode3',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode3-inverter3',
        source: 'scbNode3',
        target: '3',
        animated: true,
        style: { stroke: 'black' },
      },
      ...scbBoxNodes4.map((boxNode4) => ({
        id: `e-scbBox-${boxNode4.id}`,
        source: boxNode4.id,
        target: 'scbNode4',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode4-inverter4',
        source: 'scbNode4',
        target: '4',
        animated: true,
        style: { stroke: 'black' },
      },
    ]);
  }, []);
  useEffect(() => {
    const gap = 20;
    const xOffset = -430;
    const panelNodes = Array.from({ length: 40 }, (_, index) => ({
      id: `panel-${index}`,
      type: 'input',
      data: {
        label: (
          <>
            <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/solar_panel.svg" alt="panel" style={{ width: '4vw' }} />
          </>
        ),
      },
      position: { x: xOffset + index * (40 + gap), y: 457 },
      sourcePosition: 'top',
      style: { backgroundColor: "transparent", border: '1px solid transparent', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }
    }));

    setNodes((prevNodes) => [...prevNodes, ...panelNodes]);
  }, []);
  let warningDisplayed = false;
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: 'black' } }, eds)),
    []
  );

  const handleWeatherParameters = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id, "wms");
      if (res.status === 200) {
        setIsWeatherData(res.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // if (error.response && error.response.status === 500){
        //   history.push("/iot-home/server-error")
        //    setLoading(false);
        //  }
        if (!warningDisplayed) {
          enqueueSnackbar("Device weather data not found", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }

        setLoading(false);
      } else {
        console.log("Error while fetching the device weather data", error);
        if (!warningDisplayed) {
          enqueueSnackbar("Error while Fetching the device weather  Data", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }
        setLoading(false);
      }
    }
  };
  const handleMfmParameters = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id, "abt");
      if (res.status === 200) {
        setIsMfmData(res.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // if (error.response && error.response.status === 500){
        //   history.push("/iot-home/server-error")
        //    setLoading(false);
        //  }
        if (!warningDisplayed) {
          enqueueSnackbar("Device Parameter data not found", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }

        setLoading(false);
      } else {
        console.log("Error while fetching the device Parameter data", error);
        if (!warningDisplayed) {
          enqueueSnackbar("Error while Fetching the device Parameter  Data", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }
        setLoading(false);
      }
    }
  };
 
  const getStatusImage = (statusColor) => {
    switch (statusColor) { 
      case "green":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_status.svg";
      case "red":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_red_status.svg";
      case "orange":
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_orange_status.svg";
      default:
        return "https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_red_status.svg";
    }
  };
  return (
    
    <>
    {loading && <SimpleBackdrop open={loading} />}
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      style={{ background: bgColor }}
      connectionLineStyle={connectionLineStyle}
      snapToGrid={true}
      snapGrid={snapGrid}
      defaultViewport={defaultViewport}
      fitView
      attributionPosition="bottom-left"
    >

      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'input') return '#0041d0';
          if (n.type === 'selectorNode') return bgColor;
          if (n.type === 'output') return '#ff0072';
        }}
        nodeColor={(n) => {
          if (n.type === 'selectorNode') return bgColor;
          return 'black';
        }}
      />
      <Controls />
    </ReactFlow>
     

{newInvArray.map((inverter, index) => (
        <ReactTooltip
          key={index}
          id={`inv${index + 1}-tooltip-${index + 1}`}
          place="bottom"
          multiline={true}
          className='plant_view_tooltip'
        >
          <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Output Power : </p> 
            {inverter.device_data !== null && inverter.device_details !== null && inverter.device_data["output_power_kW"] !== null ? <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{inverter.device_data?.output_power_kW} kW</p>
            :<p className='Poppins_12px_rem' style={{fontWeight:"600"}}>0 kW</p>}
          </div>
          <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Energy : </p> 
           {(inverter.device_data !== null && inverter.device_data?.energy_counter_MWh !== null && inverter.device_data?.energy_counter_MWh !== undefined) ? 
           <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{inverter.device_data?.energy_counter_MWh.toFixed(2)} MWh</p>
          : 
          <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>0 MWh</p>
        } 
          </div>
        </ReactTooltip>
      ))}

      <ReactTooltip
        id="weather-data"
        place="bottom"
        multiline={true}
        className='plant_view_tooltip'
      >
        {isWeatherData.length > 0 ? (
          <>
           <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Wind Speed : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{isWeatherData[0]?.device_data?.wind_speed} km/hr</p>
          </div>
           <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Ambient Temp : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{isWeatherData[0]?.device_data?.ambient_temperature} °C</p>
          </div>
           <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Irradiance : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{isWeatherData[0]?.device_data?.irradiance} W/m²</p>
          </div>
          </>
        ) : (
          <>
              <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Wind Speed : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>-- km/hr</p>
          </div>
           <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Ambient Temp : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>-- °C</p>
          </div>
           <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Irradiance : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>-- W/m²</p>
          </div>
          </>
        )}
      </ReactTooltip>
      <ReactTooltip
        id="gross-gen-meter"
        place="bottom"
        multiline={true}
        className='plant_view_tooltip'
      >
        {mfmData.length > 0 ? (
          <>
                     <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Gross Generation : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>{mfmData[0]?.device_data?.active_export_fundamental_cum_Harmonics_KWh} kWh</p>
          </div>
          </>
        ) : (
          <>
            <div className='layout_tooltip'>
            <p className='Poppins_12px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>Gross Generation : </p> 
            <p className='Poppins_12px_rem' style={{fontWeight:"600"}}>-- kWh</p>
          </div>
          </>
        )}
      </ReactTooltip>
    </>
  );
};

export default PlantLayout;
