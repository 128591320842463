import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function DroneProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FifthImgOne.png",
            title:"Autonomous Operation",
            description:"Our drones autonomously perform real-time surveillance along predefined flight paths, capturing comprehensive images and videos. After automated data collection, the information is uploaded to an edge device, processed in the cloud, and analyzed for damages and discrepancies, ensuring efficient and thorough monitoring of expansive areas."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FifthImgTwo.svg",
            title:"AI Powered Image Analysis",
            description:"Utilizes advanced algorithms to detect panel damage, soiling, and vegetation through automated thermal and anomaly detection. The platform processes thermal images and performs detailed segmentation to identify issues such as dirt, damage, and other anomalies, providing precise insights from drone-captured imagery."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FifthImgThree.svg",
            title:"Seamless Integration",
            description:"Seamlessly integrate drone imagery with our 3D Digital Twin for enhanced visualization and analysis. Enjoy fully automated, periodic data collection with no manual intervention required for flight, charging, or data transfer. Our system ensures real-time insights with scheduled updates for continuous monitoring."
        },
    ]

  const keyPoints = [
    {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fifthKey1.svg",
      title:"Real-Time Insights",
      description:"Drones deliver precise, up-to-date data for superior solar farm monitoring."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fifthKey2.svg",
      title:"Cost-Efficient Automation",
      description:" Automated flights and AI analysis minimize manual inspections and reduce costs."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fifthKey3.svg",
      title:"Integrated Updates",
      description:"Continuous, seamless updates enhance performance and operational efficiency."
  },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideFive.jpg" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Drone Integration</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Elevate solar farm monitoring with autonomous flights and smart analysis, delivering real-time insights and seamless updates for optimal performance.</p>
        </div>
      </div>
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Transforms solar farm monitoring with autonomous flights and cutting-edge Computer Vision. Drones capture high-resolution images and videos during real-time surveillance, while AI-powered image analysis detects panel damage, soiling, and anomalies with precision. Integrated with our 3D Digital Twin, this system delivers continuous, actionable insights for optimal performance and efficient maintenance.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphFive.jpg' alt='prod' className='infoGraph' />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
      <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
          {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
                <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default DroneProductsPage;