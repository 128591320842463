import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function ThreeDProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/ThirdImgOne.svg",
            title:"High Fidelity 3D Models",
            description:"Provides high-fidelity 3D models for detailed visualization of your entire solar farm. Experience stunning clarity with precise 3D representations of every component, including panels, inverters, and cables. This advanced model delivers comprehensive, real-time insights into your system's layout, enhancing your ability to monitor and manage your solar infrastructure effectively."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/ThirdImgTwo.png",
            title:"Live Data Integration",
            description:"Integrates live data from IoT sensors on panels, inverters, and weather stations, providing real-time performance and environmental monitoring. Access detailed device alarms, view trackers and their angles, and make adjustments through auto or sun-tracking features. This integration ensures precise control and immediate insight into system status and performance."
         },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/ThirdImgThree.svg",
            title:"Immersive Interaction",
            description:"Offers an immersive experience of your solar farm through VR (Oculus) and AR (Apple Vision Pro). Engage with a visually stunning and interactive 3D digital twin, providing unparalleled insight and a comprehensive view of your solar infrastructure."
         },
    ]

  const keyPoints = [
    {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/thirdKey1.svg",
      title:"Detailed Visualization",
      description:"High-fidelity 3D models provide clear insights for quick issue identification."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/thirdKey2.svg",
      title:"Immersive Experience",
      description:"VR/AR integration offers a stunning, interactive view for enhanced control and training."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/thirdKey3.svg",
      title:"Real-Time Monitoring",
      description:"Live data integration ensures precise, up-to-date performance insights."
  },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideThree.jpg" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Real-Time 3D Digital Twin</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Delivers a stunning 3D Digital Twin with immersive VR/AR views, offering real-time, intuitive control and deep insights for your solar farm.</p>
        </div>
      </div>
     
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Provides an immersive 3D platform for real-time equipment visualization and control, integrating sensor data and system operations with advanced game engine technology. Accessible on PC, browser, mobile, and VR for seamless interaction.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphThree.jpg' alt='prod' className='infoGraph' />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
      <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
          {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
                <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default ThreeDProductsPage;