import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../../ExecutiveDashboard/ExceutiveDashboard.scss"
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import Divider from "@mui/material/Divider";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import { useLocation } from 'react-router-dom';
import InverterDetails from "./InverterDetails";
import "./Inverter.scss"
import EnergyData from "../../../Common/EnergyData";
import TableNoSearchBar from "../../../Common/TableNoSearchBar";
import { useHistory } from "react-router-dom";
import useErrorHandler from "../../../../utils/errorHandler";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ButtonSlider from "../../../Common/ButtonSlider";

const invService = new CosmicInverterService();
const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
function Inverter() {
  const location = useLocation();
  const inverterData = location.state?.invDeviceData;
  const ghiDetails = location.state?.ghiData;
  const history = useHistory();

  const [loadingState, setLoadingState] = useState({
    powerinfo: false,
    deviceTable: false,
    invList: false,
    idealPower: false,
    fieldList: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selectedPlantIndex, setSelectedPlantIndex] = useState(0);
  const [newInvArray, setNewInvArray] = useState([]);
  const [powerarray, setPowerArray] = useState({});
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceId, setDeviceId] = useState(inverterData.device_details.id);
  const [activeInverter, setActiveInverter] = useState(inverterData);
  const [idealGen, setIdealGen] = useState({});
  const [defaultField, setDefaultField] = useState("");
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 7; 
 
  const handleFetchError = useErrorHandler();
  useEffect(() => {
    handleDeviceTable(deviceId)
    handleInverterPowerInfo(deviceId)
    handleIdealGeneration(deviceId)
    handleFieldsList(deviceId)
  }, [deviceId]);
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];

  useEffect(() => {
    if (plantIdsArray.length > 0) {
      handlePlantClick(selectedPlantIndex, id);
    }
  }, []);

  const handleFieldsList = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter", device_id);
      if (res.status === 200) {
        setDefaultField(res.data[0]);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const startIndex = currentPage * itemsPerPage;
  let visibleInverters = newInvArray.slice(startIndex, startIndex + itemsPerPage);
   if (currentPage === 0) {
    visibleInverters = [
      {
        device_details:{name: "Inverters Dashboard"},
      },
      ...visibleInverters,
    ];
  }
  const handleInverterPowerInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: true }));
      const res = await invService.getInverterOverview(id, "inverter");
      if (res.status === 200) {
        setPowerArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
    } catch (error) {
      setPowerArray([])

      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleIdealGeneration = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, idealPower: true }));
      const res = await invService.getIdealGeneration(id);
      if (res.status === 200) {
        const data = JSON.parse(res.data);
      setIdealGen(data);
      setLoadingState((prevState) => ({ ...prevState, idealPower: false }));

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, idealPower: false }));
      setIdealGen({});

      handleFetchError(error, "Ideal Generation");
    }
  };
 

  const handleInverterDeatils = (inverter, id, index) => {
    if(index !== 0){
      setActiveInverter(inverter)
      setDeviceId(id)
      handleDeviceTable(id);
      handleInverterPowerInfo(id)
    } else{
     history.push("/iot-home/dev-monitoring/inv-dashboard")
    }
  };

  const handleDeviceTable = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceTable: true }));
      const res = await invService.getDeviceTable(device_id, "inverter");
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedDate = item["Date(YYYY-MM-DD)"]
            ? item["Date(YYYY-MM-DD)"].split('T')[0]
            : "--";
          const formattedItem = { ...item, "Date(YYYY-MM-DD)": formattedDate };

          // Iterate through keys to apply toFixed(2) on numeric values
          for (let key in formattedItem) {
            if (typeof formattedItem[key] === 'number') {
              formattedItem[key] = formattedItem[key].toFixed(2);
            }
          }
          return formattedItem;
        });

        setDeviceTableData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, deviceTable: false }));
      }
    } catch (error) {
      setDeviceTableData([])

      setLoadingState((prevState) => ({ ...prevState, deviceTable: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id,"inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        setLoadingState((prevState) => ({ ...prevState, invList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };


  const handlePlantClick = (index, id) => {
    setSelectedPlantIndex(index);
    handleInvertersList(id);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="home-container" style={{ backgroundColor: "#F4F4F4" }}>
       {newInvArray.length > 0 ? (
        
        <Grid
          container
          className="inv_names_container"
         sx={{ padding:"1rem !important", backgroundColor:"white !important",
         marginBottom:"0.5rem !important",
          border: "0.0625rem solid #D8D8D8 !important", borderRadius:"0.75rem !important" }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
          >
            <Grid
              container
              className="inv-names_grid"
            >
              {newInvArray.length > 0 ? (
              <>
              <ButtonSlider 
              isValueData={visibleInverters} 
              deviceId={deviceId} 
              handleInvDeatil={handleInverterDeatils} 
              itemsPerPage={itemsPerPage} />
            </>
              ) : (
                <div className='no-data'>
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>No data is available!</p>
                </div>
              )}
            </Grid>
          </Grid>
         
        </Grid>
      ) : (
        ""
      )}
    
     

      <Grid
        container
        className="inv_names_container"
      >
        <Grid
          item
          lg={5.8}
          xs={12}
          sm={12}
          md={12}
          className="only_flex-start"
          sx={{position:"relative"}}
        >
          <Paper
            className="capacity_paper"
          >
            <div className="flex-start_space-between">
              <div className="ideal_inv_box">
                <span className="Poppins_32px_rem" style={{ color: "#2F67F8" }}>
                  {activeInverter.device_details.name.charAt(0).toUpperCase() + activeInverter.device_details.name.slice(1).toLowerCase()}{` (${activeInverter.device_details.device_specific_details !==
                    null
                    ? activeInverter.device_details.device_specific_details
                      .capcaity_kVA
                    : "0"
                    }kVA)`}
                </span>
                <div className="flex_container">
                <div className="only_column_gap">
                <p className="raleway_16px_rem">Ideal Generated Power</p>
                <div className="flex_baseline">
                  {idealGen.output_power !== null && !isEmptyObject(idealGen) ? (
                    <><p className="Poppins_36px_rem">{idealGen.output_power}</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kW</p></>
                  ) : (
                    <><p className="Poppins_36px_rem">--</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kW</p></>
                  )}
                </div>
              </div>
                <div className="only_column_gap">
                <p className="raleway_16px_rem">Generated Power</p>
                <div className="flex_baseline">
                {activeInverter.device_data !== null &&
                    activeInverter.device_data["output_power_kW"] !== null ? (
                    <><p className="Poppins_36px_rem">{activeInverter.device_data.output_power_kW}</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kW</p></>
                  ) : (
                    <><p className="Poppins_36px_rem">--</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kW</p></>
                  )}
                </div>
              </div>
              </div>
              </div>

              <div style={{
                display: "flex", alignItems: "center",
                justifyContent: activeInverter?.device_data !== null && activeInverter?.device_data?.temperature_C !== 0 ? "space-between" : "center", padding: "1rem", width: "34%", borderRadius: "0.6rem", border: "1px solid #D8D8D8"
              }}>
                <div className="invstatus-container">
                  <div className="green-status" style={{flexDirection: activeInverter.device_details.status === true? "row" : "row-reverse", backgroundColor: activeInverter.device_details.status === true ? "green" : "red" }}>
                    <p className="raleway_8px_rem">{activeInverter.device_details.status === true ? "ON" : "OFF"}</p>
                    <div style={{
                      backgroundColor: activeInverter.device_details.status === true ? "rgb(113 213 69)" :  "#de6c64",
                      border: activeInverter.device_details.status === true ? "1px solid #21ad3d" :  "1px solid #c3392f", width: "0.5rem", height: "0.5rem", borderRadius: "50%"
                    }}>

                    </div>
                  </div>
                  <p className="Poppins_16px_rem">Status</p>
                </div>
                {activeInverter?.device_data !== null && activeInverter?.device_data?.temperature_C !== 0 &&
                  <div className="invTemp-container">
                    {(activeInverter?.device_data !== null && activeInverter?.device_data?.temperature_C !== null) ?
                      <p className="Poppins_16px_rem">{activeInverter.device_data.temperature_C}°C</p> :
                      <p className="Poppins_16px_rem">--°C</p>}
                    <p className="Poppins_16px_rem">Temp</p>
                  </div>}
              </div>
            </div>
            <div className="inv_genPower">
            <div className="only_column_gap">
                <p className="raleway_16px_rem" style={{ color: "#212121" }}>Today's Energy</p>
                <div className="flex_baseline">
                  {activeInverter.device_data !== null &&
                    activeInverter.device_data["todays_energy_kWh"] !== null ? (
                    <><p className="Poppins_36px_rem">{activeInverter.device_data.todays_energy_kWh}</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kWh</p></>
                  ) : (
                    <><p className="Poppins_36px_rem">--</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kWh</p></>
                  )}
                </div>
              </div>
              <div className="only_column_gap">
                <p className="raleway_16px_rem">Monthly Energy</p>
                <div className="flex_baseline">
                  {powerarray.past_30days_generated_energy_kWh !== null && powerarray.past_30days_generated_energy_kWh !== undefined  ? (
                    <><p className="Poppins_36px_rem">{powerarray.past_30days_generated_energy_kWh.toFixed(2)}</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kWh</p></>
                  ) : (
                    <><p className="Poppins_36px_rem">--</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>kWh</p></>
                  )}
                </div>
              </div>
            

              <div className="only_column_gap">
                <p className="raleway_16px_rem" style={{ color: "#212121" }}>Total Run Time</p>
                <div className="flex_baseline">
                  {activeInverter.device_data !== null &&
                    activeInverter.device_data["time_run_total_h"] !== null ? (
                    <><p className="Poppins_36px_rem">{activeInverter.device_data.time_run_total_h}</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>h</p></>
                  ) : (
                    <><p className="Poppins_36px_rem">--</p>
                      <p className="Poppins_18px_rem" style={{ fontFamily: "Raleway" }}>h</p></>
                  )}
                </div>
              </div>
            </div>
          </Paper>
          {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.powerinfo} />
              )}
        </Grid>
        <Grid
          item
          lg={6.1}
          xs={12}
          sm={12}
          md={12}
          className="center_center_column"
        >
          <Grid sx={{position: "relative"}}
            container className="center_center_flex_gap"
          >
            <Grid
              item
              lg={3.7}
              xs={12}
              sm={3.5}
              md={3.5}
              className="center_center_flex"
              >
                <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={powerarray?.today_forcasted_increase_percent?.percentage !== null &&
                    powerarray?.today_forcasted_increase_percent?.percentage !== undefined
                    ? powerarray.today_forcasted_increase_percent.percentage.toFixed(1)
                    : "--"}
                  ACValue={powerarray?.today_forcasted_increase_percent?.value !== null &&
                    powerarray?.today_forcasted_increase_percent?.value !== undefined
                    ? powerarray?.today_forcasted_increase_percent?.value.toFixed(1)
                    : "--"}
                  ACEnergy={
                    powerarray.today_forcasted_energy_kWh !== null &&
                      powerarray.today_forcasted_energy_kWh !== undefined
                      ? powerarray.today_forcasted_energy_kWh.toFixed(1)
                      : "--"
                  }
                  DCEnergy="true"
                  ghiValue = {ghiDetails.today_forcasted_ghi_irradiance_kWh_m2 !== null &&
                    ghiDetails.today_forcasted_ghi_irradiance_kWh_m2 !== undefined
                    ? ghiDetails.today_forcasted_ghi_irradiance_kWh_m2.toFixed(2)
                    : "--"}
                    ghiPercent = {ghiDetails?.today_forcasted_increase_percent?.percentage !== null &&
                      ghiDetails?.today_forcasted_increase_percent?.percentage !== undefined
                      ? ghiDetails.today_forcasted_increase_percent.percentage.toFixed(2)
                      : "--"}
                    ghiVal = {ghiDetails?.today_forcasted_increase_percent?.value !== null &&
                      ghiDetails?.today_forcasted_increase_percent?.value !== undefined
                      ? ghiDetails?.today_increase_percent?.value.toFixed(2)
                      : "--"}
                  unit="kWh"
                  title="Today's Forecast"
                  colorCode="#00529A" />
              </Paper>
            </Grid>
            <Divider
              className="divider_14rem"
              orientation="vertical" />
            <Grid
              item
              lg={3.7}
              xs={12}
              sm={3.5}
              md={3.5}
              className="center_center_flex"
              >
                <Paper className="column_gap_transparent">
              
                <EnergyData
                 ACPercent={powerarray?.today_increase_percent?.percentage !== null &&
                  powerarray?.today_increase_percent?.percentage !== undefined
                  ? powerarray.today_increase_percent.percentage.toFixed(1)
                  : "--"}
                ACValue={powerarray?.today_increase_percent?.value !== null &&
                  powerarray?.today_increase_percent?.value !== undefined
                  ? powerarray?.today_increase_percent?.value.toFixed(1)
                  : "--"}
                ACEnergy={
                  powerarray.today_generated_energy_kWh !== null &&
                    powerarray.today_generated_energy_kWh !== undefined
                    ? powerarray.today_generated_energy_kWh.toFixed(1)
                    : "--"
                }
                DCEnergy="true"
                ghiValue = {ghiDetails.today_ghi_irradiance_kWh_m2 !== null &&
                  ghiDetails.today_ghi_irradiance_kWh_m2 !== undefined
                  ? ghiDetails.today_ghi_irradiance_kWh_m2.toFixed(2)
                  : "--"}
                  ghiPercent = {ghiDetails?.today_increase_percent?.percentage !== null &&
                    ghiDetails?.today_increase_percent?.percentage !== undefined
                    ? ghiDetails.today_increase_percent.percentage.toFixed(2)
                    : "--"}
                  ghiVal = {ghiDetails?.today_increase_percent?.value !== null &&
                    ghiDetails?.today_increase_percent?.value !== undefined
                    ? ghiDetails?.today_increase_percent?.value.toFixed(2)
                    : "--"}
                unit="kWh"
                title="Today’s Generation"
                colorCode="#FDA737" />
           
                   </Paper>
            </Grid>
            <Divider
              className="divider_14rem"
              orientation="vertical" />
            <Grid
              item
              lg={3.75}
                  xs={12}
                  sm={3.5}
                  md={3.5}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={powerarray?.yesterday_increase_percent?.percentage !== null &&
                    powerarray?.yesterday_increase_percent?.percentage !== undefined
                    ? powerarray?.yesterday_increase_percent?.percentage.toFixed(1)
                    : "--"}
                  ACValue={powerarray?.yesterday_increase_percent?.value !== null &&
                    powerarray?.yesterday_increase_percent?.value !== undefined
                    ? powerarray?.yesterday_increase_percent?.value.toFixed(1)
                    : "--"}
                  ACEnergy={
                    powerarray.yesterdays_generated_energy_kWh !== null &&
                      powerarray.yesterdays_generated_energy_kWh !== undefined
                      ? powerarray.yesterdays_generated_energy_kWh.toFixed(1)
                      : "--"
                  }
                  DCEnergy="true"
                      ghiValue = {ghiDetails.yesterday_ghi_irradiance_kWh_m2 !== null &&
                        ghiDetails.yesterday_ghi_irradiance_kWh_m2 !== undefined
                        ? ghiDetails.yesterday_ghi_irradiance_kWh_m2.toFixed(2)
                        : "--"}
                        ghiPercent = {ghiDetails?.yesterday_increase_percent?.percentage !== null &&
                          ghiDetails?.yesterday_increase_percent?.percentage !== undefined
                          ? ghiDetails.yesterday_increase_percent.percentage.toFixed(2)
                          : "--"}
                        ghiVal = {ghiDetails?.yesterday_increase_percent?.value !== null &&
                          ghiDetails?.yesterday_increase_percent?.value !== undefined
                          ? ghiDetails?.yesterday_increase_percent?.value.toFixed(2)
                          : "--"}
                  unit="kWh"
                  title="Yesterday’s Generation"
                  colorCode="#04724D" />
            </Paper>
            </Grid>
            {/* <Grid
              item
              lg={3.75}
                  xs={12}
                  sm={3.5}
                  md={3.5}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={powerarray?.monthly_increase_percent?.percentage !== null &&
                    powerarray?.monthly_increase_percent?.percentage !== undefined
                    ? powerarray?.monthly_increase_percent?.percentage.toFixed(1)
                    : "--"}
                  ACValue={powerarray?.monthly_increase_percent?.value !== null &&
                    powerarray?.monthly_increase_percent?.value !== undefined
                    ? powerarray?.monthly_increase_percent?.value.toFixed(1)
                    : "--"}
                  ACEnergy={
                    powerarray.past_30days_generated_energy_kWh
                      ? powerarray.past_30days_generated_energy_kWh.toFixed(
                        1
                      )
                      : "--"
                  }
                  ghiValue= {ghiDetails.past_30days_ghi_irradiance_kWh_m2 !== null &&
                    ghiDetails.past_30days_ghi_irradiance_kWh_m2 !== undefined
                    ? ghiDetails.past_30days_ghi_irradiance_kWh_m2.toFixed(2)
                    : "--"}
                    ghiPercent = {ghiDetails?.monthly_increase_percent?.percentage !== null &&
                      ghiDetails?.monthly_increase_percent?.percentage !== undefined
                      ? ghiDetails.monthly_increase_percent.percentage.toFixed(2)
                      : "--"}
                    ghiVal = {ghiDetails?.monthly_increase_percent?.value !== null &&
                      ghiDetails?.monthly_increase_percent?.value !== undefined
                      ? ghiDetails?.monthly_increase_percent?.value.toFixed(2)
                      : "--"}
                      DCEnergy="true"
                  unit="kWh"
                  title="Monthly"
                  colorCode="#04724D" />
              </Paper>
            </Grid> */}
            {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.powerinfo} />
              )}
          </Grid>
        </Grid>
      </Grid>
      <InverterDetails deviceId={deviceId} defaultVal={defaultField} />
      <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="inv_table_container"
          
        
        sx={{position:"relative"}}
      >
      
           <TableNoSearchBar
                  columns={deviceTableData}
                  data={deviceTableData}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  order={order}
                  orderBy={orderBy}
                  handlePageChange={handleChangePage}
                  handleRowsPerPageChange={handleChangeRowsPerPage}
                  
                 
                />
                </Grid>
    </div>
  );
}

export default Inverter;