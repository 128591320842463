import React from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function RemoteProductsPage() {
    const proCards = [
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthImgOne.svg",
            title:"Virtual Control Room",
            description:"Offers a centralized interface for remote management, providing real-time data on solar farm performance. Access key metrics such as current power generation, total energy output, and detailed forecasts from any location. This comprehensive dashboard ensures flexible, effective monitoring and control of your solar operations."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FourthImgTwo.svg",
            title:"LLM Agent Integration",
            description:"Features a natural language interface for seamless audio and text interactions, offering real-time data in an intuitive format. Utilizes multiple specialized LLM agents, each with a distinct role, to enhance reliability and provide streamlined data in text, audio, or graphical formats. Enables sophisticated, AI-driven analysis and efficient scenario handling, ensuring users receive accurate insights and recommendations effortlessly."
        },
        {
            img:"https://s3.amazonaws.com/smarttrak.co/newProductPage/FourthImgThree.svg",
            title:"Dynamic Adjustments & Performance Optimization",
            description:"Utilizes real-time data to refine panel angles and inverter settings for peak solar farm performance. By integrating weather insights, the system ensures precise adjustments and maximizes energy production and efficiency. This continuous optimization guarantees that your solar operations remain at their highest potential."
        },
    ]

  const keyPoints = [
    {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey1.svg",
      title:"Seamless Control",
      description:"Real-time insights and management for effortless solar operations."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey2.svg",
      title:"Peak Efficiency",
      description:"Dynamic adjustments optimize performance based on live data."
  },
  {
      keyImage : "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey3.svg",
      title:"Continuous Optimization",
      description:"Ensures maximum energy production and operational effectiveness."
  },
  ]

  return (
    <div className='landing-container'>
    <Navbar />
    <div className="slide">
        <img className='powerOptix_img' style={{ width: "100%" }} src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideFour.jpg" alt="banner" />
        <div className="products_overlay">
          <p className="Poppins_45px_rem">Remote Management</p>
          <p style={{ padding: "1vw 0vw", width: "96%", fontWeight:"500" }} className="Poppins_20px_rem">Transforms remote surveillance with real-time insights and dynamic control, ensuring highly optimized and effortlessly managed solar operations.</p>
        </div>
      </div>
      
      <div className='infoGraph_container' style={{margin:"2rem 0rem"}}>
        <p className='Poppins_48px_rem'>Operational Overview</p>
        <p className='Poppins_24px_rem' style={{fontWeight:"500", color:"black", width:"78%", textAlign:"center"}}>Enables real-time monitoring of solar panels, inverters, and trackers with instant fault alerts. Advanced analytics track revenue, energy output, and efficiency. AI-driven reporting and direct control offer optimal performance and flexibility.</p>
        <img src='https://s3.amazonaws.com/smarttrak.co/newProductPage/InfoGraphFour.jpg' alt='prod' className='infoGraph' />
      </div>
      <div className='full_width_center'>
      <div className='cards_container'>
      <p className='Poppins_40px_rem' style={{textAlign:"center"}}>Key Attributes</p>
      {proCards.map((card, index)=>{
       return (
       <div key={index} className='pro-details' style={{flexDirection:index%2 ===0 ? "row" : "row-reverse"}}>
        <div><img src={card.img} alt='product' className='pro_image' /></div>
        <div>
            <p className="Poppins_35px_rem" style={{color:"#FFFFFF"}}>{card.title}</p>
            <br />
            <p className='Poppins_16px_rem' style={{color:"#FFFFFF", fontWeight:"500", lineHeight:"2rem"}}>{card.description}</p>
        </div>
       </div>
       )
      }) }
      </div>
      </div>
      <div className='full_width_center' style={{marginBottom:"2rem"}}>
      <Grid container className="key_point_grid"
        >
           <Grid lg={12} xs={12} sm={12} md={12}>
              <Paper className="full_width"
              >
                <p className='Poppins_32px_rem' style={{ color: "#015399", fontWeight: "700", textAlign:"center" }}>Core Benefits</p>
              </Paper>
            </Grid>  
          {keyPoints.map((item, index) => (
            <Grid item key={index} lg={3.8} xs={12} sm={8} md={3.8}>
              <Paper className="key_cards"
              >
               <img src={item.keyImage} alt="keyImage" />
                <div className='key_title'>
                <p className='Poppins_20px_rem' style={{fontFamily:"Poppins-Bold", fontWeight:"700", color:"black", padding:"1rem", textAlign:"center"}}>{item.title}</p>
                <p className='Poppins_16px_rem' style={{fontWeight:"500", padding:"1rem", textAlign:"center"}}>{item.description}</p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        </div>
        <Footer />
    </div>
  )
}

export default RemoteProductsPage;