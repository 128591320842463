import React from 'react';

const DynamicArrow = ({ pathData, fillColor }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} fill={fillColor} />
    </svg>
  );
};

export default DynamicArrow;
