import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useHistory } from "react-router-dom";
import "./UpperBar.scss";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
import CommonText from "../Common/CommonText";
import BoldCommonText from "../Common/BoldCommonText";
import { useSnackbar } from "notistack";
import { CosmicAlarmsService } from "../Services/CosmicAlarmsService";
import Tooltip from "@mui/material/Tooltip";
import { UserService } from "../Services/UserService";
const alarmService = new CosmicAlarmsService();
const userService = new UserService();
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function UpperBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPlantEl, setAnchorPlantEl] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [plantListsArray, setPlantListsArray] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  const roleType = localStorage.getItem("role");
  const formattedDate = `${currentDateTime.getDate()} ${currentDateTime.toLocaleString(
    "default",
    { month: "short" }
  )} ${currentDateTime.getFullYear()}`;
  const formattedTime = `${currentDateTime.getHours()}:${String(
    currentDateTime.getMinutes()
  ).padStart(2, "0")}:${String(currentDateTime.getSeconds()).padStart(2, "0")}`;
  let warningDisplayed = false;
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];

  useEffect(() => {
    handleUserDetails();
  }, []);
  useEffect(() => {
    getPlantsData();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("plantIds");
    history.push("/signin");
  };
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePlantClick = (event) => {
    setAnchorPlantEl(event.currentTarget);
  };
  const handlePlantPopoverClose = (val) => {
    setAnchorPlantEl(null);
    switch (val) {
      case "My Profile":
        history.push(`/iot-home/profile`);
        break;
      case "Subscriptions":
        history.push(`/iot-home/profile`);
        break;
      case "Help & Support":
        history.push(`/iot-home/profile`);
        break;
      default:
        break;
    }
  };

  const handlePopoverClose = (val,id) => {
    setAnchorEl(null);
    switch (val) {
      case "My Profile":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
              id: id,
          }
      });
        break;
      case "Subscriptions":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
              id: id,
          }
      });
        break;
      case "Help & Support":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
              id: id,
          }});
      
        break;
      default:
        break;
    }
  };
  const handleUserDetails = async () => {
    try {
      setLoading(true);
      const res = await userService.getUserDetails();
      if (res.status === 200) {
        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (!warningDisplayed) {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }

        setLoading(false);
      } else {
        console.log("Error while fetching the data", error);
        if (!warningDisplayed) {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }
        setLoading(false);
      }
    }
  };
  const getPlantsData = async () => {
    try {
      setLoading(true);
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (!warningDisplayed) {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }

        setLoading(false);
      } else {
        console.log("Error while fetching the data", error);
        if (!warningDisplayed) {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }
        setLoading(false);
      }
    }
  };
 
 
  const handleInviteUsers = () => {
    history.push(`/iot-home/invite-users`);
    setOpenDrawer(false);
  };

  const handleOnboard = () => {
    history.push(`/iot-home/onboard`);
    setOpenDrawer(false);
  };

  return (
    <div>
      {/* {loading && <SimpleBackdrop open={loading} />} */}
      <AppBar className="upperbar_main" position="static"
     >
        <Toolbar className="toolBar">
          <div className="icon-container">
            <div className="plantdropdown" onClick={handlePlantClick}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                  alt="plant"
                  style={{ width: "1.8vw" }}
                />
              </div>
              <div className="green-tab">
                <p className="Poppins_12px_rem" style={{ fontWeight: "400" }}>
                  {plantListsArray.length > 0 ? plantListsArray[0].name : "--"}
                </p>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/dropDown.svg"
                  alt="plant"
                  style={{ width: "1vw" }}
                />
              </div>
            </div>

            <div className="plantdropdown" onClick={handleAvatarClick}>
              <div className="green-tab">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/userProfile.svg"
                  alt="profile"
                  style={{ width: "1.8vw" }}
                />
              </div>
              <div className="flex-start-column">
                <p className="Poppins_14px_rem" style={{ color: "#2F67F8" }}>
                  {userData.first_name && userData.last_name
                    ? `${userData.first_name} ${userData.last_name}`
                    : "--"}
                </p>
                <p
                  className="Poppins_12px_rem"
                  style={{ textTransform: "capitalize", color: "#212121" }}
                >
                  {userData.role
                    ? userData.role.charAt(0).toUpperCase() +
                      userData.role.slice(1).toLowerCase()
                    : "--"}
                </p>
              </div>
            </div>
            {plantListsArray.length > 0 && (
              <Popover
                open={Boolean(anchorPlantEl)}
                anchorEl={anchorPlantEl}
                onClose={() => setAnchorPlantEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "left",
                  horizontal: "center",
                }}
                sx={{
                  borderRadius: "10px !important",
                }}
              >
                <div className="dropdown-div">
                  {plantListsArray.map((ele, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handlePlantPopoverClose(ele.name)}
                      style={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                        alt="plant"
                        style={{
                          width: "1.8vw",
                          borderRadius: "50%",
                          border: "2px solid black",
                        }}
                      />
                      <p className="Poppins_12px_rem">{ele.name}</p>
                    </MenuItem>
                  ))}
                </div>
              </Popover>
            )}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "left",
                horizontal: "center",
              }}
              sx={{
                borderRadius: "10px !important",
              }}
            >
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flexStart",
                    flexDirection: "column",
                  }}
                >
                  <CommonText
                    type="Bold_700"
                    text={
                      userData.first_name && userData.last_name
                        ? `${userData.first_name} ${userData.last_name}`
                        : "--"
                    }
                  />

                  <CommonText
                    type="Regular_400"
                    text={
                      userData.role
                        ? userData.role.charAt(0).toUpperCase() +
                          userData.role.slice(1).toLowerCase()
                        : "--"
                    }
                    styles={{ textTransform: "capitalize", color: "#969696" }}
                  />
                </div>
                <Divider
                  sx={{ mt: 0.6, mb: 0.6 }}
                  style={{ border: "1px solid #969696" }}
                />

                <MenuItem
                  onClick={() => handlePopoverClose("My Profile",0)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineOutlinedIcon style={{ color: "#00529A" }} />
                  <CommonText type="Regular_400" text="My Profile" />
                </MenuItem>

                <MenuItem
                  onClick={() => handlePopoverClose("Subscriptions",1)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineOutlinedIcon style={{ color: "#00529A" }} />
                  <CommonText type="Regular_400" text="Subscriptions" />
                </MenuItem>
                <MenuItem
                  onClick={() => handlePopoverClose("My Plants",2)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/myplants.svg"
                    alt="plant"
                    style={{ width: "20px" }}
                  />
                  <CommonText type="Regular_400" text="My Plants" />
                </MenuItem>
                <MenuItem
                  onClick={() => handlePopoverClose("Help & Support",3)}

                  value={"Help & Support"}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <HelpOutlineIcon style={{ color: "#00529A" }} />

                  <CommonText type="Regular_400" text="Help & Support" />
                </MenuItem>
                {roleType === "EXECUTIVE" && (
                  <MenuItem
                    onClick={handleInviteUsers}
                    value={"Invite Users"}
                    style={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/mdi_invite.svg"
                      alt="plant"
                      style={{ width: "20px" }}
                    />
                    <CommonText type="Regular_400" text="Invite Users" />
                  </MenuItem>
                )}

                <MenuItem
                  onClick={handleLogout}
                  value={"Logout"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px",
                    color: "#FAAD43",
                  }}
                >
                  <LogoutRoundedIcon style={{ color: "#00529A" }} />

                  <CommonText type="Regular_400" text="Logout" />
                </MenuItem>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default UpperBar;
