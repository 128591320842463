import React, { useState } from 'react'
import Navbar from '../MainLandingPage/Navbar'
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from '../MainLandingPage/Footer';
function SmartIQEdge() {
    const keyFeatures = [
        {
            title: "Advanced Data",
            extraTitle: "Intelligence",
            description: "SmartIQ Edge revolutionizes how data is collected in solar energy systems. Our solution meticulously gathers and analyzes every bit of information from your solar plant's sensors and equipment. This means real-time insights into every aspect of your solar operations, ensuring peak performance and highlighting areas for improvement.",
            featureImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/key-feature1.jpg"
        },
        {
            title: "Seamless Cloud",
            extraTitle: "Connectivity",
            description: "The power of SmartIQ Edge extends beyond physical data collection. It brings your solar plant's data to life by transmitting it to our sophisticated backend cloud servers. This seamless integration is where the magic happens – your data is not just stored; it's transformed.",
            featureImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/key-feature2.jpg"
        },
        {
            title: "AI-Driven Solar ",
            extraTitle: "Optimization",
            description: "In the realm of our cloud server, advanced AI algorithms specific to solar energy management get to work. They sift through the data, finding patterns and offering predictions that lead to more efficient operations. From optimizing energy output to predictive maintenance, SmartIQ Edge ensures your solar power plant operates at its absolute best.",
            featureImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/key-feature3.jpg"
        },
        {
            title: "Unleash Solar ",
            extraTitle: "Potential",
            description: "Installing SmartIQ Edge is more than an upgrade – it's a commitment to smarter, more efficient solar energy management. This technology empowers your solar power plant to operate more reliably and efficiently, maximizing your investment and contributing to a sustainable future.",
            featureImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/key-feature4.jpg"
        },
        {
            title: "Step into the",
            extraTitle: "Solar Future",
            description: "Join the movement towards a more sustainable world with SmartIQ Edge at the helm of your solar energy operations. Embrace this revolutionary technology and lead the charge in the renewable revolution, one solar panel at a time.",
            featureImg: "https://s3.amazonaws.com/smarttrak.co/landing-images/key-feature5.jpg"
        },
    ]
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <div className='landing-container'>
            <Navbar />
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    height: "60vh",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div style={{ position: "relative" }}>
                    <img
                        src="https://s3.amazonaws.com/smarttrak.co/landing-images/solarPanelHome.svg"
                        style={{
                            width: "22vw",
                            top: "8vh",
                            position: "relative",
                        }}
                        alt="solarPanelHome"
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: "11vh",
                            left: "4.5vw",
                        }}
                    >
                        <img
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                            alt="small-green-star"
                            style={{ position: "absolute", top: "-1vh", width: "1.4vw" }}
                            className={isHovered ? "blink-green-star" : "none"}
                        />
                        <img
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                            alt="big-green-star"
                            style={{ position: "absolute", left: "1vw", width: "3vw" }}
                            className={isHovered ? "blink-green-star" : "none"}
                        />
                    </div>
                </div>

                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <p className="bold-big-text">
                        SmartIQ Edge
                        <br />{" "}
                        <p className="landing-heading">
                            <>Elevate Your Solar Efficiency with SmartIQ Edge: <br /> The Future of Solar Energy Management
                            </>
                        </p>
                    </p>
                    <div style={{ position: "relative" }} className='svg_line'>
                        <svg className='draw_line_svg'
                            style={{ position: "absolute", left: "-50vw", top: "-8vh" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="679"
                            height="98"
                            viewBox="0 0 679 98"
                            fill="none"
                        >
                            <path
                                className="vectorAnime"
                                d="M672.807 94.6662C672.807 96.1389 674.001 97.3328 675.474 97.3328C676.947 97.3328 678.141 96.1389 678.141 94.6662C678.141 93.1934 676.947 91.9995 675.474 91.9995C674.001 91.9995 672.807 93.1934 672.807 94.6662ZM249.067 10.6125L248.796 11.0326L249.067 10.6125ZM0.125 1.53027H216.54V0.530273H0.125V1.53027ZM248.796 11.0326L364.227 85.5041L364.769 84.6638L249.338 10.1923L248.796 11.0326ZM397.026 95.1662H675.474V94.1662H397.026V95.1662ZM364.227 85.5041C374.003 91.8115 385.391 95.1662 397.026 95.1662V94.1662C385.583 94.1662 374.384 90.8669 364.769 84.6638L364.227 85.5041ZM216.54 1.53027C227.982 1.53027 239.181 4.82951 248.796 11.0326L249.338 10.1923C239.562 3.88496 228.174 0.530273 216.54 0.530273V1.53027Z"
                                fill="transparent"
                            />
                        </svg>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        position: "relative",
                        paddingBottom: "4vh",
                    }}
                >
                    <img
                        src="https://s3.amazonaws.com/smarttrak.co/landing-images/solarPanelWheel.svg"
                        style={{ width: "22vw", position: "relative" }}
                        alt="solarPanelWhell"
                    />
                    <div
                        style={{
                            position: "absolute",
                            right: "6.5vw",
                            top: "13vh",
                        }}
                    >
                        <img
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                            alt="big-green-star"
                            className={isHovered ? "blink-green-star" : "none"}
                            style={{ width: "3vw" }}
                        />
                        <img
                            style={{
                                position: "absolute",
                                width: "1vw",
                                right: "-0.5vw",
                                top: "-2vh",
                            }}
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                            alt="small-green-star"
                            className={isHovered ? "blink-green-star" : "none"}
                        />
                    </div>
                </div>

                <div style={{ position: "absolute", right: "5vw" }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="98"
                        height="363"
                        viewBox="0 0 98 363"
                        fill="none"
                    >
                        <path
                            className="vectorAnime"
                            d="M94.3703 356.751C95.843 356.751 97.0369 357.945 97.0369 359.418C97.0369 360.891 95.843 362.084 94.3703 362.084C92.8975 362.084 91.7036 360.891 91.7036 359.418C91.7036 357.945 92.8975 356.751 94.3703 356.751ZM17.2015 76.6336L17.5643 76.2895L17.2015 76.6336ZM1.23438 0.0434635L1.23438 35.3433L0.234378 35.3433L0.234378 0.0434635L1.23438 0.0434635ZM17.5643 76.2895L78.266 140.288L77.5404 140.976L16.8387 76.9776L17.5643 76.2895ZM94.8703 181.922L94.8703 359.418L93.8703 359.418L93.8703 181.922L94.8703 181.922ZM78.266 140.288C88.9272 151.528 94.8703 166.43 94.8703 181.922L93.8703 181.922C93.8703 166.686 88.0255 152.031 77.5404 140.976L78.266 140.288ZM1.23438 35.3433C1.23438 50.5794 7.07921 65.2349 17.5643 76.2895L16.8387 76.9776C6.17744 65.7373 0.234378 50.8355 0.234378 35.3433L1.23438 35.3433Z"
                            fill="transparent"
                        />
                    </svg>
                </div>
            </div>

            <div className='smart-heading'>
                <p className="bold-common-text" style={{ color: "#111" }}>What is SmartIQ Edge</p>
            </div>
            <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} />
            <div className='img-block' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <p id='smartiq-description' className='semibold-commontext'>In the dynamic world of renewable energy, staying ahead means leveraging the best in technology. SmartIQ Edge, our pioneering IoT SCADA hardware, is not just a component of your solar power plant – it's the key to unlocking its full potential.</p>
                <div className='smartiq-img-container'>
                    <img className='smartiq_img' src='https://s3.amazonaws.com/smarttrak.co/landing-images/smart_iq.jpg' alt='smartiq' />
                    <div
                        style={{
                            position: "absolute",
                            top: "-3vh",
                            right: "-3vw",
                        }}
                    >
                        <img
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/big-green-star.svg"
                            alt="big-green-star"
                            className={isHovered ? "blink-green-star" : "none"}
                            style={{ width: "3vw" }}
                        />
                        <img
                            style={{ position: "absolute", width: "1vw", bottom: "7vh" }}
                            src="https://s3.amazonaws.com/smarttrak.co/landing-images/small-green-star.svg"
                            alt="small-green-star"
                            className={isHovered ? "blink-green-star" : "none"}
                        />
                    </div>
                </div>
            </div>
            <div className="service-heading">
                <p className="bold-common-text" style={{ color: "#111" }}>Key Features</p>
                <p id="expertise-description" className="regular-common-text" style={{ width: "38%", color: "#111" }}>Discover seamless integration and unmatched efficiency through our product's advanced technology. Our user-centric design and robust functionality redefine convenience and reliability.</p>
            </div>
            <Divider style={{ border: "0.5px solid #E6E6E6", margin: "6vh 2.3vw" }} />
            <Grid container sx={{ flexGrow: 1, gap: "1vw" }}
            >
                {keyFeatures.map((item, index) => (
                    <Grid className="key-features-data" item key={index} lg={11.9} xs={11.9} sm={11.9} md={11.9}>
                        <Paper className='feature-container'
                            sx={{
                                display: "flex",
                                flexDirection:index%2 === 0 ? "row" : "row-reverse",
                                alignItems: "flex-start",
                                gap: "8vw",
                                boxShadow: "none ! important",
                            }}
                        >
                            <div className='feature-description'><p className='semibold-commontext'>{item.title}<br /> {item.extraTitle}</p>
                                <p className="regular-common-text" style={{ color: "#111" }}>{item.description}</p>
                            </div>
                            <div className='feature-img-container' style={{backgroundColor:index%2 === 0 ?"#FFD3D3":"#D3DFFF",borderRadius : index%2 === 0 ? "1.5vw 1.5vw 1.5vw 6vw" : "1.5vw 1.5vw 6vw 1.5vw"}}>
                                <img className='feature-img' style={{borderRadius : index%2 === 0 ? "1vw 1vw 1vw 6vw" : "1vw 1vw 6vw 1vw"}} src={item.featureImg} alt='key-feature' />
                                </div>

                        </Paper>
                    </Grid>
                ))}
            </Grid>
         <Footer />
        </div>
    )
}

export default SmartIQEdge