import React, { useRef, useEffect } from 'react';

const AngleDrawer = ({ sunAngle, trackerAngle }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;

    // Clear previous drawings
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }

    const centerX = svg.clientWidth / 2;
    const centerY = svg.clientHeight / 2;
    const radius = 140; // Increase radius for more space between poles and angles
    const labelOffset = 30; // Adjust this value to increase space between lines and labels

    const drawLine = (x1, y1, x2, y2, color, dashArray = 'none', width = '5px') => {
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', x1);
      line.setAttribute('y1', y1);
      line.setAttribute('x2', x2);
      line.setAttribute('y2', y2);
      line.setAttribute('stroke', color);
      line.setAttribute('stroke-width', width);
      if (dashArray !== 'none') {
        line.setAttribute('stroke-dasharray', dashArray);
      }
      svg.appendChild(line);
    };

    const drawText = (x, y, text, className) => {
      const label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      label.setAttribute('x', x);
      label.setAttribute('y', y);
      label.setAttribute('text-anchor', 'middle');
      label.textContent = text;
      label.setAttribute('class', className);
      svg.appendChild(label);
    };

    const drawCardinalDirections = () => {
      const cardinalLines = [
        { angle: 0, text: 'N', xAdjust: 0, yAdjust: labelOffset - 7 },
        { angle: 90, text: 'E', xAdjust: -5, yAdjust: labelOffset },
        { angle: 180, text: 'S', xAdjust: 0, yAdjust: labelOffset },
        { angle: 270, text: 'W', xAdjust: 4, yAdjust: labelOffset },
      ];

      cardinalLines.forEach(({ angle, text, xAdjust, yAdjust }) => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        const labelX = centerX + (radius + labelOffset) * Math.cos(angleRadians) + xAdjust;
        const labelY = centerY + (radius + labelOffset) * Math.sin(angleRadians) + yAdjust;
        drawText(labelX, labelY, text, 'Poppins_24px_rem');
      });
    };

    const drawQuadrantLines = () => {
      const quadrants = [0, 90, 180, 270];
      quadrants.forEach(angle => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        drawLine(centerX, centerY, centerX + radius * Math.cos(angleRadians), centerY + radius * Math.sin(angleRadians), '#646464', 'none', '10px');
      });
    };

    const drawAngles = () => {
      if (sunAngle !== null) {
        const sunAngleInRadians = (sunAngle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        drawLine(centerX, centerY, centerX + radius * Math.cos(sunAngleInRadians), centerY + radius * Math.sin(sunAngleInRadians), 'orange', '5,5');
        const sunImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        sunImage.setAttribute('href', 'https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_sun.svg');
        sunImage.setAttribute('width', '50');
        sunImage.setAttribute('height', '50');
        sunImage.setAttribute('x', centerX + radius * Math.cos(sunAngleInRadians) - 25);
        sunImage.setAttribute('y', centerY + radius * Math.sin(sunAngleInRadians) - 25);
        svg.appendChild(sunImage);
      }

      if (trackerAngle !== null) {
        let adjustedTrackerAngle;
        if (trackerAngle >= 0) {
          adjustedTrackerAngle = trackerAngle - 180; // Positive angle moves from W-N-E
        } else {
          adjustedTrackerAngle = trackerAngle - 180; // Negative angle moves from W-S-E
        }
        const trackerAngleInRadians = adjustedTrackerAngle * (Math.PI / 180);
        drawLine(centerX, centerY, centerX + radius * Math.cos(trackerAngleInRadians), centerY + radius * Math.sin(trackerAngleInRadians), 'blue', '5,5');

        const trackerLabelX = centerX + (radius + labelOffset) * Math.cos(trackerAngleInRadians);
        const trackerLabelY = centerY + (radius + labelOffset) * Math.sin(trackerAngleInRadians);
        drawText(trackerLabelX, trackerLabelY, `${trackerAngle}°`, 'Poppins_16px_rem');
      }
    };

    const drawAngleLabels = () => {
      const angleLabels = [
        { angle: 0, text: '0°' },
        { angle: 90, text: '90°' },
        { angle: 180, text: '180°' },
        { angle: 270, text: '270°' },
        { angle: sunAngle, text: sunAngle ? `${sunAngle}°` : "" },
      ];
      angleLabels.forEach(({ angle, text }) => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        const labelX = centerX + (radius + labelOffset) * Math.cos(angleRadians);
        const labelY = centerY + (radius + labelOffset) * Math.sin(angleRadians);
        drawText(labelX, labelY, text, 'Poppins_16px_rem');
      });
    };

    drawCardinalDirections();
    drawQuadrantLines();
    drawAngles();
    drawAngleLabels();
  }, [sunAngle, trackerAngle]);

  return <svg ref={svgRef} width="450" height="450"></svg>;
};

export default AngleDrawer;
