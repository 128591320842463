import React, { useEffect, useState, useRef } from 'react';
import Chart from 'react-apexcharts';
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import ModalCalender from '../Common/ModalCalender';

const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
const UnixDateChart = (props) => {
    const {selectedDateRangeProp, isValueData} = props
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!isEmptyObject(isValueData)) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: true,
      
              style: {
                colors: isFullScreen ? "white" : "#000000",
              },
            },
          },
          chart: {
            type: isValueData?.chart?.type,
            toolbar: {
              autoSelected: 'pan',
              show: true
            },
            zoom: {
              enabled: true
            }
          },
        fill: {
      type: "gradient",
      gradient: {
        shade: isFullScreen ? "dark" : "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
          yaxis: isValueData?.yAxis
        }));
        setSeries(isValueData.series);
    }
  }, [isValueData]);
  const [options, setOptions] = useState({
    chart: {
      type: isValueData?.chart?.type,
      height: 350,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: true,

        style: {
          colors: isFullScreen ? "white" : "#000000",
        },
      },
    },
    stroke: {
        width:2,
      curve: 'smooth'
    },
    yaxis: {
      title: {
        text: isValueData?.yaxis?.title.text
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2); 
        }
      },
      tickAmount: 5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
      }
    },
    grid: {
      show: false,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 0
    },
    tooltip: {
        x: {
            format: "yyyy-MM-dd HH:mm",
          },
          y: {
            formatter: function (value) {
              if (value === null || typeof value === "undefined") {
                return ""; 
              }
              return value.toFixed(2);
            },
          },
      },
    dataLabels: {
      enabled: false
    }
  });

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [isFullScreen]);

  const handleFullScreenToggle = () => {
    const chartContainer = chartContainerRef.current;

    if (!isFullScreen) {
      if (chartContainer.requestFullscreen) {
        chartContainer.requestFullscreen();
      } else if (chartContainer.mozRequestFullScreen) {
        chartContainer.mozRequestFullScreen();
      } else if (chartContainer.webkitRequestFullscreen) {
        chartContainer.webkitRequestFullscreen();
      } else if (chartContainer.msRequestFullscreen) {
        chartContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };
  const handleTimings = () => {
    setOpenDrawer(true);
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);

  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1vw",
        alignItems: "center",
      }}
    >
      <div>
        <p
          className="Poppins_18px_rem"
          style={{ color: isFullScreen ? "white" : "#212121" }}
        >
          {isValueData?.title?.text !== undefined ? isValueData.title.text : ""}
        </p>{" "}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Download" arrow>
            <DownloadIcon className='icon-css'
              onClick={downloadChart}
            />
          </Tooltip>

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon className='icon-css'
              onClick={handleTimings}
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={handleFullScreenToggle}
              style={{
                width: "1.6vw !important", height: "fit-content !important",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon className='icon-css'
                />
              ) : (
                <FullscreenIcon className='icon-css'
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
    <Divider style={{ border: "1px solid #D9D9D9" }} />
    {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}
    <div
        style={{
          height: 
          isEmptyObject(isValueData) ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
        }}
        ref={chartContainerRef}
         id="prediction-chart"
      >
        {isEmptyObject(isValueData) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
            <Chart
            id="unixChart"
                options={options}
                series={series}
                type="area"
                height={isFullScreen ? "800" : "310"}
              />
        )}
      </div>
    </div>
  );
};

export default UnixDateChart;